import VerificationInput from 'stories/FormInputs/Verification/Verification'

import { FIELD_TYPE, typeUtils } from 'types'
import {
  getFormItemErrors,
  getFormItemLabel,
  getGenericFormItemDefaultValue as getDefaultValue,
} from './_propGetters'

const getVerificationStatus = (type, { isPhoneVerified, isEmailVerified }) => {
  switch (type) {
    case `${FIELD_TYPE.PHONE_VERIFICATION}`:
      return isPhoneVerified
    case `${FIELD_TYPE.EMAIL_VERIFICATION}`:
      return isEmailVerified
    default:
      return false
  }
}

const Component = VerificationInput

const getProps = fieldProps => ({
  ...fieldProps,
  name: fieldProps.slug,
  label: getFormItemLabel(fieldProps),
  // input props
  separateLabel: true,
})

const getControlledProps = (
  fieldProps,
  controllerProps,
  defaultValue,
  rowDataProps,
) => ({
  ...controllerProps.field,
  type: typeUtils.getMostSpecificType(fieldProps),
  label: getFormItemLabel(fieldProps),
  isVerified: getVerificationStatus(fieldProps.type, rowDataProps),
  // input props
  separateLabel: true,
  errors: getFormItemErrors(controllerProps),
})

const getValidationRules = (fieldProps, options) => ({
  // ...getRequiredRule(fieldProps, options)
})

export const verificationInput = {
  Component,
  getProps,
  getControlledProps,
  getValidationRules,
  getDefaultValue,
}
