import axios from 'axios'

export const httpClient = axios

export const HTTP = {
  ContentTypes: {
    FormUrlEncoded: 'application/x-www-form-urlencoded',
    JSON: 'application/json',
  },
}
