import Typography from '@mui/material/Typography'
import { useBoard } from 'context/board'
import TextSkeleton from '../../../stories/loading/skeleton/text'

export default function BoardTitle() {
  const { boardInfo } = useBoard()

  if (!boardInfo) return <TextSkeleton />

  const title = boardInfo.title || boardInfo.primary_form?.title
  return (
    <Typography
      component="h5"
      variant="subtitle1"
      color="textPrimary"
      sx={{ fontWeight: 600, mb: 2, fontSize: 18 }}
    >
      {title}
    </Typography>
  )
}
