import React from 'react'
import PropTypes from 'prop-types'

import { replaceDigits } from 'unicodedigits'

import TextInput from '../Text/Text'
import { useCombinedRefs } from '../_utils'

var acceptableEnglishChars = [
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '.',
]

const isEmpty = value => [null, '', undefined].includes(value)

const isSafeInt = value =>
  !isEmpty(value) && !isNaN(value) && Number.isSafeInteger(Number(value))

const filterChars = text => {
  if (typeof text === 'string') {
    return text
      .split('')
      .filter(char => acceptableEnglishChars.includes(char))
      .join('')
  } else {
    return ''
  }
}
const NumberInput = React.forwardRef(
  (
    {
      type,
      decimal,
      defaultValue,
      value: controlledValue,
      onChange: handleChange,
      outputAsText,
      step,
      ...props
    },
    forwardedRef,
  ) => {
    const innerRef = React.useRef(null)
    const combinedRef = useCombinedRefs(forwardedRef, innerRef)

    const [selection, setSelection] = React.useState(null)

    const isControlledInput = React.useMemo(
      () => typeof handleChange === 'function',
      [handleChange],
    )

    const [value, setValue] = React.useState(defaultValue)

    React.useLayoutEffect(() => {
      if (selection && combinedRef.current) {
        ;[
          combinedRef.current.selectionStart,
          combinedRef.current.selectionEnd,
        ] = selection
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selection, value, controlledValue, forwardedRef])

    const _onChange = e => {
      const typedNumber = e.target.value
      const inputCursor = [e.target.selectionStart, e.target.selectionEnd]
      const enNumber = replaceDigits(typedNumber, 'ASCII').trim()

      if (
        isSafeInt(enNumber) ||
        enNumber === '-' ||
        isEmpty(enNumber) ||
        decimal
      ) {
        const _value =
          !decimal && !outputAsText && isSafeInt(enNumber)
            ? Number(enNumber)
            : decimal
            ? filterChars(enNumber)
            : enNumber

        if (isControlledInput) {
          handleChange(_value)
        } else {
          setValue(_value)
        }
        setSelection(() => inputCursor)
      } else {
        // if value is not going to change, we need to keep cursor in prev position
        setSelection(() => [inputCursor[0] - 1, inputCursor[1] - 1])
      }
    }

    const currentValue = isControlledInput ? controlledValue : value

    return (
      <TextInput
        dir="ltr"
        ref={combinedRef}
        inputProps={{
          inputMode: decimal ? 'decimal' : 'numeric',
          step,
        }}
        onChange={_onChange}
        value={currentValue}
        {...props}
      />
    )
  },
)

NumberInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  /**
   * show decimal/numeric keyboard on mobile devices
   */
  decimal: PropTypes.bool,
  showLabel: PropTypes.bool,
  step: PropTypes.number,
  separateLabel: PropTypes.bool,
  description: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    PropTypes.elementType,
  ]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * accepts the new numeric value as the argument and
   * should return the new (modified?) numeric value
   */
  onChange: PropTypes.func,
  fullWidth: PropTypes.bool,
  autoFill: PropTypes.bool,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  multiline: PropTypes.bool,
  placeholder: PropTypes.string,
  size: PropTypes.oneOf(['small', 'normal']),
  errors: PropTypes.arrayOf(PropTypes.string),
  outputAsText: PropTypes.bool,
}

NumberInput.defaultProps = {
  showLabel: true,
  autoFill: true,
  size: 'small',
  outputAsText: false,
}

export default NumberInput
