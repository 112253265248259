import { useEffect } from 'react'
import { board } from 'api/board'

const RedirectToApp = ({ params: qs }) => {
  useEffect(() => {
    const _login = async () => {
      const params = new URLSearchParams(qs)
      try {
        const { data } = await board().login({
          code: params.get('code'),
          state: params.get('state'),
          redirectUrl: window.location.href.split('?')[0],
        })
        if (window.opener) {
          // send them to the opening window
          window.opener.postMessage(data.data.profile)
          // close the popup
          window.close()
        }
      } catch (error) {}
    }

    _login()
  }, [])

  return 'loading...'
}

export default RedirectToApp
