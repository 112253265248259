export default function DownloadIcon({ color = '#28293D', ...props }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 50 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g>
        <path
          d="M32,24c-1.104,0-2,0.896-2,2v27.171l-4.586-4.585c-0.781-0.781-2.047-0.781-2.828,0c-0.781,0.781-0.781,2.047,0,2.828l8,8
		C30.977,59.805,31.488,60,32,60s1.023-0.195,1.414-0.586l8-8c0.781-0.781,0.781-2.047,0-2.828c-0.781-0.781-2.047-0.781-2.828,0
		L34,53.171V26C34,24.896,33.104,24,32,24z"
          fill={color}
        />
        <path
          d="M55.926,24.186C55,12.901,45.52,4,34,4c-10.224,0-19.043,6.635-21.591,16H12C5.383,20,0,25.383,0,32s5.383,12,12,12h12
		c1.104,0,2-0.896,2-2s-0.896-2-2-2H12c-4.411,0-8-3.589-8-8s3.589-8,8-8h2c0.962,0,1.788-0.685,1.966-1.63
		C17.53,14.043,25.115,8,34,8c9.925,0,18,8.075,18,18c0,1.104,0.896,2,2,2c3.309,0,6,2.691,6,6s-2.691,6-6,6H40
		c-1.104,0-2,0.896-2,2s0.896,2,2,2h14c5.514,0,10-4.486,10-10C64,29.145,60.521,25.086,55.926,24.186z"
          fill={color}
        />
      </g>
    </svg>
  )
}
