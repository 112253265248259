import MuiTooltip from '@mui/material/Tooltip'
import Box from '@mui/material/Box'

const Tooltip = ({
  children,
  message,
  disabled,
  position = 'top',
  ...props
}) => {
  if (disabled)
    return (
      <Box component="span" sx={props.sx || {}}>
        {children}
      </Box>
    )
  return (
    <MuiTooltip
      title={message}
      placement={position}
      arrow
      {...props}
      sx={{
        fontSize: '0.8em',
        '& a': {
          color: '#202095',
          textDecoration: 'underline',
        },
      }}
    >
      <div>{children}</div>
    </MuiTooltip>
  )
}

export default Tooltip
