import { config, getCurrentWorkspace } from './utils'
import { httpClient, HTTP } from './utils/http'
import API from 'app-settings/api.settings'

// #region FORM ROW TAGS
const workspace = getCurrentWorkspace()

export const tags = () => ({
  create: (formSlug, formData) =>
    httpClient.post(
      `${API.baseUrl}/v4/forms/${formSlug}/row-tags/`,
      formData,
      config({
        contentType: HTTP.ContentTypes.JSON,
        workspace,
      }),
    ),
  get: (formSlug, tagSlug) =>
    httpClient.get(
      `${API.baseUrl}/v4/forms/${formSlug}/row-tags/${tagSlug}/`,
      config({ workspace }),
    ),

  getAll: (formSlug, params) =>
    httpClient.get(
      `${API.baseUrl}/v4/forms/${formSlug}/row-tags/`,
      config({ params, workspace }),
    ),

  update: (formSlug, rowTagSlug, formData) =>
    httpClient.patch(
      `${API.baseUrl}/v4/forms/${formSlug}/row-tags/${rowTagSlug}/`,
      formData,
      config({
        contentType: HTTP.ContentTypes.JSON,
        workspace,
      }),
    ),

  delete: (formSlug, rowTagSlug) =>
    httpClient.delete(
      `${API.baseUrl}/v4/forms/${formSlug}/row-tags/${rowTagSlug}/ `,
      config({
        contentType: HTTP.ContentTypes.JSON,
        workspace,
      }),
    ),
})

// #endregion FORM ROW TAGS
