import TextInput from 'stories/FormInputs/Text/Text'

import { typeUtils, FIELD_TYPE } from 'types'
import {
  getFormItemErrors,
  getFormItemLabel,
  getGenericFormItemDefaultValue,
} from './_propGetters'
import {
  getMaxLengthRule,
  getMinLengthRule,
  getRequiredRule,
} from './_validationProps'

const getDefaultValue = fieldProps =>
  getGenericFormItemDefaultValue(fieldProps, '')

const isMultiLine = fieldProps =>
  typeUtils.getMostSpecificType(fieldProps) === FIELD_TYPE.LONG_TEXT

const Component = TextInput

const getProps = fieldProps => ({
  ...fieldProps,
  name: fieldProps.slug,
  label: getFormItemLabel(fieldProps),
  // input props
  separateLabel: true,
  autoFill: false,
  multiline: isMultiLine(fieldProps),
})

const getControlledProps = (fieldProps, controllerProps, defaultValue) => ({
  ...controllerProps.field,
  type: typeUtils.getMostSpecificType(fieldProps),
  label: getFormItemLabel(fieldProps),
  // input props
  separateLabel: true,
  autoFill: false,
  multiline: isMultiLine(fieldProps),
  errors: getFormItemErrors(controllerProps),
})

const getValidationRules = (fieldProps, options) => ({
  ...getRequiredRule(fieldProps, options),
  ...getMinLengthRule(fieldProps),
  ...getMaxLengthRule(fieldProps),
})

export const textInput = {
  Component,
  getProps,
  getControlledProps,
  getValidationRules,
  getDefaultValue,
}
