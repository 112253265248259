import { config, getCurrentWorkspace } from './utils'
import { HTTP, httpClient } from './utils/http'
import API from 'app-settings/api.settings'
import { createJsonData } from 'utils/form/createData'

const workspace = getCurrentWorkspace()

export const block = () => ({
  getAll: (boardSlug, params) =>
    httpClient.get(
      `${API.baseUrl}/v4/boards/${boardSlug}/blocks/`,
      config({ params, workspace }),
    ),

  getOne: (boardSlug, blockSlug, params) =>
    httpClient.get(
      `${API.baseUrl}/v4/boards/${boardSlug}/blocks/${blockSlug}/`,
      config({ params, workspace }),
    ),

  create: (boardSlug, blockType, formValues) =>
    httpClient.post(
      `${API.baseUrl}/v4/boards/${boardSlug}/blocks/${blockType}/`,
      createJsonData(formValues),
      config({ contentType: HTTP.ContentTypes.JSON, workspace }),
    ),

  duplicate: (boardSlug, blockType, baseBlock, formValues) =>
    httpClient.post(
      `${API.baseUrl}/v4/boards/${boardSlug}/blocks/${blockType}/${baseBlock}/duplicate/`,
      createJsonData(formValues),
      config({ contentType: HTTP.ContentTypes.JSON, workspace }),
    ),

  delete: (boardSlug, blockSlug) =>
    httpClient.delete(
      `${API.baseUrl}/v4/boards/${boardSlug}/blocks/${blockSlug}/`,
      config({ workspace }),
    ),

  update: (boardSlug, blockSlug, formValues, options = {}) =>
    httpClient.patch(
      `${API.baseUrl}/v4/boards/${boardSlug}/blocks/${blockSlug}/`,
      createJsonData(formValues, { alwaysSend: ['fields'], ...options }),
      config({ contentType: HTTP.ContentTypes.JSON, workspace }),
    ),

  getAllSharedBlocks: (sharedSlug, params) =>
    httpClient.get(
      `${API.baseUrl}/v4/shared-boards/${sharedSlug}/blocks/`,
      config({ params, workspace }),
    ),

  getOneSharedBlock: (sharedSlug, blockSlug, params) =>
    httpClient.get(
      `${API.baseUrl}/v4/shared-boards/${sharedSlug}/blocks/${blockSlug}/`,
      config({ params, workspace }),
    ),

  getContent: (boardSlug, blockSlug, params) =>
    httpClient.get(
      `${API.baseUrl}/v4/boards/${boardSlug}/blocks/${blockSlug}/content/`,
      config({ params, workspace }),
    ),

  getSharedContent: (sharedSlug, blockSlug, params) =>
    httpClient.get(
      `${API.baseUrl}/v4/shared-boards/${sharedSlug}/blocks/${blockSlug}/content/`,
      config({ params, workspace }),
    ),

  createTableReport: formValues =>
    httpClient.post(
      `${API.baseUrl}/v3/reports/table-charts/`,
      createJsonData(formValues),
      config({ contentType: HTTP.ContentTypes.JSON, workspace }),
    ),

  updateReport: (reportSlug, formValues) =>
    httpClient.patch(
      `${API.baseUrl}/v3/reports/${reportSlug}/`,
      createJsonData(formValues),
      config({ contentType: HTTP.ContentTypes.JSON, workspace }),
    ),
})
