import { defaultLocale } from 'app-settings'

let localeDictionary = require(`./${defaultLocale}`)

export default function getRawMessage(id, values = {}) {
  if (id in localeDictionary.default) {
    let message = localeDictionary.default[id]
    Object.keys(values).forEach(
      k => (message = message.replace(`{${k}}`, values[k])),
    )

    return message
  }

  return id
}
