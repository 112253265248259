import React from 'react'
import PropTypes from 'prop-types'
import FormHelperText from '@mui/material/FormHelperText'
import MuiInputLabel from '@mui/material/InputLabel'
import { InputErrors } from '../_InputErrors'
import FilesList from './_FilesList'
import Box from '@mui/material/Box'
import DeleteIcon from 'stories/icons/Delete'
import FolderIcon from '../../icons/Folder'

const FileInput = React.forwardRef(
  (
    {
      name,
      label,
      buttonText,
      multiple,
      accept,
      separateLabel,
      showLabel,
      description,
      defaultValue,
      value: controlledValue,
      onChange: handleChange,
      fullWidth,
      readOnly,
      disabled,
      placeholder,
      size,
      errors,
      ...props
    },
    ref,
  ) => {
    const hasError = errors?.length > 0

    const showSeparateLabel = showLabel && separateLabel
    const uploadButtonText = buttonText ?? label

    const isControlledInput = React.useMemo(
      () => typeof handleChange === 'function',
      [handleChange],
    )

    const [value, setValue] = React.useState(defaultValue)

    const [uploadedFiles, setUploadedFiles] = React.useState([])

    const isUploading = React.useMemo(
      () => uploadedFiles.some(f => !f.uploadInfo?.slug),
      [uploadedFiles],
    )

    const isDisabled = React.useMemo(
      () => disabled || isUploading,
      [disabled, isUploading],
    )

    const currentValue = isControlledInput ? controlledValue : value

    React.useEffect(() => {
      if (!currentValue) {
        setUploadedFiles([])
      }
    }, [currentValue])

    const _onUploadProgress = fileIndex => progressEvent => {
      const progress = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total,
      )

      setUploadedFiles(prevState =>
        prevState.map((f, index) =>
          index === fileIndex ? { ...f, progress } : f,
        ),
      )
    }

    const _onUploadSuccess = fileIndex => uploadInfo => {
      setUploadedFiles(prevState =>
        prevState.map((f, index) =>
          index === fileIndex ? { ...f, uploadInfo } : f,
        ),
      )
    }

    const _onChange = event => {
      const newValue = event
        ? [...event.target.files].map((data, index) => ({
            data,
            progress: 0,
            onUploadProgress: _onUploadProgress(index),
            onUploadSuccess: _onUploadSuccess(index),
          }))
        : null

      setUploadedFiles(() => (newValue === null ? [] : newValue))

      if (isControlledInput) {
        handleChange(newValue)
      } else {
        setValue(newValue)
      }
    }

    return (
      <Box
        sx={{
          display: 'grid',
          gridGap: 0.5,
          alignItems: 'start',
          justifyItems: 'start',
          alignContent: 'stretch',
          justifyContent: 'normal',
          width: fullWidth ? '100%' : 'auto',
        }}
      >
        {showSeparateLabel && (
          <MuiInputLabel
            sx={{
              fontSize: '1.2rem',
              color: 'primary.lighten',
              mb: 1.5,
            }}
            htmlFor={name}
            error={hasError}
          >
            {label}
          </MuiInputLabel>
        )}
        <input
          ref={ref}
          type="file"
          id={name}
          accept={accept}
          style={{ display: 'none' }}
          multiple={multiple}
          disabled={isDisabled}
          readOnly={readOnly}
          onChange={_onChange}
          {...props}
        />
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gridGap: 1,
            width: fullWidth ? '100%' : 'auto',
          }}
        >
          <label htmlFor={name} style={{ width: '100%' }}>
            <Box
              component="div"
              disabled={isDisabled}
              sx={{
                height: 60,
                backgroundColor: 'background.paper',
                borderRadius: 2,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: '#6846D9',
                border: '1px dashed',
                borderColor: 'primary.light',
                cursor: isDisabled ? 'not-allowed' : 'pointer',
              }}
            >
              <FolderIcon color="#6846D9" style={{ margin: '0 4px' }} />
              {uploadButtonText}
            </Box>
          </label>

          {currentValue && (
            <Box
              size={size}
              aria-label="delete"
              disabled={isDisabled}
              onClick={() => {
                if (isDisabled) {
                  return
                }
                _onChange(null)
              }}
              sx={{
                height: '100%',
                display: 'inline-block',
                ml: 2,
                cursor: isDisabled ? 'not-allowed' : 'pointer',
              }}
            >
              <DeleteIcon fontSize="small" />
            </Box>
          )}
        </Box>
        {description && <FormHelperText>{description}</FormHelperText>}
        {hasError && <InputErrors errors={errors} />}

        <FilesList value={currentValue} uploadedFiles={uploadedFiles} />
      </Box>
    )
  },
)

FileInput.displayName = 'FormalooFileInput'

FileInput.defaultProps = {
  showLabel: true,
  size: 'small',
  separateLabel: false,
  fullWidth: true,
  // html file input props
  multiple: false,
  accept: '*',
  buttonText: 'Click to Upload',
}

FileInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  buttonText: PropTypes.string,
  accept: PropTypes.string,
  multiple: PropTypes.bool,
  separateLabel: PropTypes.bool,
  showLabel: PropTypes.bool,
  description: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    PropTypes.elementType,
  ]),
  value: PropTypes.any,
  defaultValue: PropTypes.any,
  onChange: PropTypes.func,
  fullWidth: PropTypes.bool,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  size: PropTypes.oneOf(['small', 'normal']),
  errors: PropTypes.arrayOf(PropTypes.string),
}

export default FileInput
