import getRawMessage from 'locales/localeDirectAccess'
import TextInput from 'stories/FormInputs/Text/Text'

import { typeUtils } from 'types'
import {
  getFormItemErrors,
  getFormItemLabel,
  getGenericFormItemDefaultValue,
} from './_propGetters'
import { getRequiredRule } from './_validationProps'

const regexFromString = regexString => {
  const match = /^(.*)(\/([a-z]*))$/.exec(regexString)
  try {
    if (match && match.length >= 4) return new RegExp(match[1], match[3])
    return new RegExp(regexString)
  } catch (e) {
    console.error(
      `Invalid regular expression: ${regexString}; It's not a JS regEx.`,
    )
    return ''
  }
}

const Component = TextInput

const getDefaultValue = fieldProps =>
  getGenericFormItemDefaultValue(fieldProps, '')

const getProps = fieldProps => ({
  ...fieldProps,
  name: fieldProps.slug,
  label: getFormItemLabel(fieldProps),
  // input props
  separateLabel: true,
  autoFill: false,
})

const getControlledProps = (fieldProps, controllerProps, defaultValue) => ({
  ...controllerProps.field,
  type: typeUtils.getMostSpecificType(fieldProps),
  label: getFormItemLabel(fieldProps),
  // input props
  separateLabel: true,
  autoFill: false,
  errors: getFormItemErrors(controllerProps),
})

const getValidationRules = (fieldProps, options) => ({
  ...getRequiredRule(fieldProps, options),
  pattern: {
    value: regexFromString(fieldProps.regex),
    message: getRawMessage('form.validation.regex'),
  },
})

export const regexInput = {
  Component,
  getProps,
  getControlledProps,
  getValidationRules,
  getDefaultValue,
}
