const assetSettings = {
  boardWrapperId: 'formaloo-app-ui-board',
  ACTIVE_WORKSPACE_STORAGE_KEY: 'active-workspace',
  environment: {
    sentryDSN: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_ENVIRONMENT,
    devMode: process.env.NODE_ENV === 'development',
  },
  logoUrl: process.env.REACT_APP_CUSTOM_LOGO_URL,
}

export default assetSettings
