import React, { useState } from 'react'
import PropTypes from 'prop-types'

import ReactSelectAsyncCreatable from 'react-select/async-creatable'
import FormHelperText from '@mui/material/FormHelperText'
import MuiInputLabel from '@mui/material/InputLabel'
import { useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import { FormattedMessage } from 'react-intl'

import useFormatMessage from 'hooks/utility/useFormatMessage'
import { InputErrors } from '../_InputErrors'

const AsyncCreatableSelect = React.forwardRef(
  (
    {
      name,
      label,
      description,
      autoFill,
      readOnly,
      errors,
      showLabel,
      separateLabel,
      inputProps: muiInputProps,
      type,
      fetchOptions,
      renderOption,
      getOptionLabel,
      getOptionValue,
      getOptionSelected,
      defaultValue,
      defaultOptions,
      value: controlledValue,
      onChange: handleChange,
      onCreateOption: handleCreateOption,
      loadingMessage,
      noOptionsMessage,
      disabled: isDisabled,
      isMulti,
      cacheOptions,
      debounceDelay,
      ...props
    },
    ref,
  ) => {
    const formatMessage = useFormatMessage()
    const muiTheme = useTheme()
    // const showDefaultMuiLabel = showLabel && !separateLabel
    const showSeparateLabel = showLabel && separateLabel

    const customizedTexts = React.useMemo(
      () => ({
        placeholder: formatMessage({ id: 'form.fields.choices.typeToSearch' }),
        loadingMessage: () =>
          loadingMessage ??
          formatMessage({ id: 'form.fields.choices.loading' }),
        noOptionsMessage: () =>
          noOptionsMessage ??
          formatMessage({
            id: 'form.fields.choices.noOptionsAvailable',
          }),
      }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [loadingMessage, noOptionsMessage],
    )

    const isControlledInput = React.useMemo(
      () => typeof handleChange === 'function',
      [handleChange],
    )

    const [innerValueState, setInnerValueState] = useState(
      defaultValue ?? controlledValue ?? (isMulti ? [] : null),
    )

    const [isLoading, setIsLoading] = useState(false)

    const _onChange = (eventValue, actionMeta) => {
      const newValue = isMulti
        ? eventValue.map(getOptionValue)
        : getOptionValue(eventValue)

      setInnerValueState(eventValue)

      if (isControlledInput) {
        handleChange(newValue, eventValue)
      }
    }

    const _onCreateOption = inputValue => {
      const selectState = {
        isLoading,
      }
      const selectMethods = {
        setIsLoading,
        addToOptions: option => {
          const newValue = isMulti ? [option, ...innerValueState] : option
          _onChange(newValue)
        },
      }

      return typeof handleCreateOption === 'function'
        ? handleCreateOption(inputValue, { selectState, selectMethods })
        : undefined
    }

    const _loadOptions = React.useMemo(() => {
      return async inputValue => {
        const fetchedOptions = await fetchOptions({
          searchValue: inputValue,
        })

        return fetchedOptions
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultValue])

    const hasError = errors?.length > 0

    return (
      <div>
        {showSeparateLabel && (
          <MuiInputLabel
            id={`${name}-label-id`}
            htmlFor={`${name}-id`}
            error={hasError}
            sx={{
              fontSize: '1.2rem',
              color: 'primary.lighten',
              mb: 1.5,
            }}
          >
            {label}
          </MuiInputLabel>
        )}
        <Box
          sx={{
            width: props.fullWidth ? '100%' : 'auto',
            '& .formaloo-select-container': {},
            '& .formaloo-select__control': {
              minHeight: 50,
              borderRadius: 2,
            },
            '& .formaloo-select__multi-value': {
              backgroundColor: 'background.paper',
              borderRadius: 2,
              px: 1,
              py: 0.4,
            },
            '& .formaloo-select__indicator-separator': {
              display: 'none',
            },
          }}
        >
          <ReactSelectAsyncCreatable
            ref={ref}
            // provide a unique id to make it accessible
            aria-label={label}
            id={`${name}-select`}
            inputId={`${name}-id`}
            aria-labelledby={`${name}-label-id`}
            name={name}
            loadOptions={_loadOptions}
            cacheOptions={cacheOptions}
            autocomplete="off"
            defaultOptions={defaultOptions}
            isLoading={isLoading}
            value={innerValueState}
            onChange={_onChange}
            onCreateOption={_onCreateOption}
            getOptionLabel={getOptionLabel}
            getOptionValue={getOptionValue}
            isDisabled={isLoading || isDisabled || readOnly}
            isMulti={isMulti}
            classNamePrefix="formaloo-select"
            styles={{
              option: (styles, { isDisabled, isFocused, isSelected }) => {
                return {
                  ...styles,
                  color: muiTheme.palette.primary.dark,
                  backgroundColor: isDisabled
                    ? undefined
                    : isSelected
                    ? muiTheme.palette.background.paper
                    : isFocused
                    ? muiTheme.palette.background.paper
                    : undefined,
                  ':active': {
                    ...styles[':active'],
                    backgroundColor: !isDisabled
                      ? isSelected
                        ? muiTheme.palette.primary.main
                        : undefined
                      : undefined,
                  },
                }
              },
            }}
            theme={theme => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: muiTheme.palette.primary.main,
              },
            })}
            {...customizedTexts}
            {...props}
          />
        </Box>
        {description && <FormHelperText>{description}</FormHelperText>}
        {hasError && <InputErrors errors={errors} />}
      </div>
    )
  },
)

AsyncCreatableSelect.displayName = 'FormalooAsyncCreatableSelectInput'

AsyncCreatableSelect.defaultProps = {
  showLabel: true,
  autoFill: false,
  size: 'small',
  separateLabel: true,
  fullWidth: true,
  defaultOptions: true,
  cacheOptions: true,
  getOptionValue: option => (option ? option.slug : null),
  getOptionLabel: option => option?.title,
  isOptionSelected: (option, selectValue) =>
    Array.isArray(selectValue)
      ? selectValue.includes(option.slug)
      : option.slug === selectValue,
  getNewOptionData: (inputValue, optionLabel) => {
    return {
      slug: inputValue,
      title: optionLabel,
    }
  },
  formatCreateLabel: inputValue => (
    <FormattedMessage
      id="field.creatable.create"
      values={{ option: inputValue }}
    />
  ),
  hideSelectedOptions: true,
  shouldFetchDefaultValueOptions: false,
}

AsyncCreatableSelect.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    PropTypes.elementType,
  ]).isRequired,
  separateLabel: PropTypes.bool,
  showLabel: PropTypes.bool,
  isClearable: PropTypes.bool,
  description: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    PropTypes.elementType,
  ]),
  value: PropTypes.any,
  defaultValue: PropTypes.any,
  /**
   * 1. set to true to call load options immediately after mount
   * 2. set to default selected value(s)
   */
  defaultOptions: PropTypes.any,
  loadOptions: PropTypes.func,
  onChange: PropTypes.func,
  fullWidth: PropTypes.bool,
  autoFill: PropTypes.bool,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  size: PropTypes.oneOf(['small', 'normal']),
  errors: PropTypes.arrayOf(PropTypes.string),
  isMulti: PropTypes.bool,
  getOptionLabel: PropTypes.func.isRequired,
  getOptionValue: PropTypes.func.isRequired,
  getNewOptionData: PropTypes.func.isRequired,
  /**
   * Render the option, use getOptionLabel by default.
   *
   * ```
   * Signature:
   *  function(option: T, state: object) => ReactNode
   *  option: The option to render.
   *  state: The state of the component.
   * ```
   */
  renderOption: PropTypes.func,
  /**
   * Render the selected value.
   *
   * ```
   *  Signature:
   *  function(value: T[], getTagProps: function) => ReactNode
   *  value: The value provided to the component.
   *  getTagProps: A tag props getter.
   * ```
   */
  renderTags: PropTypes.func,
  /**
   * Used to determine if an option is selected,
   * considering the current value. Uses strict equality by default.
   *
   * ```
   * Signature:
   *  function(option: T, value: T) => boolean
   *  option: The option to test.
   *  value: The value to test against.
   * ```
   */
  isOptionSelected: PropTypes.func,
  isOptionDisabled: PropTypes.func,
  onInputChange: PropTypes.func,
  loadingText: PropTypes.string,
  noOptionsText: PropTypes.string,
  isRtl: PropTypes.bool,
  cacheOptions: PropTypes.bool,
  onCreateOption: PropTypes.func,
  loadingMessage: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    PropTypes.elementType,
  ]),
  noOptionsMessage: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    PropTypes.elementType,
  ]),
}

export default AsyncCreatableSelect
