export const BLOCK_LOCATIONS = {
  'left-sidebar': 'left',
  'right-sidebar': 'right',
  header: 'header',
  footer: 'footer',
  main: 'main',
  'non-primary': 'nonPrimary', //(Not included in board's main page)
}

export const LOCATIONS = {
  left: 'left-sidebar',
  right: 'right-sidebar',
  header: 'header',
  footer: 'footer',
  main: 'main',
  nonPrimary: 'non-primary',
}
