import { useState } from 'react'

export function useCopyToClipboard() {
  const [isCopied, setCopyStatus] = useState(false)
  const copyText = async (text = '') => {
    try {
      await navigator.clipboard.writeText(text)
      setCopyStatus(true)

      setTimeout(() => {
        setCopyStatus(false)
      }, 800)

      return Promise.resolve()
      /* Resolved - text copied to clipboard successfully */
    } catch (err) {
      return Promise.reject(err)
      /* Rejected - text failed to copy to the clipboard */
    }
  }
  return { isCopied, copyText }
}
