import Typography from '@mui/material/Typography'
import RadioGroupInput from 'stories/FormInputs/RadioGroup/RadioGroup'
import Box from '@mui/material/Box'

import { typeUtils } from 'types'
import {
  getFormItemErrors,
  getFormItemLabel,
  getGenericFormItemDefaultRawValue as getDefaultValue,
} from './_propGetters'
import { getRequiredRule } from './_validationProps'

const OptionLabel = ({ title, image }) => {
  return (
    <Box width="100%" my={0.5} mx={1}>
      {image && (
        <img
          style={{
            width: '100%',
            height: '150px',
            objectFit: 'contain',
            marginBottom: 10,
            borderRadius: 5,
            maxWidth: 250,
          }}
          src={image}
          alt={title}
        />
      )}
      {title && <Typography>{title}</Typography>}
    </Box>
  )
}

const Component = RadioGroupInput

const getProps = fieldProps => ({
  ...fieldProps,
  name: fieldProps.slug,
  label: getFormItemLabel(fieldProps),
  // input props
  separateLabel: true,
  options: fieldProps.choice_items.map(option => ({
    value: option.slug,
    label: <OptionLabel {...option} />,
  })),
})

const getControlledProps = (fieldProps, controllerProps, defaultValue) => ({
  ...controllerProps.field,
  type: typeUtils.getMostSpecificType(fieldProps),
  label: getFormItemLabel(fieldProps),
  // input props
  separateLabel: true,
  errors: getFormItemErrors(controllerProps),
  options: fieldProps.choice_items.map(option => ({
    value: option.slug,
    label: <OptionLabel {...option} />,
  })),
})

const getValidationRules = (fieldProps, options) => ({
  ...getRequiredRule(fieldProps, options),
})

export const radioGroupInput = {
  Component,
  getProps,
  getControlledProps,
  getValidationRules,
  getDefaultValue,
}
