export default function EditFormIcon({ color = '#28293D', ...props }) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M21 13.067V18.972C21 20.092 20.099 21 18.987 21H5.013C3.901 21 3 20.092 3 18.972V6.028C3 4.908 3.901 4 5.013 4H12"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.828 17.1722L11.559 16.2392C11.735 16.1952 11.895 16.1042 12.024 15.9762L20.414 7.58624C21.195 6.80524 21.195 5.53924 20.414 4.75824L20.242 4.58624C19.461 3.80524 18.195 3.80524 17.414 4.58624L9.024 12.9762C8.896 13.1042 8.805 13.2652 8.761 13.4412L7.828 17.1722"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.83 6.17041L18.83 9.17041"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.546 16.2424C11.56 16.1364 11.578 16.0314 11.578 15.9214C11.578 14.5404 10.459 13.4214 9.07796 13.4214C8.96796 13.4214 8.86296 13.4404 8.75696 13.4534"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
