import Loadable from 'react-loadable'

export default function LazyLoad(opts, shouldShowLoading) {
  return Loadable(
    Object.assign(
      {
        loading: () => (shouldShowLoading ? <span>loading...</span> : null),
        delay: 200,
        timeout: 10000,
      },
      opts,
    ),
  )
}
