export const toasterConfig = {
  position: 'bottom-center',
  reverseOrder: false,
  gutter: 8,
  toastOptions: {
    // Define default options
    className: '',
    duration: 3000,
    // Default options for specific types
    success: {
      duration: 3000,
      theme: {
        primary: 'green',
        secondary: 'black',
      },
    },
    error: {
      duration: 3000,
      style: {
        background: 'red',
        color: '#fff',
      },
    },
  },
}
