import { unAuthorizedConfig } from './utils'
import { HTTP, httpClient } from './utils/http'
import API from 'app-settings/api.settings'
import { createFormData } from 'utils/form/createData'

const config = (additionalSettings = {}) =>
  unAuthorizedConfig({
    ...additionalSettings,
    contentType: HTTP.ContentTypes.FormUrlEncoded,
  })

export const storageService = () => ({
  upload: (file, params = {}) => {
    return httpClient.post(
      `${API.baseUrl}/v1.0/files/`,
      createFormData(file),
      config(params),
    )
  },
  get: (fileSlug, params = {}) =>
    httpClient.get(
      `${API.baseUrl}/v1.0/files/${fileSlug}/`,
      config({ params }),
    ),
})
