import { getBoardMetaData } from 'utils/board/meta-data'
import { FakeEmbedAuthStorage } from 'dev-tools'
import { isInFakeEmbed } from 'utils/common'

export const AuthStorage = () => {
  if (isInFakeEmbed()) {
    return FakeEmbedAuthStorage()
  }

  try {
    const { formSlug } = getBoardMetaData()
    return typeof window.$$ === 'function' ? window.$$()[formSlug] : null
  } catch (e) {
    console.log(e)
  }
}

export const DashboardStorageSettings = {
  sessionKey: 'session',
}
