export default function ViewIcon({ color = '#28293D', ...props }) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.5915 7.41C11.4697 8.28825 11.4697 9.714 10.5915 10.5937C9.71321 11.472 8.28746 11.472 7.40771 10.5937C6.52946 9.7155 6.52946 8.28975 7.40771 7.41C8.28746 6.53025 9.71246 6.53025 10.5915 7.41"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.25 9C2.25 8.50575 2.364 8.01675 2.5845 7.566V7.566C3.72075 5.24325 6.23175 3.75 9 3.75C11.7682 3.75 14.2793 5.24325 15.4155 7.566V7.566C15.636 8.01675 15.75 8.50575 15.75 9C15.75 9.49425 15.636 9.98325 15.4155 10.434V10.434C14.2793 12.7567 11.7682 14.25 9 14.25C6.23175 14.25 3.72075 12.7567 2.5845 10.434V10.434C2.364 9.98325 2.25 9.49425 2.25 9Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
