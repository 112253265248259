import React from 'react'
import PropTypes from 'prop-types'
import NumberInput from '../Number/Number'

const NATIONAL_ID_LENGTH = 10

const formatNationalId = (id = '') =>
  id ? id.replace(/\D/g, '').substring(0, NATIONAL_ID_LENGTH) : id

const NationalIdInput = React.forwardRef(
  (
    {
      defaultValue,
      value: controlledValue,
      onChange: handleChange,
      type,
      ...props
    },
    forwardedRef
  ) => {
    const isControlledInput = React.useMemo(
      () => typeof handleChange === 'function',
      [handleChange]
    )
    const [value, setValue] = React.useState(defaultValue)

    const _onChange = (value) => {
      const _id = formatNationalId(value)

      if (isControlledInput) {
        handleChange(_id)
      } else {
        setValue(_id)
      }
    }

    const currentValue = isControlledInput ? controlledValue : value

    return (
      <NumberInput
        ref={forwardedRef}
        onChange={_onChange}
        value={currentValue}
        defaultValue={defaultValue}
        outputAsText={true}
        {...props}
      />
    )
  }
)

NationalIdInput.displayName = 'FormalooNationalIdInput'

NationalIdInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  showLabel: PropTypes.bool,
  separateLabel: PropTypes.bool,
  description: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    PropTypes.elementType,
  ]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * accepts the new numeric value as the argument and
   * should return the new (modified?) numeric value
   */
  onChange: PropTypes.func,
  fullWidth: PropTypes.bool,
  autoFill: PropTypes.bool,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  size: PropTypes.oneOf(['small', 'normal']),
  errors: PropTypes.arrayOf(PropTypes.string),
}

export default NationalIdInput
