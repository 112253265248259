export default function IntegrationIcon({ color = '#28293D', ...props }) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.22498 6.45176V6.45176C9.22498 4.91976 10.467 3.67676 12 3.67676V3.67676C13.532 3.67676 14.775 4.91876 14.775 6.45176V6.45176C14.775 7.98376 13.533 9.22676 12 9.22676V9.22676C10.467 9.22476 9.22498 7.98276 9.22498 6.45176Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.5 17.5479V17.5479C2.5 16.0159 3.742 14.7729 5.275 14.7729V14.7729C6.807 14.7729 8.05 16.0149 8.05 17.5479V17.5479C8.049 19.0799 6.808 20.3229 5.275 20.3229V20.3229C3.742 20.3229 2.5 19.0809 2.5 17.5479Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.951 17.5479V17.5479C15.951 16.0159 17.193 14.7729 18.726 14.7729V14.7729C20.258 14.7729 21.501 16.0149 21.501 17.5479V17.5479C21.501 19.0799 20.259 20.3229 18.726 20.3229V20.3229C17.192 20.3229 15.951 19.0809 15.951 17.5479H15.951Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.48998 11.9199L6.47998 15.0599"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5 8.77979L15.51 11.9198"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.95 17.5498H12"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
