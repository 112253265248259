import React from 'react'
import PropTypes from 'prop-types'
import FormHelperText from '@mui/material/FormHelperText'
import MuiInputLabel from '@mui/material/InputLabel'
import Typography from '@mui/material/Typography'
import { InputErrors } from '../_InputErrors'
import Box from '@mui/material/Box'
import RoundCheck from 'stories/icons/RoundCheck'
import { useTheme } from '@mui/material/styles'
import RoundCancel from 'stories/icons/RoundCancel'

const VerificationStatus = ({ subject, status }) => {
  const { palette } = useTheme()
  const StatusIcon = status ? (
    <RoundCheck color={palette.success.main} width={24} height={24} />
  ) : (
    <RoundCancel color={palette.error.main} width={24} height={24} />
  )

  return (
    <Box
      sx={{
        width: 'inherit',
        display: 'grid',
        gridAutoFlow: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        gridGap: 1,
        padding: 1,
      }}
    >
      {StatusIcon}
      <Typography variant="body1" component="span">
        {subject}
      </Typography>
    </Box>
  )
}

const VerificationInput = React.forwardRef(
  (
    {
      name,
      label,
      separateLabel,
      showLabel,
      description,
      value: controlledValue,
      defaultValue,
      onChange: handleChange,
      fullWidth,
      readOnly,
      disabled,
      placeholder,
      size,
      errors,
      type,
      isVerified: controlledIsVerified,
      ...props
    },
    ref,
  ) => {
    const hasError = errors?.length > 0

    const showSeparateLabel = showLabel && separateLabel

    const isControlledInput = React.useMemo(
      () => typeof handleChange === 'function',
      [handleChange],
    )

    const [value, setValue] = React.useState(defaultValue)

    const currentValue = true || isControlledInput ? controlledValue : value

    const _onChange = event => {
      const newValue = [...event.target.files]

      if (isControlledInput) {
        handleChange(newValue)
      } else {
        setValue(newValue)
      }
    }

    return (
      <Box
        sx={{
          display: 'grid',
          gridGap: 0.5,
          alignItems: 'start',
          justifyItems: 'start',
          alignContent: 'stretch',
          justifyContent: 'normal',
          width: fullWidth && showSeparateLabel ? '100%' : 'auto',
        }}
      >
        {showSeparateLabel && (
          <MuiInputLabel
            sx={{
              fontSize: '1.2rem',
              color: 'primary.lighten',
              mb: 1.5,
            }}
            htmlFor={name}
            error={hasError}
          >
            {label}
          </MuiInputLabel>
        )}
        {description && <FormHelperText>{description}</FormHelperText>}
        {readOnly && (
          <VerificationStatus
            subject={currentValue}
            status={controlledIsVerified}
          />
        )}
        {hasError && <InputErrors errors={errors} />}
      </Box>
    )
  },
)

VerificationInput.displayName = 'FormalooVerificationInput'

VerificationInput.defaultProps = {
  showLabel: true,
  size: 'small',
  separateLabel: false,
  fullWidth: true,
  readOnly: true,
}

VerificationInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  separateLabel: PropTypes.bool,
  showLabel: PropTypes.bool,
  description: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    PropTypes.elementType,
  ]),
  value: PropTypes.any,
  defaultValue: PropTypes.any,
  onChange: PropTypes.func,
  fullWidth: PropTypes.bool,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  size: PropTypes.oneOf(['small', 'normal']),
  errors: PropTypes.arrayOf(PropTypes.string),
  type: PropTypes.oneOf(['phone_verification', 'email_verification'])
    .isRequired,
  isVerified: PropTypes.bool,
}

export default VerificationInput
