/**
 * type, sub_type and json_key are properties of the field with which,
 * we can determine the final `Component`, `Options` or other type-dependant settings to display
 * and validate
 */

/**
 * @typedef {string} FieldType
 **/

/**
 * @typedef {string} FieldSubType
 **/

/**
 * @typedef {string} FieldJsonKey
 **/

/**
 * Enum for field type values.
 * @readonly
 * @enum {FieldType}
 */
export const FIELD_TYPE = {
  CHECKBOX: 'checkbox', // checkbox is a single input that must have a value yes or no, can not be null
  SINGLE_SELECT: 'choice',
  DATE: 'date',
  DROPDOWN: 'dropdown',
  EMAIL: 'email',
  EMAIL_VERIFICATION: 'email_verification',
  FILE: 'file',
  LONG_TEXT: 'long_text',
  MATRIX: 'matrix',
  META: 'meta',
  MONEY: 'money',
  MULTIPLE_SELECT: 'multiple_select',
  NUMBER: 'number',
  PHONE_VERIFICATION: 'phone_verification',
  PHONE: 'phone',
  PRODUCT: 'product',
  RATING: 'rating',
  SHORT_TEXT: 'short_text',
  SIGNATURE: 'signature',
  TIME: 'time',
  WEBSITE: 'website',
  YES_NO: 'yes_no', // this input can be yes, no and null so a radio group is used to implement it
  HIDDEN: 'hidden',
  LOCATION: 'location',
  CITY: 'city',
  TABLE: 'table',
  LOOKUP: 'lookup',
  USER: 'user',
  BARCODE: 'barcode',
  REGEX: 'regex',
  VARIABLE: 'variable',
  OEMBED: 'oembed',
}

/**
 * @typedef {object} FieldTypeSubType
 * @property {FieldSubType}
 */

/**
 * Enum for field sub_type values.
 * @readonly
 * @enum {FieldTypeSubType}
 */
export const FIELD_SUB_TYPE = {
  META: {
    SECTION: 'section',
    PAGE_BREAK: 'page_break',
  },
  RATING: {
    STAR: 'star',
    SCORE: 'score',
    NPS: 'nps',
    LIKE_DISLIKE: 'like_dislike',
    /** NPS Widget */
    EMBEDDED: 'embeded', // typo in backend
  },
}

/**
 * @typedef {object} FieldTypeJsonKey
 * @property {FieldJsonKey}
 */

/**
 * Enum for field json_key values.
 * @readonly
 * @enum {FieldTypeJsonKey}
 */
export const FIELD_JSON_KEY = {
  SHORT_TEXT: { NATIONAL_NUMBER: 'national_number' },
}

export const GENERIC_FIELD_TYPE = {
  TEXT: 'text',
  DATE: 'date',
  TIME: 'time',
  NUMBER: 'number',
  SELECT: 'select',
  BOOLEAN: 'boolean',
  FILE: 'file',
  OBJECT: 'object',
}
