import { FormattedMessage } from 'react-intl'

export const StaticPagesSlugs = {
  USERS: 'page_users',
  USER_PROFILE: 'page_profile',
}

export const Users = {
  title: <FormattedMessage id="app.users.title" />,
  slug: StaticPagesSlugs.USERS,
  isStatic: true,
}

export const UserProfile = {
  title: <FormattedMessage id="app.user.profile" />,
  slug: StaticPagesSlugs.USER_PROFILE,
  isStatic: true,
}
