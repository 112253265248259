import AsyncSelectInput from 'stories/FormInputs/AsyncSelect/ReactSelect'

import { form } from 'api/form'
import { typeUtils } from 'types'
import { getRequiredRule } from './_validationProps'
import { getFormItemErrors, getFormItemLabel } from './_propGetters'

const Component = AsyncSelectInput

const getDefaultValue = fieldProps => fieldProps.raw_value

const fetchOptions =
  fieldProps =>
  async ({ searchValue, defaultValue: slug }) => {
    const search = searchValue?.split('-')[0]?.trim()

    const params = slug ? { slug } : { search }
    const {
      data: { data },
    } = await form().getFieldChoices(fieldProps.slug, params)
    try {
      return data.objects
    } catch (error) {
      throw Error('encountered an error while fetching options!')
    }
  }

const getProps = fieldProps => ({
  ...fieldProps,
  name: fieldProps.slug,
  label: getFormItemLabel(fieldProps),
  // input props
  separateLabel: true,
  autoFill: false,
  fetchOptions: fetchOptions(fieldProps),
  defaultOptions: true,
})

const getControlledProps = (fieldProps, controllerProps, defaultValue) => ({
  ...controllerProps.field,
  type: typeUtils.getMostSpecificType(fieldProps),
  label: getFormItemLabel(fieldProps),
  // input props
  separateLabel: true,
  autoFill: false,
  errors: getFormItemErrors(controllerProps),
  fetchOptions: fetchOptions(fieldProps),
  isClearable: !fieldProps.required,
  // onChange: (newValue) => controllerProps.field.onChange(newValue),
  defaultValue,
})

const getValidationRules = (fieldProps, options) => ({
  ...getRequiredRule(fieldProps, options),
})

export const cityInput = {
  Component,
  getProps,
  getControlledProps,
  getValidationRules,
  getDefaultValue,
}
