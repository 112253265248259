import Button from './Button'
import { useCopyToClipboard } from 'hooks/copyToClipboard/index'
import Tooltip from 'stories/Tooltip'
import { useIntl } from 'react-intl'
import Box from '@mui/material/Box'

export function CopyButton({
  alt = '',
  icon,
  text,
  wrapperStyle = {},
  ...props
}) {
  const { isCopied, copyText } = useCopyToClipboard()
  const { formatMessage } = useIntl()
  return (
    <Box sx={wrapperStyle} component="span">
      <Tooltip
        sx={{ display: 'inline-block' }}
        message={isCopied ? formatMessage({ id: 'copied' }) : alt}
      >
        <Button
          variant="text"
          onClick={() => {
            copyText(text)
          }}
          {...props}
        >
          {icon}
        </Button>
      </Tooltip>
    </Box>
  )
}
