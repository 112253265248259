import React from 'react'
import { createRoot } from 'react-dom/client'
import axiosConfig from './api/utils/axiosConfig'
import App from './App'
import reportWebVitals from './reportWebVitals'
import assetSettings from './app-settings/assets.settings'
import setupLogger from './utils/logger/setup'
import { setupFakeEmbed } from 'dev-tools'

const {
  boardWrapperId,
  environment: { devMode },
} = assetSettings

axiosConfig()

setupFakeEmbed()

if (!devMode) {
  setupLogger()
}

const container = document.getElementById(boardWrapperId)
const root = createRoot(container) // createRoot(container!) if you use TypeScript
root.render(<App />)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
