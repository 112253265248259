import { createContext, useContext, useState } from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'
import { LOCATIONS } from '../utils/board/locations'

const ResponsiveViewContext = createContext()

const ResponsiveViewProvider = ({ children }) => {
  const [isLeftSidebarOpen, setLeftSidebar] = useState(false)
  // TODO: adds other locations
  const sidebarStates = {
    [LOCATIONS.left]: isLeftSidebarOpen,
  }
  const sidebarControllers = {
    [LOCATIONS.left]: status => setLeftSidebar(status),
  }
  const isMobileScreen = useMediaQuery('(max-width:769px)')
  const isTabletScreen = useMediaQuery('(max-width:1024px)')
  const isMiniMobileScreen = useMediaQuery('(max-width:480px)')

  const SidebarWidth = 256

  const closeOpenSidebar = (sidebarLocation = LOCATIONS.left) => {
    if (sidebarStates[sidebarLocation]) {
      sidebarControllers[sidebarLocation](false)
    }
  }
  return (
    <ResponsiveViewContext.Provider
      value={{
        isLeftSidebarOpen,
        setLeftSidebar,
        isMobileScreen,
        isTabletScreen,
        isMiniMobileScreen,
        SidebarWidth,
        closeOpenSidebar,
      }}
    >
      {children}
    </ResponsiveViewContext.Provider>
  )
}

function useResponsiveView() {
  const context = useContext(ResponsiveViewContext)
  if (context === undefined) {
    throw new Error(
      `useResponsiveView must be used within a ResponsiveViewProvider`,
    )
  }
  return context
}

export { ResponsiveViewProvider, useResponsiveView }
