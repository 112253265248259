import PrimaryBoard from 'components/board'
import { BoardProvider } from 'context/board'
import { IntlProvider } from 'context/intl'
import { ThemeProvider } from 'context/theme'

function App() {
  return (
    <IntlProvider>
      <ThemeProvider>
        <BoardProvider>
          <PrimaryBoard />
        </BoardProvider>
      </ThemeProvider>
    </IntlProvider>
  )
}

export default App
