import Box from '@mui/material/Box'

const UIContainer = ({ children }) => {
  return (
    <Box
      sx={{
        width: '100%',
        p: 1,
        backgroundColor: 'background.paper',
        borderRadius: 2,
      }}
    >
      {children}
    </Box>
  )
}

export default UIContainer
