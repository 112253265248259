import TextInput from 'stories/FormInputs/Text/Text'

import { typeUtils } from 'types'
import {
  getFormItemErrors,
  getFormItemLabel,
  getGenericFormItemDefaultValue,
} from './_propGetters'
import { getRequiredRule } from './_validationProps'

const getDefaultValue = fieldProps =>
  getGenericFormItemDefaultValue(fieldProps, '')

const Component = TextInput

const getProps = fieldProps => ({
  ...fieldProps,
  name: fieldProps.slug,
  label: getFormItemLabel(fieldProps),
  // input props
  separateLabel: true,
  autoFill: false,
  multiline: false,
})

const getControlledProps = (fieldProps, controllerProps, defaultValue) => ({
  ...controllerProps.field,
  type: typeUtils.getMostSpecificType(fieldProps),
  label: getFormItemLabel(fieldProps),
  // input props
  separateLabel: true,
  autoFill: false,
  multiline: false,
  errors: getFormItemErrors(controllerProps),
  isEditable: fieldProps.is_editable,
})

const getValidationRules = (fieldProps, options) => ({
  ...getRequiredRule(fieldProps, options),
})

export const variable = {
  Component,
  getProps,
  getControlledProps,
  getValidationRules,
  getDefaultValue,
}
