import { form } from 'api/form'
import { useBoardFn } from 'hooks/board'

export const useFormFn = () => {
  const { isPrivate } = useBoardFn()

  const getFormsList = async (params = {}) => {
    try {
      const {
        data: { data },
      } = await form().getAll(params)
      return Promise.resolve(data.forms)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const getForm = async (formSlug, formAddress, params = {}) => {
    const getFormData = isPrivate
      ? form().getOne(formSlug, params)
      : form().getOneBySlugPublic(formSlug, params)

    try {
      const {
        data: { data },
      } = await getFormData
      return Promise.resolve(data.form)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const getPublicForm = async (formSlug, params = {}) => {
    try {
      const {
        data: { data },
      } = await form().getOneBySlugPublic(formSlug, params)
      return Promise.resolve(data.form)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const getRows = async (formSlug, params = {}) => {
    try {
      const {
        data: { data },
      } = await form().getRows(formSlug, params)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const getOneRow = async (rowSlug, params = {}) => {
    try {
      const {
        data: { data },
      } = await form().getOneRow(rowSlug, params)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
  const updateFormField = async (fieldSlug, formData = {}) => {
    try {
      const {
        data: { data },
      } = await form().updateField(fieldSlug, formData)
      return Promise.resolve(data.field)
    } catch (error) {
      return Promise.reject(error)
    }
  }
  return {
    getFormsList,
    getForm,
    getRows,
    getOneRow,
    updateFormField,
    getPublicForm,
  }
}
