const OAuthServer = {
  baseUrl: process.env.REACT_APP_ICAS_API_URL,
  apiKey: process.env.REACT_APP_ICAS_API_KEY,
  redirectUri:
    process.env.REACT_APP_ICAS_REDIRECT_URI ||
    `${window.location.origin}/oauth2/exchange/`,
  icasAuthorizationUrl: `${process.env.REACT_APP_ICAS_API_URL}/v1/profiles/authorize/`,
}

export default OAuthServer
