import { tags } from 'api/response-tags'

export const fetchResponseTagOptions =
  formSlug =>
  async ({ searchValue: search, slug }) => {
    const searchParams = slug ? { slug } : { search }
    const {
      data: { data },
    } = await tags().getAll(formSlug, searchParams)
    try {
      return data.row_tags ? data.row_tags : data.objects
    } catch (error) {
      throw Error('encountered an error while fetching options!')
    }
  }
