import OptionsIcon from 'stories/icons/Options'
import { FormattedMessage, useIntl } from 'react-intl'
import { useTheme } from '@mui/material/styles'
import { useState } from 'react'
import Box from '@mui/material/Box'
import Slide from '@mui/material/Slide'
import { useResponsiveView } from 'context/responsive-view'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import { shouldFlip } from 'stories/helpers/theme'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import { useBoard } from 'context/board'
import urlSettings from 'app-settings/url.settings'
import EditFormIcon from '../../../stories/icons/EditForm'
import ShareIcon from '../../../stories/icons/Share'
import ChartIcon from '../../../stories/icons/Chart'
import IntegrationIcon from '../../../stories/icons/Integration'
import ResponseIcon from '../../../stories/icons/Response'
import PresentationIcon from '../../../stories/icons/Presentation'
import { APP_CLASS_PREFIX } from 'utils/fix-classnames'
import ViewIcon from 'stories/icons/View'
import { useFormUrl } from 'hooks/form/useFormUrl'

const { formBuilderDomain } = urlSettings

export default function SummaryOptions({}) {
  const [isMenuOpen, setMenu] = useState(false)

  const theme = useTheme()
  const { boardInfo } = useBoard()
  const formUrl = useFormUrl(boardInfo?.primary_form)
  const { SidebarWidth, isMobileScreen } = useResponsiveView()
  const { formatMessage } = useIntl()

  const options = [
    {
      title: formatMessage({ id: 'summary.edit' }),
      icon: <EditFormIcon color={theme.palette.primary.dark} />,
      href: `${formBuilderDomain}/u/my-forms/${boardInfo?.primary_form?.slug}/edit`,
    },
    {
      title: formatMessage({ id: 'summary.view' }),
      icon: <ViewIcon color={theme.palette.primary.dark} />,
      href: `${formUrl}`,
      target: '_blank',
    },
    {
      title: formatMessage({ id: 'summary.share' }),
      icon: <ShareIcon color={theme.palette.primary.dark} />,
      href: `${formBuilderDomain}/u/my-forms/${boardInfo?.primary_form?.slug}/results?tab=share`,
    },
    {
      title: formatMessage({ id: 'summary.chart' }),
      icon: <ChartIcon color={theme.palette.primary.dark} />,
      href: `${formBuilderDomain}/u/my-forms/${boardInfo?.primary_form?.slug}/results?tab=charts`,
    },
    {
      title: formatMessage({ id: 'summary.response' }),
      icon: <ResponseIcon color={theme.palette.primary.dark} />,
      href: `${formBuilderDomain}/u/my-forms/${boardInfo?.primary_form?.slug}/results?tab=submits`,
    },
    {
      title: formatMessage({ id: 'summary.integration' }),
      icon: <IntegrationIcon color={theme.palette.primary.dark} />,
      href: `${formBuilderDomain}/u/my-forms/${boardInfo?.primary_form?.slug}/results?tab=integrations`,
    },
    {
      title: formatMessage({ id: 'summary.presentation' }),
      icon: <PresentationIcon color={theme.palette.primary.dark} />,
      href: `${formBuilderDomain}/u/my-forms/${boardInfo?.primary_form?.slug}/results?tab=live`,
    },
  ]
  return (
    <>
      <Box
        onClick={() => {
          setMenu(true)
        }}
        sx={{
          display: 'flex',
          alignItems: 'center',
          height: 40,
          px: 1,
          py: 1.5,
          // mb: 1,
          minWidth: 'auto',
          borderRadius: 2,
          ml: 'auto',
          color: 'text.primary',
          fontWeight: 600,
          textTransform: 'capitalize',
          cursor: 'pointer',
          '& > *:first-of-type': { mr: 1 },
          backgroundColor: isMenuOpen ? 'primary.active' : 'inherit',
        }}
      >
        <OptionsIcon
          color={theme.palette.primary.dark}
          style={{ width: 20, height: 20 }}
        />
        <FormattedMessage
          id="form.options"
          description="Button text"
          defaultMessage="Edit"
        />
      </Box>

      {isMenuOpen && (
        <Box
          width="100%"
          height="100vh"
          className={`${APP_CLASS_PREFIX}_from_options`}
          sx={{
            backgroundColor: 'rgba(28, 35, 46, 0.7)',
            position: 'fixed',
            top: 0,
            left: isMobileScreen ? 0 : SidebarWidth,
            right: 0,
            bottom: 0,
            zIndex: isMobileScreen ? 1 : -1,
            backdropFilter: 'blur(3px)',
          }}
        >
          <Slide in={true} direction={shouldFlip(theme) ? 'left' : 'right'}>
            <Box width="100%" height="100%">
              <ClickAwayListener
                onClickAway={() => {
                  setMenu(false)
                }}
              >
                <Box
                  sx={{
                    width: SidebarWidth,
                    height: '100%',
                    backgroundColor: 'background.default',
                    p: 1.5,
                    borderTopRightRadius: 8,
                    borderBottomRightRadius: 8,
                  }}
                >
                  {options.map((option, index) => (
                    <Link
                      key={index}
                      href={option.href}
                      target={option.target || '_self'}
                      underline="none"
                      sx={{
                        opacity: isMenuOpen ? 1 : 0,
                        display: 'flex',
                        alignItems: 'center',
                        minHeight: 35,
                        borderRadius: 2,
                        p: 1.5,
                        mb: 1,
                        transition: 'opacity 1s',
                        '&:hover': { backgroundColor: 'primary.active' },
                      }}
                    >
                      {option.icon}
                      <Typography
                        sx={{ ml: 1, fontWeight: 500 }}
                        color="primary.dark"
                        variant="body2"
                      >
                        {option.title}
                      </Typography>
                    </Link>
                  ))}
                </Box>
              </ClickAwayListener>
            </Box>
          </Slide>
        </Box>
      )}
    </>
  )
}
