import NationalId from 'stories/FormInputs/NationalId/NationalId'
import { typeUtils } from 'types'
import {
  getFormItemErrors,
  getFormItemLabel,
  getGenericFormItemDefaultValue,
} from './_propGetters'
import {
  getMaxLengthRule,
  getMinLengthRule,
  getRequiredRule,
} from './_validationProps'

const Component = NationalId

const getDefaultValue = fieldProps =>
  getGenericFormItemDefaultValue(fieldProps, '')

const getProps = fieldProps => ({
  ...fieldProps,
  name: fieldProps.slug,
  label: getFormItemLabel(fieldProps),
  // input props
  separateLabel: true,
  autoFill: false,
})

const getControlledProps = (fieldProps, controllerProps, defaultValue) => ({
  ...controllerProps.field,
  type: typeUtils.getMostSpecificType(fieldProps),
  label: getFormItemLabel(fieldProps),
  // input props
  separateLabel: true,
  autoFill: false,
  errors: getFormItemErrors(controllerProps),
})

const getValidationRules = (fieldProps, options) => ({
  ...getRequiredRule(fieldProps, options),
  ...getMaxLengthRule(fieldProps, 10),
  ...getMinLengthRule(fieldProps, 10),
})

export const nationalNumber = {
  Component,
  getProps,
  getControlledProps,
  getValidationRules,
  getDefaultValue,
}
