import { useResponsiveView } from 'context/responsive-view'
import Box from '@mui/material/Box'
import ArrowLeft from 'stories/icons/ArrowLeft'
import Typography from '@mui/material/Typography'
import { FormattedMessage } from 'react-intl'

export default function CloseButton() {
  const { isMobileScreen, isLeftSidebarOpen, setLeftSidebar } =
    useResponsiveView()

  const toggleSidebar = () => setLeftSidebar()
  if (!isMobileScreen) return null
  return (
    <Box
      width="100%"
      display="flex"
      alignItems="center"
      justifyContent="flex-end"
      mb={1}
    >
      <Box
        display="inline-flex"
        alignItems="center"
        sx={{
          cursor: 'pointer',
        }}
        onClick={toggleSidebar}
      >
        <ArrowLeft
          style={{
            transform: isLeftSidebarOpen ? 'rotate(0)' : 'rotate(180deg)',
            width: 20,
          }}
        />
        <Typography component="span" variant="caption">
          {isLeftSidebarOpen ? (
            <FormattedMessage id="close" />
          ) : (
            <FormattedMessage id="view" />
          )}
        </Typography>
      </Box>
    </Box>
  )
}
