import { FormattedMessage } from 'react-intl'
import RadioGroupInput from 'stories/FormInputs/RadioGroup/RadioGroup'
import { typeUtils } from 'types'
import {
  getFormItemErrors,
  getFormItemLabel,
  getGenericFormItemDefaultRawValue as getDefaultValue,
} from './_propGetters'
import { getRequiredRule } from './_validationProps'

const options = [
  { value: 'yes', label: <FormattedMessage id="yes" /> },
  { value: 'no', label: <FormattedMessage id="no" /> },
]

const Component = RadioGroupInput

const getProps = fieldProps => ({
  ...fieldProps,
  name: fieldProps.slug,
  label: getFormItemLabel(fieldProps),
  // input props
  separateLabel: true,
  options,
})

const getControlledProps = (fieldProps, controllerProps, defaultValue) => ({
  ...controllerProps.field,
  type: typeUtils.getMostSpecificType(fieldProps),
  label: getFormItemLabel(fieldProps),
  // input props
  separateLabel: true,
  errors: getFormItemErrors(controllerProps),
  options,
})

const getValidationRules = (fieldProps, options) => ({
  ...getRequiredRule(fieldProps, options),
})

export const yesNoInput = {
  Component,
  getProps,
  getControlledProps,
  getValidationRules,
  getDefaultValue,
}
