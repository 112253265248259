import React, { useRef, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import Box from '@mui/material/Box'
import Button from 'stories/button/Button'
import { useBoard } from 'context/board'
import { board } from 'api/board'
import { useMountEffect } from 'hooks/react'
import { useAuthContext } from 'context/auth'
import AccountMenu from './AccountMenu'
import { useBoardFn } from 'hooks/board'
import Modal from 'stories/modal/Modal'
import ProfileForm from '../page/static/Profile'
import isEmpty from 'ramda/es/isEmpty'
import isNil from 'ramda/es/isNil'
import { useResponsiveView } from 'context/responsive-view'

const getWindowName = uniqueId => '___FORMALOO_APP_AUTH___' + uniqueId

// window features
const windowFeatures = `toolbar=no, menubar=no, width=550, height=650, top=100, left=${
  (window.innerWidth - 550) / 2
}`

const receiveMessage = (event, uniqueId) => {
  if (
    event.source.name === getWindowName(uniqueId) &&
    event.source.location.origin === window.location.origin
  ) {
    window.location.reload()
  }
}

const AuthBox = () => {
  const { isLoading, isAuthenticated, user } = useAuthContext()
  const { boardInfo, isAuthEnabled } = useBoard()
  const { isPrivate } = useBoardFn()
  const { isMobileScreen } = useResponsiveView()

  const isFirstLogin = useMemo(
    () =>
      user &&
      boardInfo?.user_form?.form_fields?.edited_by_user &&
      user.rendered_data?.[boardInfo?.user_form?.form_fields?.edited_by_user]
        ?.value === 'no',
    [user, boardInfo?.user_form],
  )

  const shouldFillUserForm = useMemo(
    () =>
      user &&
      Object.keys(user.rendered_data).some(
        fieldName =>
          user.rendered_data?.[fieldName].required &&
          (isNil(user.rendered_data?.[fieldName]?.value) ||
            isEmpty(user.rendered_data?.[fieldName]?.value)),
      ),
    [user],
  )

  const appId = boardInfo?.share_address

  const windowRef = useRef(null)

  const messageListener = event => receiveMessage(event, appId)

  useMountEffect(() => {
    window.addEventListener('message', messageListener, false)

    return () => {
      window.removeEventListener('message', messageListener, false)
    }
  })

  const handleClick = async action => {
    // ignore click if already opened a window
    if (windowRef.current && !windowRef.current.closed) {
      return
    }

    windowRef.current = window.open('', getWindowName(appId), windowFeatures)

    windowRef.current.document.write('please wait...')

    try {
      const { data } = await board().getAuthUrl({
        appId,
        action,
        redirectUri: boardInfo?.custom_domain
          ? `https://${boardInfo?.custom_domain}/auth/success/`
          : `${window.location.origin}/auth/success/${appId}`,
      })

      windowRef.current.location.replace(data.data.oauth2.redirection_url)

      windowRef.current.focus()
    } catch (e) {
      // TODO: handle error
      windowRef.current.close()
    }
  }

  if (!boardInfo || !isAuthEnabled || isPrivate) return null

  return (
    <>
      <Box
        sx={{
          position: 'absolute',
          top: 8,
          right: isMobileScreen ? 'auto' : 24,
          left: isMobileScreen ? 8 : 'auto',
          zIndex: 10,
          width: 'auto',
        }}
      >
        {isLoading ? (
          'Loading...'
        ) : isAuthenticated ? (
          <AccountMenu />
        ) : (
          <>
            <Button
              variant="text"
              color="secondary"
              onClick={() => handleClick('login')}
              sx={{
                mr: 1,
                color: '#794EF1',
                '&:hover': { backgroundColor: '#F1EDFF' },
              }}
            >
              <FormattedMessage id="login" />
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => handleClick('signup')}
              sx={{
                backgroundColor: '#794EF1',
                '&:hover': { backgroundColor: '#8460FF', boxShadow: 'none' },
              }}
            >
              <FormattedMessage id="signup" />
            </Button>
          </>
        )}
      </Box>
      <Modal
        open={Boolean(isFirstLogin && shouldFillUserForm)}
        disableCloseButton
        onClose={() => {}}
        maxWidth={false}
        fullWidth
      >
        <ProfileForm />
      </Modal>
    </>
  )
}

export default AuthBox
