import Skeleton from '@mui/material/Skeleton'

export default function TextSkeleton({
  className = '',
  rows = 1,
  variant,
  animation = 'wave',
}) {
  return Array.from(new Array(rows)).map((_, index) => (
    <Skeleton
      className={className}
      key={index}
      variant={variant}
      animation={animation}
      style={{ marginBottom: 8 }}
    />
  ))
}
