import PropTypes from 'prop-types'
import TextInput from '../Text/Text'
import NumberInput from '../Number/Number'
import RadioInput from '../RadioGroup/Radio'
import CheckboxInput from '../CheckboxGroup/Checkbox'

const DefaultOptionNumberInput = ({
  question: q,
  option,
  onChange,
  value: controlledValue = {},
}) => {
  const _onChange = (newValue) => {
    onChange(q.slug, { ...controlledValue, [option.slug]: newValue })
  }

  return (
    <NumberInput
      name={q.slug}
      label={q.title}
      showLabel={false}
      onChange={_onChange}
      value={controlledValue[option.slug] ?? ''}
      autoFill={false}
    />
  )
}

const DefaultOptionTextInput = ({
  question: q,
  option,
  onChange,
  value: controlledValue = {},
}) => {
  const _onChange = (event) => {
    onChange(q.slug, { ...controlledValue, [option.slug]: event.target.value })
  }

  return (
    <TextInput
      name={q.slug}
      label={q.title}
      showLabel={false}
      onChange={_onChange}
      value={controlledValue[option.slug] ?? ''}
      autoFill={false}
    />
  )
}

const DefaultOptionCheckboxInput = ({
  question: q,
  option,
  onChange,
  value: controlledValue = {},
}) => {
  const isChecked = controlledValue[option.slug] === true

  const _onChange = (event) => {
    // onChange(
    //   `${q.slug}.${option.slug}`,
    //   event.target.checked ? option.slug : ''
    // )

    onChange(q.slug, {
      ...controlledValue,
      [option.slug]: event.target.checked || '',
    })
  }

  return (
    <CheckboxInput
      name={`${q.slug}.${option.slug}`}
      onChange={_onChange}
      checked={isChecked}
    />
  )
}

const DefaultOptionRadioInput = ({
  question: q,
  option,
  onChange,
  value: controlledValue,
}) => {
  const isChecked = controlledValue === option.slug

  const _onChange = (event) => {
    onChange(q.slug, event.target.checked ? option.slug : '')
  }

  return (
    <RadioInput
      name={q.slug}
      value={option.slug}
      onChange={_onChange}
      checked={isChecked}
    />
  )
}

const optionInputTypes = {
  number: DefaultOptionNumberInput,
  text: DefaultOptionTextInput,
  boolean: DefaultOptionCheckboxInput,
  radio: DefaultOptionRadioInput,
}

const DefaultOptionInput = (props) => {
  const InputComponent =
    optionInputTypes[props.option.type] ?? DefaultOptionRadioInput

  return <InputComponent {...props} />
}

DefaultOptionInput.propTypes = {
  question: PropTypes.object,
  option: PropTypes.object,
  onChange: PropTypes.func,
  value: PropTypes.any,
}

export default DefaultOptionInput
