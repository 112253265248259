import { faIR, enUS, koKR } from '@mui/material/locale'

import DanaWoff from './fonts/dana/woff2/dana-regular.woff2'
import DanaMedium from './fonts/dana/woff2/dana-medium.woff2'
import DanaThin from './fonts/dana/woff2/dana-light.woff2'
import DanaBold from './fonts/dana/woff2/dana-ultrabold.woff2'
import DanaBook from './fonts/dana/woff2/dana-black.woff2'

import markPro from './fonts/mark/Mark-Regular.woff2'
import markProMedium from './fonts/mark/Mark-Medium.woff2'
import markProThin from './fonts/mark/Mark-Thin.woff2'
import markProBold from './fonts/mark/Mark-Bold.woff2'
import markProHeavy from './fonts/mark/Mark-Heavy.woff2'
import markProBook from './fonts/mark/Mark-Book.woff2'

export const persianFont = `@font-face {
          font-family: dana;
          font-style: normal;
          font-display: swap;
          font-weight: 300;
          src: local('dana'), local('dana-light'), url(${DanaThin}) format('woff2');
        }
        @font-face {
          font-family: dana;
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('dana'), local('dana-regular'), url(${DanaWoff}) format('woff2');
        }
        @font-face {
          font-family: dana;
          font-style: normal;
          font-display: swap;
          font-weight: 500;
          src: local('dana'), local('dana-medium'), url(${DanaMedium}) format('woff2');
        }
       @font-face {
          font-family: dana;
          font-style: normal;
          font-display: swap;
          font-weight: 800;
          src: local('dana'), local('dana-ultrabold'), url(${DanaBold}) format('woff2');
        }
        @font-face {
          font-family: dana;
          font-style: normal;
          font-display: swap;
          font-weight: 900;
          src: local('dana'), local('dana-bold'), url(${DanaBook}) format('woff2');
        }
        body {
          overflow-x: clip;
          max-width: 100%
        }
      `
export const englishFont = `
 @font-face {
          font-family: ff-mark-pro;
          font-style: normal;
          font-display: swap;
          font-weight: 300;
          src: local('ff-mark-pro'), local('Mark-Thin'), url(${markProThin}) format('woff2');
        }
@font-face {
          font-family: ff-mark-pro;
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('ff-mark-pro'), local('Mark-Regular'), url(${markPro}) format('woff2');
        }
        @font-face {
          font-family: ff-mark-pro;
          font-style: normal;
          font-display: swap;
          font-weight: 500;
          src: local('ff-mark-pro'), local('Mark-Book'), url(${markProBook}) format('woff2');
        }
          @font-face {
          font-family: ff-mark-pro;
          font-style: normal;
          font-display: swap;
          font-weight: 600;
          src: local('ff-mark-pro'), local('Mark-Medium'), url(${markProMedium}) format('woff2');
        }
          @font-face {
          font-family: ff-mark-pro;
          font-style: normal;
          font-display: swap;
          font-weight: 800;
          src: local('ff-mark-pro'), local('Mark-Bold'), url(${markProBold}) format('woff2');
        }
         @font-face {
          font-family: ff-mark-pro;
          font-style: normal;
          font-display: swap;
          font-weight: 900;
          src: local('ff-mark-pro'), local('Mark-Heavy'), url(${markProHeavy}) format('woff2');
        }
      `

export const themeLocale = {
  fa: { faIR, font: persianFont, name: 'dana' },
  en: { enUS, font: englishFont, name: 'ff-mark-pro' },
  ko: { koKR, font: englishFont, name: 'ff-mark-pro' },
}
