import getRawMessage from 'locales/localeDirectAccess'
import MatrixInput from 'stories/FormInputs/Matrix/Matrix'

import { typeUtils } from 'types'
import {
  getFormItemErrors,
  getFormItemLabel,
  getGenericFormItemDefaultRawValue as getDefaultValue,
} from './_propGetters'

const Component = MatrixInput

const getProps = fieldProps => ({
  ...fieldProps,
  name: fieldProps.slug,
  label: getFormItemLabel(fieldProps),
  // input props
  options: fieldProps.choice_items,
  questions: fieldProps.choice_groups,
})

const getControlledProps = (fieldProps, controllerProps, defaultValue) => ({
  ...controllerProps.field,
  type: typeUtils.getMostSpecificType(fieldProps),
  label: getFormItemLabel(fieldProps),
  // input props
  options: fieldProps.choice_items,
  questions: fieldProps.choice_groups,
  errors: getFormItemErrors(controllerProps),
})

const getValidationRules = (fieldProps, options) => ({
  ...(!options.ignoreRequired &&
    fieldProps.required && {
      required: {
        value: fieldProps.required,
        message: getRawMessage('form.validation.required.matrix'),
      },
    }),
})

export const matrixInput = {
  Component,
  getProps,
  getControlledProps,
  getValidationRules,
  getDefaultValue,
}
