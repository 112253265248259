import React from 'react'
import MuiCheckbox from '@mui/material/Checkbox'

const Checkbox = React.forwardRef(({ ...props }, forwardedRef) => {
  return (
    <MuiCheckbox
      inputRef={forwardedRef}
      sx={{
        color: '#794EF1',
        '&.Mui-checked': {
          color: '#794EF1',
        },
      }}
      {...props}
    />
  )
})

export default Checkbox
