import React from 'react'
import MuiRadio from '@mui/material/Radio'

const Radio = React.forwardRef(({ ...props }, forwardedRef) => {
  return (
    <MuiRadio
      inputRef={forwardedRef}
      sx={{
        color: '#794EF1',
        '&.Mui-checked': {
          color: '#794EF1',
        },
      }}
      {...props}
    />
  )
})

export default Radio
