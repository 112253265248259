import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import FormHelperText from '@mui/material/FormHelperText'
import MuiInputLabel from '@mui/material/InputLabel'
import { InputErrors } from '../_InputErrors'

import Box from '@mui/material/Box'
import Link from '@mui/material/Link'
import DeleteIcon from 'stories/icons/Delete'
import { isValidHTML } from 'utils/formatter/html'
import Button from 'stories/button/Button'

const SignaturePreview = ({ value, label }) => {
  if (!value)
    return (
      <Box
        sx={{
          height: '100px',
          border: '1px solid lightgray',
          backgroundColor: 'lightgray',
          padding: '4px',
          borderRadius: '4px',
          display: 'flex',
          width: 'inherit',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <FormattedMessage id="form.signature.notSigned" />
      </Box>
    )

  // handle default value (url)
  if (isValidHTML(value)) {
    const fileSrc =
      value.split('href="')[1] && value.split('href="')[1].split('"')[0]

    return (
      <Link
        sx={{
          height: '100px',
          border: '1px solid lightgray',
          backgroundColor: 'lightgray',
          padding: '4px',
          borderRadius: '4px',
          display: 'flex',
          width: 'inherit',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        target="_blank"
        href={fileSrc}
        rel="noreferrer noopener nofollow"
      >
        <img
          src={fileSrc}
          alt={label}
          style={{
            maxWidth: '100%',
            objectFit: 'contain',
          }}
        />
      </Link>
    )
  }

  return (
    <div
      data-error="i don't know how to preview"
      data-value={JSON.stringify(value)}
    />
  )
}

const SignatureInput = React.forwardRef(
  (
    {
      name,
      label,
      buttonText,
      separateLabel,
      showLabel,
      description,
      defaultValue,
      value: controlledValue,
      onChange: handleChange,
      fullWidth,
      readOnly,
      disabled,
      placeholder,
      size,
      errors,
      renderPreview,
      ...props
    },
    ref,
  ) => {
    const hasError = errors?.length > 0

    const showSeparateLabel = showLabel && separateLabel

    const isControlledInput = React.useMemo(
      () => typeof handleChange === 'function',
      [handleChange],
    )

    const [value, setValue] = React.useState(defaultValue)

    const currentValue = isControlledInput ? controlledValue : value

    const _onChange = event => {
      const newValue = event ? [...event.target.files] : null

      if (isControlledInput) {
        handleChange(newValue)
      } else {
        setValue(newValue)
      }
    }

    return (
      <Box
        sx={{
          display: 'grid',
          gridGap: 0.5,
          alignItems: 'start',
          justifyItems: 'start',
          alignContent: 'stretch',
          justifyContent: 'normal',
          width: fullWidth && showSeparateLabel ? '100%' : 'auto',
        }}
      >
        {showSeparateLabel && (
          <MuiInputLabel htmlFor={name} error={hasError}>
            {label}
          </MuiInputLabel>
        )}
        {/* TODO: add signature pad here */}
        {description && <FormHelperText>{description}</FormHelperText>}
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'flex-start',
            gridGap: 1,
          }}
        >
          <SignaturePreview value={currentValue} label={label} />
          {currentValue && (
            <Button
              variant="outlined"
              size={size}
              aria-label="delete"
              disabled={disabled}
              onClick={() => _onChange(null)}
            >
              <DeleteIcon fontSize="small" />
            </Button>
          )}
        </Box>
        {hasError && <InputErrors errors={errors} />}
      </Box>
    )
  },
)

SignatureInput.displayName = 'FormalooSignatureInput'

SignatureInput.defaultProps = {
  showLabel: true,
  size: 'small',
  separateLabel: false,
  fullWidth: true,
  // html file input props
}

SignatureInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  separateLabel: PropTypes.bool,
  showLabel: PropTypes.bool,
  description: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    PropTypes.elementType,
  ]),
  value: PropTypes.any,
  defaultValue: PropTypes.any,
  onChange: PropTypes.func,
  fullWidth: PropTypes.bool,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  size: PropTypes.oneOf(['small', 'normal']),
  errors: PropTypes.arrayOf(PropTypes.string),
}

export default SignatureInput
