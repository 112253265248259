import RadioGroupInput from 'stories/FormInputs/RadioGroup/RadioGroup'
import { typeUtils } from 'types'
import {
  getFormItemErrors,
  getFormItemLabel,
  getGenericFormItemDefaultRawValue as getDefaultValue,
} from './_propGetters'
import { getRequiredRule } from './_validationProps'
import ContainedLike from 'stories/icons/ContaniedLike'

const options = [
  {
    value: -1,
    label: <ContainedLike style={{ transform: 'rotate(180deg)', width: 30 }} />,
  },
  { value: 1, label: <ContainedLike style={{ width: 30 }} /> },
]

const Component = RadioGroupInput

const getProps = fieldProps => ({
  ...fieldProps,
  name: fieldProps.slug,
  label: getFormItemLabel(fieldProps),
  // input props
  separateLabel: true,
  options,
})

const getControlledProps = (fieldProps, controllerProps, defaultValue) => ({
  ...controllerProps.field,
  type: typeUtils.getMostSpecificType(fieldProps),
  label: getFormItemLabel(fieldProps),
  // input props
  separateLabel: true,
  errors: getFormItemErrors(controllerProps),
  options,
  onChange: value => controllerProps.field.onChange(Number(value)),
})

const getValidationRules = (fieldProps, options) => ({
  ...getRequiredRule(fieldProps, options),
})

export const likeDislikeInput = {
  Component,
  getProps,
  getControlledProps,
  getValidationRules,
  getDefaultValue,
}
