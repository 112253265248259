/** @jsxImportSource @emotion/react */

import Box from '@mui/material/Box'

export default function Menu({ color = '#28293D', ...props }) {
  return (
    <Box
      {...props}
      css={{
        width: 20,
        height: 14,
        position: 'relative',
        '&::after, &::before': {
          content: '""',
          display: 'block',
          position: 'absolute',
          width: '100%',
          height: 2,
          borderRadius: 5,
          background: color,
          left: 0,
          right: 0,
        },
        '&::before': {
          top: 0,
        },
        '&::after': {
          bottom: 0,
        },
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          width: '100%',
          height: 2,
          background: color,
          borderRadius: 5,
          top: 6,
        }}
      />
    </Box>
  )
}
