import Box from '@mui/material/Box'
import { BLOCK_SUBTYPES, BLOCK_TYPES } from 'utils/board/block-types'
import { useBoardView } from 'context/view'
import { useResponsiveView } from 'context/responsive-view'
import MobileMenuButton from './MenuButton'
import LazyLoad from 'utils/lazy-load'
import TextSkeleton from 'stories/loading/skeleton/text'
import { APP_CLASS_PREFIX } from 'utils/fix-classnames'
import { StaticPagesSlugs } from '../page/types'
import WaterMark from './WaterMark'

const ChartBlock = LazyLoad({ loader: () => import('components/block/chart') })
const FormBlock = LazyLoad({ loader: () => import('components/block/form') })
const FiledBlock = LazyLoad({
  loader: () => import('components/block/form/FieldBlock'),
})
const ResultBlock = LazyLoad({
  loader: () => import('components/block/result'),
})
const Kanban = LazyLoad({
  loader: () => import('components/block/kanban'),
})
const ReportBlock = LazyLoad({
  loader: () => import('components/block/report'),
})

const UsersPage = LazyLoad({
  loader: () => import('components/board/page/static/Users'),
})

const ProfilePage = LazyLoad({
  loader: () => import('components/board/page/static/Profile'),
})

export default function BoardView() {
  const { currentBlock } = useBoardView()
  const { isMobileScreen, SidebarWidth } = useResponsiveView()

  // useEffect(() => {
  //   /* update current block on view section after switching the mode */
  //   if (currentBlock && currentBlock.slug) {
  //     setBlockToViewOnBoard(currentBlock.slug)
  //   }
  // }, [isEditMode])

  const checkFormSubtype = theBlock => {
    if (theBlock.subtype === BLOCK_SUBTYPES.form.fields)
      return <FiledBlock {...theBlock} />
    return <FormBlock {...theBlock} />
  }

  const blockPageViewer = theBlock => {
    if (theBlock === null) return null
    if (theBlock === undefined)
      return (
        <Box
          px={2}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          width="100%"
          height="100%"
        >
          <TextSkeleton rows={4} />
        </Box>
      )

    switch (theBlock.type) {
      case BLOCK_TYPES.form:
        return checkFormSubtype(theBlock)
      case BLOCK_TYPES.formResult:
        return <ResultBlock {...theBlock} />
      case BLOCK_TYPES.formChart:
        return <ChartBlock {...theBlock} />
      case BLOCK_TYPES.report:
        return <ReportBlock {...theBlock} />
      case BLOCK_TYPES.kanban:
        return <Kanban {...theBlock} />
      default:
        return <p>The block type is not supported</p>
    }
  }

  const staticPageViewer = thePage => {
    if (!thePage) return null
    const staticPages = {
      [StaticPagesSlugs.USERS]: <UsersPage {...thePage} />,
      [StaticPagesSlugs.USER_PROFILE]: <ProfilePage {...thePage} />,
    }
    return staticPages[thePage.slug]
  }

  const viewThePageContent = thePage => {
    if (thePage?.isStatic) return staticPageViewer(thePage)
    return blockPageViewer(thePage)
  }

  const ViewWidth = isMobileScreen ? '100%' : `calc(100% - ${SidebarWidth}px)`

  return (
    <Box
      width={ViewWidth}
      height="100vh"
      overflow="hidden"
      backgroundColor="background.default"
      className={`${APP_CLASS_PREFIX}_view`}
    >
      {isMobileScreen && <MobileMenuButton />}
      {viewThePageContent(currentBlock)}
      <WaterMark />
    </Box>
  )
}
