import getRawMessage from 'locales/localeDirectAccess'
import { components } from 'react-select'
import ReactSelectInput from 'stories/FormInputs/Select/ReactSelect'

import { typeUtils } from 'types'
import {
  getFormItemErrors,
  getFormItemLabel,
  getGenericFormItemDefaultValue as getDefaultValue,
} from './_propGetters'
import { getRequiredRule } from './_validationProps'

const generateOptions = ({
  min_value: minValue,
  max_value: maxValue,
  required,
}) =>
  Array.from({ length: maxValue - minValue + 1 }, (_, i) => ({
    slug: minValue + i,
    title: minValue + i,
  }))

const SingleValue = ({ unitPrice, currency, children, ...props }) => {
  const style = {
    display: 'flex',
    justifyContent: 'space-between',
    gridGap: '4px',
  }

  return (
    <components.SingleValue {...props}>
      <div style={style}>
        <span>{children}</span>
        <span
          style={{
            [document.body.dir === 'rtl' ? 'marginRight' : 'marginLeft']:
              'auto',
          }}
        >
          X
        </span>
        <span>
          {unitPrice} {currency}
        </span>
      </div>
    </components.SingleValue>
  )
}

const Component = ReactSelectInput

const getProps = fieldProps => ({
  ...fieldProps,
  name: fieldProps.slug,
  label: getFormItemLabel(fieldProps),
  // input props
  separateLabel: true,
  autoFill: false,
  options: generateOptions(fieldProps),
  isClearable: !fieldProps.required,
})

const getControlledProps = (
  fieldProps,
  controllerProps,
  defaultValue,
  rowDataProps,
  formDataProps,
) => ({
  ...controllerProps.field,
  type: typeUtils.getMostSpecificType(fieldProps),
  label: getFormItemLabel(fieldProps),
  // input props
  separateLabel: true,
  autoFill: false,
  errors: getFormItemErrors(controllerProps),
  options: generateOptions(fieldProps),
  isClearable: !fieldProps.required,
  onChange: newValue => controllerProps.field.onChange(newValue),
  components: {
    SingleValue: props => (
      <SingleValue
        {...props}
        unitPrice={fieldProps.unit_price}
        currency={formDataProps.currency?.currency_code}
      />
    ),
  },
  placeholder: getRawMessage('form.field.product.quantity'),
})

const getValidationRules = (fieldProps, options) => ({
  ...getRequiredRule(fieldProps, options),
  validate: value => {
    if (!value) return true

    if (!!fieldProps.min_value && value < fieldProps.min_value) {
      return getRawMessage('form.validation.minValue', {
        minValue: fieldProps.min_value,
      })
    }

    if (!!fieldProps.max_value && value > fieldProps.max_value) {
      return getRawMessage('form.validation.maxValue', {
        maxValue: fieldProps.max_value,
      })
    }

    return true
  },
})

export const productInput = {
  Component,
  getProps,
  getControlledProps,
  getValidationRules,
  getDefaultValue,
}
