export default function ResponseIcon({ color = '#28293D', ...props }) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M7.49817 14.0012L9.99921 11.3331L12.5003 14.0012L16.5019 9.99951"
        stroke={color}
        strokeWidth="1.41"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="2.99622"
        y="2.99658"
        width="18.0075"
        height="18.0075"
        rx="5"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
