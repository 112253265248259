import { createContext, useContext, useEffect, useState, useMemo } from 'react'
import { useAuth } from 'hooks/useAuth'
// import { setErrors } from 'utils/error'
import { useBoard } from 'context/board'
import { useBoardFn } from 'hooks/board'

const AuthContext = createContext()

const AuthContextProvider = ({ children }) => {
  const { isAuthEnabled, boardInfo } = useBoard()

  const { isLoading, isAuthCallbackRoute, logout, getUserProfile } = useAuth(
    boardInfo?.share_address,
  )
  const { isPrivate } = useBoardFn()

  const [user, setUser] = useState(null)

  const isAuthenticated = useMemo(() => user !== null, [user])
  const userProfileForm = useMemo(() => user?.form, [user])

  useEffect(() => {
    if (!isAuthCallbackRoute && isAuthEnabled && !isPrivate) {
      getUserProfile()
        .then(TheUser => setUser(TheUser))
        .catch(e => {
          console.info(e)
        })
    }
  }, [isAuthCallbackRoute, isAuthEnabled, isPrivate])

  return (
    <AuthContext.Provider
      value={{
        isAuthCallbackRoute,
        isAuthenticated,
        isLoading,
        user,
        setUser,
        userProfileForm,
        getUserProfile,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

function useAuthContext() {
  const context = useContext(AuthContext)
  if (context === undefined) {
    throw new Error(`useAuthContext must be used within a AuthContextProvider`)
  }
  return context
}

export { AuthContextProvider, useAuthContext }
