import React from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import { FormattedMessage } from 'react-intl'
import { useForm, Controller } from 'react-hook-form'
import AsyncCreatableSelect from 'stories/FormInputs/AsyncCreatableSelect'
import { getFormItemErrors } from 'stories/FormInputs/FormItem/props/_propGetters'
import { getRandomTagColor } from './_colors'
import { fetchResponseTagOptions } from './_utils'
import { useResponseFn } from 'hooks/response'
import { tags as tagApi } from 'api/response-tags'
import { setErrors } from 'utils/error'
import { notify } from 'utils/notification'

const FormResponseTagsForm = ({
  tags,
  formSlug,
  responseSlug,
  onSuccessfulSubmit,
  onFailedSubmit,
  ...props
}) => {
  const defaultValues = { row_tags: tags }
  const { updateResponse } = useResponseFn()

  const {
    handleSubmit,
    reset,
    watch,
    formState: { isValidating, isValid, isDirty },
    control,
    setError,
  } = useForm({
    defaultValues,
    mode: 'onChange',
    criteriaMode: 'all',
  })

  const onSubmit = async formValues => {
    updateResponse(responseSlug, formValues)
      .then(response => {
        reset()

        notify(
          <FormattedMessage id="form.response.tags.update.success" />,
          'success',
        )
        typeof onSuccessfulSubmit === 'function' && onSuccessfulSubmit(response)
      })
      .catch(errors => {
        setErrors(errors, setError)

        notify(
          <FormattedMessage id="form.response.tags.update.error" />,
          'error',
        )

        typeof onFailedSubmit === 'function' && onFailedSubmit(errors)
      })
  }

  const formValues = watch('row_tags')

  React.useEffect(() => {
    if (isDirty && isValid && !isValidating) {
      handleSubmit(onSubmit)()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues, isValid, isValidating, isDirty])

  return (
    <>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={theme => ({
          display: 'flex',
          flexDirection: 'column',
          marginTop: theme.spacing(2),
          marginBottom: theme.spacing(4),
        })}
      >
        <Controller
          name="row_tags"
          control={control}
          render={({ field: fieldProps, ...controllerProps }) => (
            <AsyncCreatableSelect
              label={<FormattedMessage id="form.response.tags.title" />}
              {...fieldProps}
              {...controllerProps}
              fetchOptions={fetchResponseTagOptions(formSlug)}
              onCreateOption={async (title, { selectMethods }) => {
                selectMethods.setIsLoading(true)
                try {
                  const {
                    data: { data },
                  } = await tagApi().create(formSlug, {
                    title,
                    color: getRandomTagColor(),
                  })
                  notify(
                    <FormattedMessage id="form.response.tags.create.success" />,
                    'success',
                  )
                  selectMethods.addToOptions(data.row_tag)
                  selectMethods.setIsLoading(false)
                  return Promise.resolve(data.row_tag)
                } catch (error) {
                  notify(
                    <FormattedMessage id="invitation.massage.error" />,
                    'error',
                  )
                  selectMethods.setIsLoading(false)
                  return Promise.reject(error)
                }
              }}
              errors={getFormItemErrors(controllerProps)}
              isMulti
              formatOptionLabel={(option, context) => {
                return (
                  <div
                    style={{
                      display: 'flex',
                      gridGap: '8px',
                      alignItems: 'center',
                      fontWeight: '500',
                    }}
                  >
                    {option.color ? (
                      <span
                        style={{
                          borderRadius: '50%',
                          width: '16px',
                          height: '16px',
                          backgroundColor: option.color,
                        }}
                      />
                    ) : null}
                    {option.title}
                  </div>
                )
              }}
              defaultOptions={true}
              noOptionsMessage={
                <FormattedMessage id="form.response.tags.empty" />
              }
            />
          )}
        />
      </Box>
    </>
  )
}

FormResponseTagsForm.defaultProps = {
  tags: [],
}

FormResponseTagsForm.propTypes = {
  formSlug: PropTypes.string,
  responseSlug: PropTypes.string,
  tags: PropTypes.array,
  onSuccessfulSubmit: PropTypes.func,
  onFailedSubmit: PropTypes.func,
}

export default FormResponseTagsForm
