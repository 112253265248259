import { createTheme, useTheme } from '@mui/material/styles'
// import omit from 'lodash/omit'

export const themeGenerator = themeConfig => {
  const defaultTheme = useTheme()

  const {
    background: { main, sidebar },
    text: { primary, secondary },
    error,
    success,
    common,
    mode,
  } = themeConfig

  const palette = {
    background: { default: main, paper: sidebar },
    primary,
    secondary,
    error,
    success,
    common,
    mode,
    text: { primary: primary.dark },
  }

  return createTheme({
    ...defaultTheme,
    palette,
  })
}
