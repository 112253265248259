export default function UserIcon({ color = '#28293D', ...props }) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.8562 3.39385C11.8813 4.41898 11.8813 6.08103 10.8562 7.10616C9.83103 8.13129 8.16898 8.13129 7.14385 7.10616C6.11872 6.08103 6.11872 4.41898 7.14385 3.39385C8.16898 2.36872 9.83103 2.36872 10.8562 3.39385"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 13.8751V14.6251C3 15.0391 3.336 15.3751 3.75 15.3751H14.25C14.664 15.3751 15 15.0391 15 14.6251V13.8751C15 11.6056 12.036 10.1311 9 10.1311C5.964 10.1311 3 11.6056 3 13.8751Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
