import Button from 'stories/button/Button'
import LinearProgress from '@mui/material/LinearProgress'
import { FormattedMessage } from 'react-intl'
import { isValidHTML } from 'utils/formatter/html'
import DownloadIcon from 'stories/icons/Download'

const FilesList = ({ value, uploadedFiles }) => {
  if (uploadedFiles?.length > 0) {
    return uploadedFiles.map((f, i) => (
      <Button
        key={`${i}-${f.data?.name}`}
        target="_blank"
        color="secondary"
        href={f.uploadInfo?.url}
        rel="noreferrer noopener nofollow"
        variant="contained"
        startIcon={f.uploadInfo?.slug && <DownloadIcon color="#fff" />}
        fullWidth
        disabled={!f.uploadInfo?.slug}
        sx={{
          backgroundColor: '#6846D9',
          '&:hover': {
            backgroundColor: '#6846D9',
          },
        }}
      >
        <div key={i} style={{ direction: 'ltr' }}>
          {!f.uploadInfo?.slug ? (
            <>
              {f.data?.name} - {f.data?.size} - {f.progress}%
              {<LinearProgress variant="determinate" value={f.progress} />}
            </>
          ) : (
            <>
              {f.uploadInfo?.meta_data?.name} -
              <FormattedMessage id="general.downloadFile" />
            </>
          )}
        </div>
      </Button>
    ))
  }

  if (!value) return null

  // handle default value (url)
  if (isValidHTML(value)) {
    const fileSrc =
      value.split('href="')[1] && value.split('href="')[1].split('"')[0]

    return (
      <Button
        target="_blank"
        color="secondary"
        href={fileSrc}
        rel="noreferrer noopener nofollow"
        variant="contained"
        startIcon={<DownloadIcon color="#fff" />}
        fullWidth
        sx={{
          backgroundColor: '#6846D9',
          '&:hover': {
            backgroundColor: '#6846D9',
          },
        }}
      >
        <FormattedMessage id="general.downloadFile" />
      </Button>
    )
  }

  if (typeof value === 'string') {
    return (
      <div style={{ maxWidth: '100%' }}>
        <div>{value}</div>
      </div>
    )
  }

  if (value instanceof File) {
    return (
      <>
        <div>
          {value.name} - {value.size}
        </div>
      </>
    )
  }

  return (
    <div
      data-error="i don't know how to preview"
      data-value={JSON.stringify(value)}
    />
  )
}

export default FilesList
