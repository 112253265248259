import Menu from 'stories/icons/Menu'
import { useResponsiveView } from 'context/responsive-view'
import Box from '@mui/material/Box'
import { useTheme } from '@mui/material/styles'

const MobileMenuButton = () => {
  const { setLeftSidebar } = useResponsiveView()
  const { palette } = useTheme()
  const openLeftSidebar = () => {
    setLeftSidebar(true)
  }
  return (
    <Box
      sx={{
        width: 33,
        height: 33,
        position: 'sticky',
        top: 15,
        right: 25,
        left: 'calc(100% - 50px)',
        zIndex: 10,
        display: 'flex',
        alignItems: 'center',
        p: 1,
        borderRadius: 1.5,
        backgroundColor: 'rgba(216,224,237,0.4)',
      }}
      onClick={openLeftSidebar}
    >
      <Menu color={palette.primary.dark} />
    </Box>
  )
}

export default MobileMenuButton
