import Checkbox from '@mui/material/Checkbox'
import Box from '@mui/material/Box'
import LightIcon from '../../icons/Light'
import { useTheme } from '@mui/material/styles'

export default function ThemeSwitch({ inputRef, ...props }) {
  const { palette } = useTheme()
  const iconProps = {
    width: 20,
    height: 20,
    color: palette.primary.dark,
  }
  return (
    <Box
      sx={{
        width: 32,
        height: 32,
        borderRadius: '50%',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'background.paper',
      }}
    >
      <Checkbox
        {...props}
        size="small"
        icon={<LightIcon {...iconProps} />}
        checkedIcon={<LightIcon {...iconProps} />}
      />
    </Box>
  )
}
