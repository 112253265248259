import React, { useState } from 'react'
import PropTypes from 'prop-types'

import TextInput from '../Text/Text'

const formatUrl = (url = '') =>
  url.length === 0 || /^http(s?):\/\//i.test(url) ? url : `http://${url}`

const UrlInput = React.forwardRef(
  (
    { value: controlledValue, onChange: handleChange, defaultValue, ...props },
    forwardedRef
  ) => {
    const isControlledInput = React.useMemo(
      () => typeof handleChange === 'function',
      [handleChange]
    )
    const [value, setValue] = useState(defaultValue)

    const _onChange = (event) => {
      const _url = formatUrl(event.target.value)

      if (isControlledInput) {
        handleChange(_url)
      } else {
        setValue(_url)
      }
    }

    const currentValue = isControlledInput ? controlledValue : value

    return (
      <TextInput
        dir="ltr"
        ref={forwardedRef}
        onChange={_onChange}
        value={currentValue}
        {...props}
      />
    )
  }
)

UrlInput.displayName = 'FormalooEmailInput'

UrlInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  separateLabel: PropTypes.bool,
  showLabel: PropTypes.bool,
  description: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    PropTypes.elementType,
  ]),
  value: PropTypes.string,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
  fullWidth: PropTypes.bool,
  autoFill: PropTypes.bool,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  multiline: PropTypes.bool,
  placeholder: PropTypes.string,
  size: PropTypes.oneOf(['small', 'normal']),
  errors: PropTypes.arrayOf(PropTypes.string),
}

export default UrlInput
