import { config, getCurrentWorkspace } from './utils'
import { HTTP, httpClient } from './utils/http'
import API from 'app-settings/api.settings'
import { createJsonData, createFormData } from 'utils/form/createData'

const workspace = getCurrentWorkspace()

export const board = () => ({
  getPrimary: (formSlug, params) =>
    httpClient.get(
      `${API.baseUrl}/v4/forms/${formSlug}/boards/primary/`,
      config({ params, workspace }),
    ),

  updatePrimary: (formSlug, formValues) =>
    httpClient.patch(
      `${API.baseUrl}/v4/forms/${formSlug}/boards/primary/`,
      createJsonData(formValues),
      config({ contentType: HTTP.ContentTypes.JSON, workspace }),
    ),

  getOne: (formSlug, boardSlug, params) =>
    httpClient.get(
      `${API.baseUrl}/v4/forms/${formSlug}/boards/${boardSlug}/`,
      config({ params, workspace }),
    ),

  updateOne: (formSlug, boardSlug, formValues, dataOptions = {}) =>
    httpClient.patch(
      `${API.baseUrl}/v4/forms/${formSlug}/boards/${boardSlug}/`,
      createJsonData(formValues, dataOptions),
      config({ contentType: HTTP.ContentTypes.JSON, workspace }),
    ),

  delete: (formSlug, boardSlug) =>
    httpClient.delete(
      `${API.baseUrl}/v4/forms/${formSlug}/boards/${boardSlug}/`,
      config({ workspace }),
    ),

  // 1. get public board with sharedSlug
  // 2. get public board on custom domain if there is no sharedSlug
  getShared: (sharedSlug, params) =>
    httpClient.get(
      `${API.baseUrl}/v4/shared-boards/${sharedSlug ? sharedSlug + '/' : ''}`,
      config({ params, workspace }),
    ),

  getAuthUrl: ({ appId: originator, action, redirectUri: redirect_uri }) => {
    return httpClient.post(
      `${API.baseUrl}/v2.0/end-users/request-redirect/`,
      createFormData({
        originator: originator?.toLowerCase(),
        action,
        redirect_uri: redirect_uri?.toLowerCase(),
      }),
      config({
        contentType: HTTP.ContentTypes.FormUrlEncoded,
        workspace,
      }),
    )
  },

  login: ({ code, state, redirectUrl: redirect_uri }) =>
    httpClient.post(
      `${API.baseUrl}/v2.0/end-users/confirm-code/`,
      createFormData({
        code,
        state,
        redirect_uri: redirect_uri?.toLowerCase(),
      }),
      config({ contentType: HTTP.ContentTypes.FormUrlEncoded, workspace }),
    ),

  logout: appUrl =>
    httpClient.post(
      `${API.baseUrl}/v2.0/end-users/logout/`,
      createFormData({
        originator: appUrl?.toLowerCase(),
      }),
      config({ contentType: HTTP.ContentTypes.FormUrlEncoded, workspace }),
    ),

  getBoardUserProfile: sharedSlug =>
    httpClient.get(
      `${API.baseUrl}/v2.0/shared-boards/${sharedSlug}/profile/`,
      config({ workspace }),
    ),
  updateBoardUserProfile: (sharedSlug, formValues) =>
    httpClient.patch(
      `${API.baseUrl}/v2.0/shared-boards/${sharedSlug}/profile/`,
      createJsonData(formValues),
      config({
        contentType: HTTP.ContentTypes.FormUrlEncoded,
        workspace,
      }),
    ),
})
