import Box from '@mui/material/Box'
import UserIcon from 'stories/icons/User'
import { FormattedMessage } from 'react-intl'
import { useTheme } from '@mui/material/styles'
import { useBoardView } from 'context/view'
import { Users } from 'components/board/page/types'

export default function UsersPageButton() {
  const { palette } = useTheme()
  const { setStaticPageToViewOnBoard, currentBlock } = useBoardView()

  const viewUsersPage = () => {
    setStaticPageToViewOnBoard(Users)
  }

  return (
    <Box
      onClick={viewUsersPage}
      sx={{
        display: 'flex',
        alignItems: 'center',
        height: 40,
        px: 1,
        py: 1.2,
        mt: 0.3,
        minWidth: 'auto',
        borderRadius: 2,
        ml: 'auto',
        color: 'text.primary',
        fontWeight: 600,
        textTransform: 'capitalize',
        cursor: 'pointer',
        '& > *:first-of-type': { mr: 1 },
        backgroundColor:
          currentBlock?.slug === Users.slug ? 'primary.active' : 'inherit',
      }}
    >
      <UserIcon
        color={palette.primary.dark}
        style={{ width: 20, height: 20 }}
      />
      <FormattedMessage id="app.users.button" />
    </Box>
  )
}
