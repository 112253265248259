import { FIELD_TYPE, FIELD_SUB_TYPE } from '../fields'

export const isAdminOnly = field => field?.admin_only

export const isHidden = field => field?.type === FIELD_TYPE.HIDDEN

export const isVisible = field => !isHidden(field)

export const isNonRenderable = field =>
  field.type === FIELD_TYPE.META ||
  field.sub_type === FIELD_SUB_TYPE.META.PAGE_BREAK ||
  field.type === FIELD_TYPE.OEMBED ||
  field.is_editable === false

export const isMetaField = field => field.type === FIELD_TYPE.META

/**
 * Number Based Fields: number, money, rating
 * Text-Based Fields: short_text, long_text, email, phone_number, website, Barcode
 * Yes/No Fields: yes_no
 * Boolean Fields: boolean ????
 * Date and Time Fields: date, time
 */
const sortableFields = [
  // number
  FIELD_TYPE.NUMBER,
  FIELD_TYPE.MONEY,
  FIELD_TYPE.RATING,
  // text
  FIELD_TYPE.SHORT_TEXT,
  FIELD_TYPE.LONG_TEXT,
  FIELD_TYPE.EMAIL,
  FIELD_TYPE.PHONE,
  FIELD_TYPE.WEBSITE,
  FIELD_TYPE.BARCODE,
  // yes/no
  FIELD_TYPE.YES_NO,
  // date / time
  FIELD_TYPE.DATE,
  FIELD_TYPE.TIME,
  // boolean: verification
  FIELD_TYPE.PHONE_VERIFICATION,
  // hidden (text)
  FIELD_TYPE.HIDDEN,
]

export const isSortableFieldType = fieldType =>
  sortableFields.includes(fieldType)

const leftToRightFields = [
  // text
  FIELD_TYPE.EMAIL,
  FIELD_TYPE.PHONE,
  FIELD_TYPE.WEBSITE,
  FIELD_TYPE.BARCODE,
  // number
  FIELD_TYPE.NUMBER,
  FIELD_TYPE.MONEY,
  FIELD_TYPE.RATING,
  // date / time
  FIELD_TYPE.DATE,
  FIELD_TYPE.TIME,
]

export const isLeftToRightField = fieldType =>
  leftToRightFields.includes(fieldType)

export const isVerificationField = field =>
  [FIELD_TYPE.PHONE_VERIFICATION, FIELD_TYPE.EMAIL_VERIFICATION].includes(
    field?.type,
  )

export const isResponseVerificationState = field =>
  ['phone_verification_state', 'email_verification_state'].includes(field?.slug)

export const getType = ({ type, sub_type, json_key }) =>
  [type, sub_type, json_key].filter(Boolean).join('.')

export const getMostSpecificType = ({ type, sub_type, json_key }) => {
  if (type === FIELD_TYPE.VARIABLE) return type
  return json_key ?? sub_type ?? type
}
