const urlSettings = {
  formBuilderDomain:
    process.env.REACT_APP_FORM_BUILDER_DOMAIN ||
    'https://staging.dash.formaloo.com/',
  baseUrl: process.env.REACT_APP_DOMAIN || 'https://staging.app.formaloo.com',
  formViewDomain:
    process.env.REACT_APP_FORM_VIEW_DOMAIN || 'https://staging.formaloo.com',
  formViewMainDomain:
    process.env.REACT_APP_FORM_VIEW_MAIN_DOMAIN ||
    'https://staging.formaloo.com',
  websiteDomain: 'https://www.formaloo.com/en/',
}

export default urlSettings
