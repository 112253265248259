import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'

export default function Modal({
  open = false,
  onClose,
  fullWidth = true,
  maxWidth = 'sm',
  disableCloseButton,
  children,
  minHeight = 'auto',
  title,
}) {
  return (
    <Dialog
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      open={open}
      onClose={onClose}
      aria-labelledby="max-width-dialog-title"
    >
      <DialogTitle
        id="max-width-dialog-title"
        component="div"
        sx={{
          p: 1,
          pb: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        {title && (
          <Typography
            color="textPrimary"
            component="h6"
            sx={{ fontWeight: 600 }}
            variant="subtitle2"
          >
            {title}
          </Typography>
        )}
        {!disableCloseButton && (
          <Button
            onClick={onClose}
            variant="text"
            sx={{
              width: 40,
              minWidth: 40,
              borderRadius: '50%',
              height: 40,
              padding: 0,
              color: 'secondary.main',
            }}
          >
            X
          </Button>
        )}
      </DialogTitle>

      <DialogContent sx={{ py: 0, px: 1, minHeight }}>
        <Paper
          elevation={0}
          sx={{
            width: '100%',
            backgroundColor: 'background.default',
            height: '100%',
            px: 2,
            py: 1,
            borderRadius: 2,
            mb: 2,
          }}
        >
          {children}
        </Paper>
      </DialogContent>
    </Dialog>
  )
}
