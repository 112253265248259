export const _COLORS = [
  { textColor: '#4F5968', bgColor: '#EBEFF5' },
  { textColor: '#4576D9', bgColor: '#EEF3FE' },
  { textColor: '#328755', bgColor: '#F0F9F4' },
  { textColor: '#36B2AE', bgColor: '#F1FBFA' },
  { textColor: '#6846D9', bgColor: '#F1EDFF' },
  { textColor: '#B2365F', bgColor: '#FBF1F4' },
  { textColor: '#E54113', bgColor: '#FFF1ED' },
  { textColor: '#E56A00', bgColor: '#FFF5ED' },
  { textColor: '#B2970F', bgColor: '#FFFCED' },
  { textColor: '#71A30E', bgColor: '#F7FCEB' },
]

export const TAG_COLORS = [
  '#FCB400',
  '#FF6F2C',
  '#F82B60',
  '#2D7FF9',
  '#8B46FF',
  '#FF08C2',
  '#20D9D2',
  '#18BFFF',
  '#20C933',
  '#FFD66E',
  '#FFA981',
  '#FF9EB7',
  '#9CC7FF',
  '#CDB0FF',
  '#F99DE2',
  '#72DDC3',
  '#77D1F3',
  '#93E088',
  '#E08D00',
  '#F7653B',
  '#EF3061',
  '#E929BA',
  '#7C39ED',
  '#1283DA',
  '#01A9DB',
  '#02AAA4',
  '#11AF22',
]

export const getRandomTagColor = () =>
  TAG_COLORS[Math.floor(Math.random() * TAG_COLORS.length)]
