import React from 'react'
import PropTypes from 'prop-types'
import MuiInputLabel from '@mui/material/InputLabel'
import FormLabel from '@mui/material/FormLabel'
import FormControl from '@mui/material/FormControl'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormHelperText from '@mui/material/FormHelperText'
import Checkbox from './Checkbox'
import { InputErrors } from '../_InputErrors'
import Box from '@mui/material/Box'

const CheckboxGroup = React.forwardRef(
  (
    {
      name,
      label,
      description,
      readOnly,
      errors,
      showLabel,
      separateLabel,
      inputProps: muiInputProps,
      type,
      options,
      defaultValue,
      value: controlledValue,
      onChange: handleChange,
      row,
      size,
      fullWidth,
      ...props
    },
    ref,
  ) => {
    const hasError = errors?.length > 0

    const showDefaultMuiLabel = showLabel && !separateLabel
    const showSeparateLabel = showLabel && separateLabel

    const isControlledInput = React.useMemo(
      () => typeof handleChange === 'function',
      [handleChange],
    )

    const [value, setValue] = React.useState(defaultValue)

    const currentValue = (isControlledInput ? controlledValue : value) || []

    const _onChange = event => {
      const newValue = event.target.checked
        ? [...currentValue, event.target.name]
        : currentValue.filter(optionName => optionName !== event.target.name)

      if (isControlledInput) {
        handleChange(newValue)
      } else {
        setValue(newValue)
      }
    }

    const isOptionChecked = optionName => currentValue.includes(optionName)

    return (
      <Box
        sx={{
          display: 'grid',
          gridGap: 0.5,
          alignItems: 'start',
          justifyItems: 'start',
          alignContent: 'stretch',
          justifyContent: 'normal',
          width: props.fullWidth && showSeparateLabel ? '100%' : 'auto',
        }}
      >
        {showSeparateLabel && (
          <MuiInputLabel
            sx={{
              fontSize: '1.2rem',
              color: 'primary.lighten',
              mb: 1.5,
            }}
            htmlFor={name}
            error={hasError}
          >
            {label}
          </MuiInputLabel>
        )}
        <FormControl
          component="fieldset"
          error={hasError}
          disabled={props.disabled}
        >
          {showDefaultMuiLabel && (
            <FormLabel component="legend">{label}</FormLabel>
          )}
          <FormGroup row={row}>
            {options.map(({ name: optionName, label: optionLabel }) => (
              <FormControlLabel
                sx={{ ml: -1.5, mr: 2 }}
                key={optionName}
                control={
                  <Checkbox
                    size={size}
                    name={optionName}
                    checked={isOptionChecked(optionName)}
                    onChange={_onChange}
                  />
                }
                label={optionLabel}
              />
            ))}
          </FormGroup>
          {description && <FormHelperText>{description}</FormHelperText>}
        </FormControl>
        {hasError && <InputErrors errors={errors} />}
      </Box>
    )
  },
)

CheckboxGroup.displayName = 'FormalooCheckboxGroup'

CheckboxGroup.defaultProps = {
  showLabel: true,
  size: 'small',
  separateLabel: false,
  fullWidth: true,
  row: true,
  defaultValue: [],
  value: [],
}

CheckboxGroup.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  separateLabel: PropTypes.bool,
  showLabel: PropTypes.bool,
  description: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    PropTypes.elementType,
  ]),
  value: PropTypes.array,
  defaultValue: PropTypes.array,
  onChange: PropTypes.func,
  fullWidth: PropTypes.bool,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'normal']),
  errors: PropTypes.arrayOf(PropTypes.string),
  row: PropTypes.bool,
  /**
   * { label, name }
   */
  options: PropTypes.array.isRequired,
}

export default CheckboxGroup
