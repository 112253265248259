import { useState } from 'react'
import { responses } from 'api/response'

export const useResponseFn = () => {
  const [isLoading, setLoading] = useState(false)

  const updateResponse = async (resSlug, formData, params = {}) => {
    setLoading(true)
    try {
      const {
        data: { data },
      } = await responses().update(resSlug, formData)
      setLoading(false)
      return Promise.resolve(data.row)
    } catch (error) {
      setLoading(false)

      return Promise.reject(error)
    }
  }

  const updateDraft = async (submitSlug, formData) => {
    setLoading(true)
    try {
      const {
        data: { data },
      } = await responses().updateDraft(submitSlug, formData)
      setLoading(false)
      return Promise.resolve(data.row)
    } catch (error) {
      setLoading(false)

      return Promise.reject(error)
    }
  }
  const createResponse = async (formData, formSlug) => {
    setLoading(true)
    try {
      const {
        data: { data },
      } = await responses().create(formData, formSlug)
      setLoading(false)
      return Promise.resolve(data.row)
    } catch (error) {
      setLoading(false)

      return Promise.reject(error)
    }
  }

  return { updateResponse, createResponse, updateDraft, isLoading }
}
