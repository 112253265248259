import assetSettings from 'app-settings/assets.settings'

const { boardWrapperId } = assetSettings

export const getBoardMetaData = () => {
  let formSlug = null
  let boardSlug = null
  let boardPublicSlug = null
  let isPrivate = false
  let userAccess = null
  let isAppOnCustomDomain = false

  const embeddedBoardWrapper = document.querySelector(`#${boardWrapperId}`)

  if (embeddedBoardWrapper) {
    boardSlug = embeddedBoardWrapper.getAttribute(`data-board-slug`)
    formSlug = embeddedBoardWrapper.getAttribute(`data-board-form`)
    userAccess = embeddedBoardWrapper.getAttribute(`data-board-access`)
    isPrivate = Boolean(boardSlug)
  }
  boardPublicSlug = window.location.pathname.split('/')[1]
  isAppOnCustomDomain = Boolean(!isPrivate && !boardPublicSlug)

  return {
    formSlug,
    boardSlug,
    boardPublicSlug,
    isPrivate,
    userAccess,
    isAppOnCustomDomain,
  }
}
