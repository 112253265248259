import { omit } from 'utils/formatter/data-structure'

export const createJsonData = (formObject = {}, options = {}) => {
  const blackListValues = [null, undefined, '']
  const alwaysSend = options.alwaysSend || []
  const fieldKeys = Object.keys(formObject) || []
  const emptyFields = fieldKeys.filter(
    field =>
      !alwaysSend.includes(field) &&
      blackListValues.includes(formObject[field]),
  )
  const allowedFields = omit(emptyFields)(formObject)
  return JSON.stringify(allowedFields)
}

export const createFormData = (formObject, options = {}) => {
  let formData = new FormData()
  appendToFormData(formObject, formData, options)
  return formData
}

function appendToFormData(formObject, formData, options) {
  const filterList = [null, undefined, '']
  const alwaysSend = options.alwaysSend || []

  for (const field in formObject) {
    const fieldValue = formObject[field]
    if (alwaysSend.includes(field) || !filterList.includes(fieldValue)) {
      if (typeof fieldValue === 'object') {
        //it's alwaysSend object that sent null to empty or clear the current value of field like logo
        if (fieldValue === null) {
          formData.append(field, fieldValue)
        } else {
          if (fieldValue instanceof FileList) {
            // it's a file
            if (fieldValue.length === 1) {
              formData.append(field, fieldValue[0])
            } else {
              for (let i = 0; i < fieldValue.length; i++) {
                formData.append(`${field}[]`, fieldValue[i])
              }
            }
          } else if (
            typeof fieldValue.name === 'string' &&
            typeof fieldValue.size === 'number'
          ) {
            // it's a file
            formData.append(field, fieldValue)
          } else {
            if (Array.isArray(fieldValue) && options.sendFormData) {
              formData.append(field, fieldValue)
            } else {
              // it's an object
              formData.append(field, JSON.stringify(fieldValue))
            }
          }
        }
      } else {
        // it's not an object
        formData.append(field, fieldValue)
      }
    }
  }
}
