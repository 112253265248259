export const DARK_THEME = {
  background: { main: '#1C1C1F', sidebar: '#000000' },
  text: {
    primary: {
      main: '#a9a9a9', //description and view placeholder
      dark: '#FFFFFF', // block text color
      darken: '#FFFFFF',
      light: '#59585E', // active block bg
      lighten: '#59585E', // active block bg
      active: '#292929',
    },
    secondary: {
      main: '#497CF6',
      light: '#e5edff',
    },
  },
  error: { main: '#E65847' },
  success: { main: '#99D66C' },
  common: {},
  mode: 'dark',
}

export const LIGHT_THEME = {
  background: { main: '#FFFFFF', sidebar: '#F5F7FA' },
  text: {
    primary: {
      main: '#4F5968', //description and view placeholder
      dark: '#000000', // block text color
      darken: '#333A45',
      light: '#D8E0ED',
      lighten: '#333A45',
      active: '#EBEFF5',
    },
    secondary: {
      main: '#497CF6',
    },
  },
  error: { main: '#E65847' },
  success: { main: '#99D66C' },
  common: {},
  mode: 'light',
}

export const THEMES_MODE = {
  light: LIGHT_THEME,
  dark: DARK_THEME,
}
