export default function FolderIcon({ color = '#28293D', ...props }) {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.9991 8.25H3.0005C2.54552 8.25 2.11513 8.45651 1.83044 8.81141C1.54575 9.16631 1.43752 9.63125 1.53622 10.0754L2.36955 13.8254C2.52207 14.5117 3.13078 15 3.83383 15H14.0682C14.7574 15 15.358 14.5303 15.524 13.8614L16.455 10.1114C16.5662 9.66353 16.465 9.1894 16.1807 8.8259C15.8964 8.4624 15.4606 8.24999 14.9991 8.25Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.75 8.45353V6.705C15.75 5.87657 15.0784 5.205 14.25 5.205H9.39686C9.1486 5.205 8.91642 5.08215 8.77677 4.8769L7.72302 3.3281C7.58336 3.12284 7.35117 2.99999 7.10291 3H3.75C2.92157 3 2.25 3.67157 2.25 4.5V8.45353"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
