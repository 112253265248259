import React from 'react'
import { IntlProvider as ReactIntlProvider } from 'react-intl'
import * as messages from 'locales'
import { defaultLocale } from 'app-settings'

const getLocaleMessages = locale => messages[locale]

const IntlContext = React.createContext()

class IntlProviderClass extends React.Component {
  constructor(...args) {
    super(...args)

    this.switchLocale = locale =>
      this.setState({ locale, messages: getLocaleMessages(locale) })

    // pass everything in state to avoid creating object inside render method (like explained in the documentation)
    this.state = {
      locale: defaultLocale,
      messages: getLocaleMessages(defaultLocale),
      switchLocale: this.switchLocale,
    }
  }

  render() {
    const { children } = this.props
    const { locale, messages } = this.state

    return (
      <IntlContext.Provider value={this.state}>
        <ReactIntlProvider
          key={locale}
          locale={locale}
          messages={messages}
          defaultLocale={defaultLocale}
        >
          {children}
        </ReactIntlProvider>
      </IntlContext.Provider>
    )
  }
}

function useIntl() {
  const context = React.useContext(IntlContext)
  if (context === undefined) {
    throw new Error(`useIntl must be used within a IntlProvider`)
  }
  return context
}

export { IntlProviderClass as IntlProvider, IntlContext, useIntl }
