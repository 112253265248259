import { formatNumber } from 'utils/formatter/digit'

export const getFormItemErrors = controlledFieldProps => {
  if (!controlledFieldProps.fieldState.error) {
    return []
  }

  return controlledFieldProps.fieldState.error.types
    ? Object.values(controlledFieldProps.fieldState.error.types)
    : [controlledFieldProps.fieldState.error.message]
}

export const getFormItemLabel = fieldProps =>
  `${fieldProps.position ? formatNumber(fieldProps.position) + '.' : ''} ${
    fieldProps.title
  }${fieldProps.required ? ' *' : ''}`

export const getGenericFormItemDefaultValue = (fieldProps, defaultValue) =>
  fieldProps.value ??
  (typeof defaultValue !== 'undefined' ? defaultValue : null)

export const getGenericFormItemDefaultRawValue = (fieldProps, defaultValue) =>
  fieldProps.raw_value ??
  (typeof defaultValue !== 'undefined' ? defaultValue : null)
