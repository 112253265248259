import { ErrorMessage } from '@hookform/error-message'
import Typography from '@mui/material/Typography'
import UIContainer from './UiContainer'
import Box from '@mui/material/Box'

const FieldContainer = ({ name, errors, children }) => {
  return (
    <Box sx={{ my: 1, width: '100%' }}>
      <UIContainer>{children}</UIContainer>
      <Typography
        variant="caption"
        color="error"
        component="div"
        sx={{ py: 0.5, px: 1 }}
      >
        <ErrorMessage errors={errors} name={name} />
      </Typography>
    </Box>
  )
}

export default FieldContainer
