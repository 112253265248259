export default function LightIcon({ color = '#28293D', ...props }) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M5.99756 15.0016V18.0028C5.99756 19.6604 7.34126 21.0041 8.99881 21.0041H18.0026C19.6601 21.0041 21.0038 19.6604 21.0038 18.0028V5.99783C21.0038 4.34029 19.6601 2.99658 18.0026 2.99658H8.99881C7.34126 2.99658 5.99756 4.34029 5.99756 5.99783V8.99908"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 15.0015L15.0013 12.0003L12 8.99902"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.99658 12.0002H15.0016"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
