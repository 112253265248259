import React from 'react'
import Box from '@mui/material/Box'
import Slide from '@mui/material/Slide'
import { useTheme } from '@mui/material/styles'

import BlocksList from './BlocksList'
import CloseButton from './CloseButton'
import { useBoard } from 'context/board'
import BoardThemeEditor from '../theme-editor'
import MobileClickAway from './MobileClickAway'
import { useResponsiveView } from 'context/responsive-view'
import { shouldFlip } from 'stories/helpers/theme'
import { APP_CLASS_PREFIX } from 'utils/fix-classnames'

const WrapperComponent = ({ children }) => {
  const { isMobileScreen, isLeftSidebarOpen } = useResponsiveView()
  const theme = useTheme()
  if (isMobileScreen)
    return (
      <Slide
        in={isLeftSidebarOpen}
        direction={shouldFlip(theme) ? 'left' : 'right'}
      >
        {children}
      </Slide>
    )
  return <React.Fragment>{children}</React.Fragment>
}

export default function LeftSidebar() {
  const {
    boardBlocks: { left },
  } = useBoard()
  const { isMobileScreen, isLeftSidebarOpen, SidebarWidth, setLeftSidebar } =
    useResponsiveView()

  if (!left) return null

  const mobileStyle = isMobileScreen
    ? {
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        zIndex: 99,
        boxShadow: 3,
        px: 1,
        pt: 1,
        pb: 2,
      }
    : { px: 1.7, py: 3, position: 'relative', zIndex: 10 }

  return (
    <WrapperComponent>
      <Box
        className={`${APP_CLASS_PREFIX}_sidebar`}
        width={SidebarWidth}
        sx={{
          height: '100vh',
          overflow: 'auto',
          backgroundColor: 'background.default',
          borderRight: '1px solid #D8E0ED',
          ...mobileStyle,
        }}
      >
        <CloseButton />
        <BoardThemeEditor />
        <Box pt={2}>
          <BlocksList />
        </Box>
        {isLeftSidebarOpen && (
          <MobileClickAway onClick={() => setLeftSidebar(false)} />
        )}
      </Box>
    </WrapperComponent>
  )
}
