import React, { useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { useForm, Controller } from 'react-hook-form'
import FormItem from 'stories/FormInputs/FormItem/FormItem'
import {
  getControlledFormItemProps,
  getFormItemDefaultValue,
  getFormItemValidationRules,
} from 'stories/FormInputs/FormItem'
import { typeUtils } from 'types'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { getDirtyFormFieldValues } from 'utils/form'
import { filterObj } from 'utils/formatter/data-structure'
import { setErrors } from 'utils/error'
import { notify } from 'utils/notification'
import Button from 'stories/button/Button'

const getEditableFields = (fields = []) =>
  fields.filter(
    field =>
      field.type &&
      !typeUtils.isNonRenderable(field) &&
      !typeUtils.isResponseVerificationState(field),
  )

const getDefaultValuesFromArray = (data, editableFieldSlugs) =>
  data
    ?.filter(item => editableFieldSlugs.includes(item.slug))
    .reduce(
      (prev, curr) => ({ ...prev, [curr.slug]: getFormItemDefaultValue(curr) }),
      {},
    )

const getDefaultValuesFromObject = (data, editableFieldSlugs) =>
  Object.entries(filterObj(editableFieldSlugs)(data)).reduce(
    (prev, [currSlug, currValue]) => ({
      ...prev,
      [currSlug]: getFormItemDefaultValue(currValue),
    }),
    {},
  )

const getDefaultValues = (data, fields) => {
  const editableFieldSlugs = fields.map(f => f.slug)

  return Array.isArray(data)
    ? getDefaultValuesFromArray(data, editableFieldSlugs)
    : getDefaultValuesFromObject(data, editableFieldSlugs)
}

const FormResponseEditForm = ({
  form,
  response,
  isEditMode,
  onSuccessfulSubmit,
  onFailedSubmit,
  onDirtyStateChange,
  disableFields = [],
  isFormDisable,
  onSubmit: { updateResponse, updateDraft, isSubmitting },
  ...props
}) => {
  const shouldUpdateDraft = React.useMemo(() => {
    return form.accept_draft_answers && response.status === 'draft'
  }, [response.status, form.accept_draft_answers])

  const ignoreRequired = React.useMemo(
    () => form.logic?.length > 0 || shouldUpdateDraft,
    [form.logic, shouldUpdateDraft],
  )
  const editableFields = getEditableFields(form.fields_list)
  const defaultValues = getDefaultValues(response.rendered_data, editableFields)

  // TODO:
  /**
   * verification fields' data are not present on the field's value
   * remove this once the api is updated
   *
   **/
  const rowDataProps = {
    isPhoneVerified: response.phone_verification_state === 'verified',
    isEmailVerified: response.email_verification_state === 'verified',
    trackingCode: response.tracking_code,
    submitCode: response.submit_code,
    createdAt: response.created_at,
    calculationScore: response.calculation_score,
  }

  const formDataProps = {
    currency: form.currency,
  }

  const {
    handleSubmit,
    reset,
    formState: { dirtyFields, isValid, isValidating, isDirty },
    control,
    setError,
  } = useForm({
    defaultValues,
    mode: 'onChange',
    criteriaMode: 'all',
  })

  useEffect(() => {
    if (!isValid) {
      handleSubmit(onSubmit)()
    }
  }, [])

  const setFieldError = useCallback(
    fieldSlug => errors => {
      setError(fieldSlug, errors)
    },
    [],
  )

  useEffect(() => {
    if (!isSubmitting && !isValidating) {
      onDirtyStateChange(isDirty)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty, isSubmitting, isValidating])

  const onSubmit = async data => {
    const formValues = getDirtyFormFieldValues(dirtyFields, data)

    const submitFn = shouldUpdateDraft ? updateDraft : updateResponse
    const submitSlug = shouldUpdateDraft ? response.submit_code : response.slug

    if (formValues && Object.keys(formValues).length > 0) {
      submitFn(submitSlug, formValues)
        .then(response => {
          reset(getDefaultValues(response?.rendered_data, editableFields))
          notify(
            <FormattedMessage id="response.edit.successMessage" />,
            'success',
          )
          typeof onSuccessfulSubmit === 'function' &&
            onSuccessfulSubmit(response)
        })
        .catch(errors => {
          setErrors(errors, setError)
          notify(<FormattedMessage id="general.error.fixFormErrors" />, 'error')
        })
    }
  }

  return (
    <>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={theme => ({
          marginBottom: theme.spacing(6),
          display: 'flex',
          minHeight: '100%',
          flexDirection: 'column',
        })}
      >
        {editableFields.map(fieldProps => {
          return (
            <Box
              key={fieldProps.slug}
              sx={theme => ({
                margin: '8px 0px',
                padding: theme.spacing(1, 0, 2),
              })}
            >
              <Controller
                name={fieldProps.slug}
                control={control}
                rules={getFormItemValidationRules(fieldProps, {
                  ignoreRequired,
                })}
                render={controllerProps => (
                  <FormItem
                    {...getControlledFormItemProps(
                      fieldProps,
                      controllerProps,
                      {
                        defaultValue: defaultValues[fieldProps.slug],
                        rowDataProps,
                        formDataProps,
                        setFieldErrors: setFieldError(fieldProps.slug),
                      },
                    )}
                    disabled={
                      disableFields.includes(fieldProps.slug) || isFormDisable
                    }
                  />
                )}
              />
            </Box>
          )
        })}

        <Box
          sx={theme => ({
            width: 'calc(100% + 48px)',
            bottom: '0',
            marginBottom: '-56px',
            display: 'flex',
            padding: '8px 24px',
            position: 'sticky',
            background: 'white',
            boxShadow: 'none',
            marginLeft: '-24px',
            justifyContent: 'flex-end',
            marginTop: 'auto',
            '& button': {
              [theme.breakpoints.down('sm')]: {
                minWidth: '70px',
              },
            },
          })}
        >
          <Button
            variant="outlined"
            color="secondary"
            disabled={!isDirty || isSubmitting}
            type="button"
            onClick={() => reset(defaultValues)}
            sx={{
              minWidth: 150,
              color: '#794EF1',
              minHeight: 50,
              ml: 1,
              border: 'none',
              '&.Mui-disabled': {
                border: 'none',
              },
              '&:hover': {
                boxShadow: 'none',
                border: 'none',
                background: 'none',
              },
            }}
          >
            <FormattedMessage id="general.reset" />
          </Button>

          <div>
            {!isValid && isDirty && (
              <Typography variant="caption" component="span" color="error">
                <FormattedMessage id="form.validation.invalidData" />
              </Typography>
            )}
            <Button
              variant="contained"
              color="secondary"
              disabled={!isDirty || !isValid || isValidating || isSubmitting}
              isLoading={isSubmitting}
              type="submit"
              sx={{
                minWidth: 150,
                background: '#794EF1',
                minHeight: 50,
                ml: 1,
                '&:hover': { background: '#794EF1', boxShadow: 'none' },
              }}
            >
              <FormattedMessage
                id={shouldUpdateDraft ? 'saveDraft' : 'saveAll'}
              />
            </Button>
          </div>
        </Box>
      </Box>
    </>
  )
}

FormResponseEditForm.defaultProps = {
  isEditMode: true,
}

FormResponseEditForm.propTypes = {
  form: PropTypes.object,
  response: PropTypes.object,
  isEditMode: PropTypes.bool,
  onSuccessfulSubmit: PropTypes.func,
  onFailedSubmit: PropTypes.func,
  onDirtyStateChange: PropTypes.func,
}

export default FormResponseEditForm
