import { createContext, useContext, useState, useEffect, useMemo } from 'react'
import { useMountEffect } from 'hooks/react'
import GlobalStyles from '@mui/material/GlobalStyles'
import { useBoardFn } from 'hooks/board'
import { setErrors } from '../utils/error'
import { BoardViewProvider } from './view'
import { BoardModeProvider } from './mode'
import { BLOCK_LOCATIONS } from '../utils/board/locations'
import { omit } from 'utils/formatter/data-structure'
import MuiThemeProvider from '@mui/material/styles/ThemeProvider'
import { themeGenerator } from '../utils/board/theme-generator'
import { THEMES_MODE } from 'utils/board/theme-configs'
import isEqual from 'lodash/isEqual'
import HelmetHeader from '../components/helmet/Header'
import { AuthContextProvider, useAuthContext } from 'context/auth'
import { useWindowLocation } from 'hooks/utility/useWindowLocation'
import RedirectToApp from '../components/board/auth/RedirectToApp'

const BoardContent = ({ children }) => {
  const { isAuthCallbackRoute } = useAuthContext()
  const { search } = useWindowLocation()

  if (isAuthCallbackRoute) return <RedirectToApp params={search} />
  return (
    <BoardModeProvider>
      <BoardViewProvider>{children}</BoardViewProvider>
    </BoardModeProvider>
  )
}
const BoardContext = createContext()

const BoardProvider = ({ children }) => {
  const [boardInfo, setBoardInfo] = useState(null)
  const [boardBlocks, setBoardBlocks] = useState({
    left: null,
    right: null,
    header: null,
    footer: null,
    main: null,
  })
  const [boardTheme, setBoardTheme] = useState(THEMES_MODE.light)

  const { fetchBoard } = useBoardFn()

  const setBlocksByLocations = blocks => {
    if (!blocks || !Object.keys(blocks).length) return null
    let arrangedBlocks = {}
    Object.keys(blocks).forEach(
      location =>
        (arrangedBlocks = {
          ...arrangedBlocks,
          [BLOCK_LOCATIONS[location]]:
            blocks[location]?.sort((b1, b2) => b1.position - b2.position) || [],
        }),
    )
    setBoardBlocks(arrangedBlocks)
  }

  const setBoardData = board => {
    board.blocks && setBlocksByLocations(board.blocks)
    setBoardInfo(omit(['blocks'])(board))
  }

  const setSingleLocationData = (data, location = 'main') => {
    setBoardBlocks({ ...boardBlocks, [location]: data })
  }

  useMountEffect(() => {
    fetchBoard()
      .then(board => setBoardData(board))
      .catch(err => setErrors(err))
  })

  useEffect(() => {
    const newTheme =
      boardInfo &&
      boardInfo.theme_config &&
      boardInfo.theme_config.mode &&
      !isEqual(boardInfo.theme_config.mode, boardTheme)
        ? THEMES_MODE[boardInfo.theme_config.mode]
        : null

    if (newTheme) {
      setBoardTheme(newTheme)
    }
  }, [boardInfo])

  const isAuthEnabled = useMemo(
    () => Boolean(boardInfo?.user_form),
    [boardInfo],
  )

  const boardTitle =
    boardInfo && (boardInfo.title || boardInfo.primary_form?.title)
  const boardLogo =
    boardInfo && (boardInfo.thumbnail_url || boardInfo.primary_form?.logo)

  return (
    <BoardContext.Provider
      value={{
        boardInfo,
        boardBlocks,
        isAuthEnabled,
        setBoardData,
        setBoardTheme,
        setSingleLocationData,
      }}
    >
      <AuthContextProvider>
        <HelmetHeader title={boardTitle || ''} logo={boardLogo || ''} />
        <MuiThemeProvider theme={themeGenerator(boardTheme)}>
          <GlobalStyles
            styles={{
              '&::-webkit-scrollbar': {
                width: 12,
              },
              '	&::-webkit-scrollbar-thumb': {
                backgroundColor:
                  themeGenerator(boardTheme).palette.primary.light,
                border: '3px solid rgba(0, 0, 0, 0)',
                backgroundClip: 'padding-box',
                borderRadius: 40,
              },
              '::-webkit-scrollbar': {
                background: 'none !important',
              },
            }}
          />
          <BoardContent>{children}</BoardContent>
        </MuiThemeProvider>
      </AuthContextProvider>
    </BoardContext.Provider>
  )
}

function useBoard() {
  const context = useContext(BoardContext)
  if (context === undefined) {
    throw new Error(`useBoard must be used within a BoardProvider`)
  }
  return context
}

export { BoardProvider, useBoard }
