import React, { useEffect } from 'react'
import Box from '@mui/material/Box'
import { useForm, useFieldArray } from 'react-hook-form'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'

import LazyLoad from 'utils/lazy-load'
import { setErrors } from 'utils/error'
import { useBoardFn } from 'hooks/board'
import { useBoard } from 'context/board'
import { useBoardMode } from 'context/mode'
import { LOCATIONS } from 'utils/board/locations'
import { BLOCK_TYPES } from 'utils/board/block-types'
import { useAccess } from 'hooks/access-management/useAccess'
import AddMenuButton from 'components/block/menu/AddMenuButton'
import { useWindowLocation } from 'hooks/utility/useWindowLocation'

const Menu = LazyLoad({ loader: () => import('components/block/menu') })
const Stats = LazyLoad({ loader: () => import('components/block/stats') })

export default function BlocksList() {
  const {
    boardBlocks: { left },
    boardInfo,
  } = useBoard()
  const { params } = useWindowLocation()
  const { isEditMode, setSwitchMode } = useBoardMode()
  const { isAllowedToEdit } = useAccess()
  const { updateBoard, isLoading: isUpdating } = useBoardFn()
  const { handleSubmit, control, reset } = useForm({
    mode: 'onBlur',
    defaultValues: {
      [LOCATIONS.left]: left,
    },
  })

  const {
    fields: allMenus,
    move,
    remove,
    append,
  } = useFieldArray({
    control,
    name: LOCATIONS.left,
  })

  useEffect(() => {
    reset({ [LOCATIONS.left]: left })
  }, [left])

  useEffect(() => {
    setSwitchMode(!isUpdating)
  }, [isUpdating])

  const onSubmit = data => {
    const leftSidebarBlocks = data
      ? data[LOCATIONS.left].map(block => block.slug)
      : []
    updateBoard({ blocks: { [LOCATIONS.left]: leftSidebarBlocks } }).catch(
      // TODO: display form errors on each block
      error => setErrors(error),
    )
  }

  const firstMenuOfSidebar = left.find(block => block.type === BLOCK_TYPES.menu)

  const isMenuBlock = blockType => Boolean(blockType === BLOCK_TYPES.menu)

  const onDragEnd = ({ destination, source }) => {
    const destinationIndex = parseInt(destination.index)
    const sourceIndex = parseInt(source.index)

    if (destinationIndex !== sourceIndex) {
      move(sourceIndex, destinationIndex)
      handleSubmit(onSubmit)()
    }
  }

  const addToList = newBlock => {
    append(newBlock)
    handleSubmit(onSubmit)()
  }

  const checkToSelectMenu = blockSlug => {
    if (params?._b) return false
    return firstMenuOfSidebar && firstMenuOfSidebar.slug === blockSlug
  }

  const blockMapper = (block, dndProps) => {
    switch (block.type) {
      case BLOCK_TYPES.stats:
        return (
          <Stats
            key={block.slug}
            slug={block.slug}
            boardSlug={boardInfo && boardInfo.slug}
            dndProps={dndProps}
          />
        )
      case BLOCK_TYPES.menu:
        return (
          <Menu
            key={block.slug}
            isEditMode={isEditMode}
            boardSlug={boardInfo && boardInfo.slug}
            isFirstMenuToDisplay={checkToSelectMenu(block.slug)}
            slug={block.slug}
            dndProps={dndProps}
          />
        )
      default:
        return null
    }
  }

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="board-menu-list">
          {provided => (
            <Box
              {...provided.droppableProps}
              ref={provided.innerRef}
              sx={{
                backgroundColor: 'transparent',
                width: '100%',
              }}
            >
              {allMenus.map((block, index) => {
                const dndProps = {
                  menuSlug: block.slug,
                  isDragDisabled: !isEditMode || !isMenuBlock(block.type),
                  index: index,
                  boardSlug: boardInfo && boardInfo.slug,
                  deleteMenu: () => remove(index),
                }
                return (
                  <React.Fragment key={block.slug}>
                    {blockMapper(block, dndProps)}
                  </React.Fragment>
                )
              })}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      </DragDropContext>
      <AddMenuButton
        addToList={addToList}
        disabled={!isEditMode || !isAllowedToEdit}
      />
    </>
  )
}
