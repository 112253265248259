import settings from 'app-settings/url.settings.js'
import Link from '@mui/material/Link'
import Box from '@mui/material/Box'
import { FormattedMessage } from 'react-intl'
import logo from 'assets/images/formaloo-logo.svg'
import { useBoard } from 'context/board'
import { useBoardFn } from 'hooks/board'
import CopyIcon from 'stories/icons/Copy'
import assetSettings from 'app-settings/assets.settings'

const { logoUrl: customLogoUrl } = assetSettings

export default function WaterMark({}) {
  const { boardInfo } = useBoard()
  const { isPrivate } = useBoardFn()

  if (isPrivate) return null

  const tempType = boardInfo?.primary_form?.template_type || 'board'

  const Badge = boardInfo?.primary_form?.has_watermark && (
    <Link
      href={settings.websiteDomain}
      sx={{
        display: 'inline-flex',
        py: 0.5,
        px: 1,
        borderRadius: 2,
        backgroundColor: '#4f5968',
        border: '2px solid #4f5968',
        color: 'white',
        alignItems: 'center',
        fontSize: '13px',
        fontWeight: 500,
        whiteSpace: 'nowrap',
        '& > img': {
          ml: 1,
        },
      }}
      rel="noreferrer"
      target="_blank"
      underline="none"
    >
      <FormattedMessage id="madeWith" />
      <img
        src={customLogoUrl ?? logo}
        alt="Formaloo"
        style={{
          width: 75,
        }}
      />
    </Link>
  )

  const CopyButton = boardInfo?.is_template && (
    <Link
      href={`${settings.formBuilderDomain}/d/${boardInfo?.primary_form?.slug}/${tempType}/${boardInfo?.share_address}`}
      rel="noreferrer"
      target="_blank"
      underline="none"
      sx={{
        display: 'inline-flex',
        py: 0.5,
        px: 1,
        ml: 1.5,
        borderRadius: 2,
        backgroundColor: 'white',
        color: 'text.primary',
        alignItems: 'center',
        fontSize: '13px',
        border: '2px solid ',
        borderColor: 'primary.light',
        whiteSpace: 'nowrap',

        fontWeight: 500,
        '& > svg': {
          ml: 1,
        },
      }}
    >
      <FormattedMessage id="app.temp.copy" />
      <CopyIcon color="#333A45" />
    </Link>
  )
  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: 8,
        right: 16,
        display: 'inline-flex',
        alignItems: 'center',
      }}
    >
      {Badge}
      {CopyButton}
    </Box>
  )
}
