export default {
  GREETING: '{name}님, 안녕하세요.',
  edit: '편집하다',
  submit: '제출하다',
  yes: '예',
  no: '아니요',
  questions: '질문',
  delete: '삭제',
  view: '보다',
  close: '닫다',
  tags: '태그',
  saveAll: '구하다',
  uncategorized: '분류되지 않은',
  untitled: '제목 없음',
  readOnly: '읽기 전용',
  editable: '편집 가능',
  done: '완료',
  trackingCode: '추적 코드',
  beta: '베타',
  'view.public': '공개 보기',
  open: '열려 있는',
  saveDraft: '초안 업데이트',
  draft: '초안',
  login: '로그인',
  logout: '로그 아웃',
  signup: '가입하기',
  account: '내 계정',
  email: '이메일',
  'app.edit': '폼 수정',
  'app.settings': '앱 설정',
  'app.settings.isPublic': '공개 링크로 전환',
  'app.settings.publicAddress': '퍼블릭 어드레스',
  'app.settings.saved.success': '최종 설정',
  'app.settings.users_settings_enabled':
    '포털 모드 ( 액티브로그인/가입) {badge}',
  'app.user.profile': '프로필',
  'app.users.button': '사용자',
  'app.users.title': '사용자',
  'app.users.settings.signup.warning':
    '포털 모드, 설정이 완료되지 않았습니다. 사용자 테이블을 선택하거나 새로 만들어 주세요',
  'app.users.settings.signup': '포털 모드, 액티브',
  'app.users.settings.signup.desc':
    '활성이면 앱이 사용자 전용 포털로 변경되고, 사용자는 가입 및 로그인할 수 있습니다.',
  'app.users.settings.signup.modal.title': '사용자 테이블',
  'app.users.settings.signup.modal.desc':
    '새 사용자 테이블을 만들거나 기존 사용자 테이블 중 하나를 선착해 주세요.',
  'app.users.settings.signup.table.create': '새테이블',
  'app.users.settings.signup.table.exiting': '테이블에 연결',
  'app.users.settings.login.label': '로그인',
  'app.users.settings.signup.label': '회원 가입',
  'app.users.settings.paidMembership.label': '결제 틸트 가입 활성',
  'app.users.settings.captchaEnabled.label': '캡처 활성',
  'app.users.settings.otp.label': 'OTP 스마트폰(이메일인증)',
  'app.users.settings.save.success': '변경사항이 성공적으로 저장되었습니다.',
  'app.users.table.edit': '폼 수정',
  'app.users.table.change': '사용자 테이블 변경',
  'block.group.item.create.title': '메뉴에 추가',
  'block.group.subGroup.create.title': '+ 페이지 추가',
  'block.group.item.create.button': '+ 페이지 추가',
  'field.validation.required': '이 필드는 필수입니다.',
  'field.validation.url': '유효한 URL을 입력하세요.',
  'field.placeholder.link': '링크 URL',
  'field.placeholder.title': '제목',
  'field.placeholder.fields': '필드 선택: ',
  'field.placeholder.xField': 'x축 필드 선택:',
  'field.placeholder.yFields': 'y축 필드 선택:',
  'block.group.item.view.label': '데이터 보기 선택: ',
  'block.group.item.form.label': '테이블 선택:',
  'block.group.item.type.label': '표시하고 싶은 데이터를 선택해 주세요',
  'block.type.fields.all': '모든 필드',
  'block.type.fields.specific': '선택 필드',
  'block.type.group': '서브 메뉴',
  'block.type.link': '맞춤 링크',
  'block.type.viewData': '블록',
  'block.type.label': '유형 선택',
  'block.menu.create': '+ 메뉴 추가',
  'block.menu.create.title': '새 메뉴',
  'block.type.kanban.title': '보드',
  'block.type.kanban.columnField': '열 그룹 기준',
  'block.type.kanban.featureImage': '카드 이미지',
  'block.type.kanban.cardTitle': '카드 제목',
  'block.type.kanban.cardFields': '카드에 표시할 필드',
  'block.type.kanban.mode': '액세스 유형',
  'block.type.kanban.view': '보다',
  'block.access.label': '접근 권한',
  'block.access.public': '전체 공개',
  'block.access.logoutOnly': '비로그인 사용자',
  'block.access.loginOnly': '로그인 사용자',
  'block.access.roleBase': '특정 레벨 사용자',
  'block.access.team.all': '모든 팀멤버',
  'block.access.team.roleBase': '특정 팀멤버',
  'block.access.roleBase.label': '사용자 역할 선택',
  'block.access.team.label': '팀 액세스 수준을 선택하고',
  'block.access.team.role.admin': '관리자',
  'block.access.team.role.owner': '소유자',
  'block.access.team.role.member': '팀 구성원',
  'block.access.team.role.guest': '손님',
  'block.edit.base': '베이스 편집',
  'block.settings': '설정',
  'block.save': '구하다',
  'report.chart.type.label': '차트 유형 선택:',
  'report.revert': '되돌린 차트',
  'chart.type.bar': '막대 차트',
  'chart.type.pie': '파이 차트',
  'chart.type.line': '라인 차트',
  'block.type.data.advanceChart': '구성 차트 {description}',
  'block.type.data.chart': '차트',
  'block.type.data.table': '테이블',
  'kanban.view.default': '칸반',
  'kanban.view.grid': '그리드 {description}',
  'kanban.search.placeholder': '카드 검색...',
  'field.result.likeDislike.title': '(좋아요/싫어요)',
  'field.result.likeDislike.option.like': '좋다',
  'field.result.likeDislike.option.dislike': '싫어함',
  'field.result.nps.title': '(10점 만점)',
  'field.result.score.title': '(100점 만점)',
  'field.result.star.title': '(5점 만점)',
  'field.result.matrix.button': '답변 표시',
  'field.file.sizeLimit': '최대 파일 크기: {size}KB',
  'field.creatable.create': '"{option}" 생성',
  'table.recordsCount': '{count} 기록',
  'general.genericDateTitle': '날짜',
  'table.labelDisplayedRows': '{count} 중 {from}-{to}',
  'pagination.rows per page': '페이지당 행',
  'general.genericReferredAddress': '추천 주소',
  'table.empty': '이런! 자료 없음...',
  'table.moreThan': '{to} 이상',
  'table.actions': '행위',
  'table.bulkActions.delete.success':
    '{count} 항목이 성공적으로 삭제되었습니다.',
  'table.bulkActions.delete.error': '이런! 뭔가 잘못됐어...',
  'table.row.add': '행 추가',
  'filters.submit.status.all': '모두',
  'filters.submit.status.complete': '완벽한',
  'filters.submit.status.inComplete': '부분(자동 저장)',
  'filters.submit.status': '행 상태',
  'filters.condition.label': '상태',
  'filters.operator.label': '운영자',
  'filters.operand.label': '값',
  'filters.chip.label': '{field} {condition}: {value}',
  'sortBy.label': '정렬 기준',
  'sortBy.asc': '오름차순',
  'sortBy.desc': '설명',
  'sortBy.chip.label': '정렬 기준: {field} - {direction}',
  'filters.conditions.exact': '이다',
  'filters.conditions.iexact': '입니다(대소문자 구분 안 함).',
  'filters.conditions.contains': '포함',
  'filters.conditions.icontains': '포함(대소문자 구분 안 함)',
  'filters.conditions.lt': '미만',
  'filters.conditions.lte': '이하',
  'filters.conditions.gt': '보다 큰',
  'filters.conditions.gte': '보다 크거나 같음',
  'filters.time.gte': '날짜부터',
  'filters.time.lte': '지금까지',
  'filters.search': '찾다',
  'filters.field.hidden': '{field}',
  'filters.field.hidden.title': '열 숨기기',
  'filters.date.title': '날짜 필터',
  'filters.tags': '태그는',
  filter: '필터',
  'pagination.first page': '첫 페이지',
  'pagination.last page': '마지막 페이지',
  'pagination.previous page': '이전 페이지',
  'pagination.next page': '다음 페이지',
  'date.from': '에서',
  'date.to': '에게',
  'date.clear': '분명한',
  save: '변경 사항 저장',
  saveAs: '새 테이블로 저장',
  'filter.time.title': '날짜 범위',
  'invitation.massage.error': '뭔가 잘못됐어...',
  'form.location.viewOnGoogleMaps': '지도에서 보기',
  'form.validation.regex': '값이 패턴과 일치하지 않습니다.',
  'form.validation.required': '필수항목입니다.',
  'form.validation.required.matrix': '이 필드의 모든 질문에 답해야 합니다.',
  'form.validation.email': '유효한 이메일을 입력하세요.',
  'form.validation.maxLength': '답은 {maxLength}자 미만이어야 합니다.',
  'form.validation.minValue': '답은 {minValue}보다 커야 합니다.',
  'form.validation.maxValue': '대답은 {maxValue}보다 작아야 합니다.',
  'form.validation.url':
    'URL은 http 또는 https를 포함해야 합니다. 유효한 URL을 입력하세요.',
  'form.validation.number': '이 필드에는 숫자만 입력하십시오.',
  'form.validation.between': '시간은 {min}에서 {max} 사이여야 합니다.',
  'form.validation.to': '시간은 {max} 미만이어야 합니다.',
  'form.validation.from': '시간은 {min} 이상이어야 합니다.',
  'form.validation.date.fromTo': '{desc}({min}에서 {max} 사이의 날짜)',
  'form.validation.date.from': '{desc}({min}의 날짜)',
  'form.validation.date.to': '{desc}({max}까지의 날짜)',
  'form.validation.phone.mobile': '유효한 휴대폰 번호를 입력하십시오',
  'form.validation.phone.landline': '유효한 전화번호를 입력하세요.',
  'form.validation.product.minValue': '수량은 {minValue} 이상이어야 합니다.',
  'form.validation.product.maxValue': '수량은 {maxValue} 미만이어야 합니다.',
  'form.validation.maxDigit': '답은 {maxLength}자리 미만이어야 합니다.',
  'form.validation.invalidData': '유효하지 않은 데이터',
  'form.signature.notSigned': '서명되지 않음!',
  'a11y.ariaLabel.button': '{label} 버튼',
  'form.response.tags.empty':
    '태그를 찾을 수 없습니다. 새 태그를 만들려면 입력하세요.',
  'form.response.tags.title': '태그',
  'form.response.tags.create.success': '태그가 성공적으로 생성되었습니다!',
  'form.response.tags.delete.success': '태그가 성공적으로 삭제되었습니다!',
  'form.response.tags.update.success': '태그가 성공적으로 업데이트되었습니다!',
  'form.response.tags.update.error': '행 태그를 업데이트할 수 없습니다!',
  'form.fields.choices.typeToSearch': '검색 옵션에 입력...',
  'form.fields.choices.loading': '검색 옵션...',
  'form.fields.choices.noOptionsAvailable': '옵션을 찾을 수 없습니다.',
  'form.fields.dropDown.placeholder': '클릭하여 선택',
  'form.submitResults.createRow.successMessage':
    '새 행이 성공적으로 생성되었습니다.',
  'form.field.product.quantity': '수량',
  'form.options': '폼 옵션',
  'kanban.filter.title': '필터',
  'kanban.card.new': '새 카드 추가',
  'kanban.settings.customizing': '카드 사용자 지정',
  'kanban.settings.groupBy': '그룹화',
  'kanban.column.card.count': '카드 수',
  'table.actions.row.create': '새로운 행',
  'response.edit.successMessage': '변경사항이 성공적으로 저장되었습니다.',
  'general.genericErrorMessage': '뭔가 잘못됐어...',
  'general.error.fixFormErrors': '양식 오류를 수정해주세요!',
  'general.reset': '모든 변경 사항을 재설정하고',
  'general.downloadFile': '파일 다운로드',
  'general.unsavedChanges': '저장되지 않은 변경 사항',
  'charts.avgRate': '평균 비율: {avg}',
  'charts.totalAnswer': '총 응답 수: {total}',
  'charts.npsScore': 'NPS 점수: {score}',
  'grid.column.number': '숫자',
  'grid.column.columns': '열',
  'summary.edit': '폼 수정',
  'summary.view': '폼 보기',
  'summary.share': '공유',
  'summary.chart': '차트',
  'summary.response': '응답',
  'summary.integration': '통합 및 웹훅',
  'summary.presentation': '프레젠테이션',
  'validation.slug': '유효한 주소를 입력하세요',
}
