export const BLOCK_TYPES = {
  menu: 'menu',
  stats: 'stats',
  form: 'form_display', // returns all fields and data of form; if an array of fields set for this type, this returns only those specific fields
  formResult: 'form_result',
  formChart: 'form_charts', // returns all charts of form's fields; if an array of fields set for this type, this returns only the charts of specific fields
  kanban: 'kanban',
  report: 'report',
}

export const BLOCK_TYPE_CREATE = {
  // these types are used on endpoints of creating block
  form: 'form-display', // returns all fields and data of form; if an array of fields set for this type, this returns only those specific fields
  formResult: 'form-result',
  formChart: 'form-charts', // returns all charts of form's fields; if an array of fields set for this type, this returns only the charts of specific fields
  report: 'reports',
  kanban: 'kanbans', // returns all charts of form's fields; if an array of fields set for this type, this returns only the charts of specific fields
}

export const BLOCK_SUBTYPES = {
  form: {
    all: 'all_fields', // returns all fields and data of form
    fields: 'display_fields', // if an array of fields set for form type, this returns only those specific fields
  },
  chart: {
    all: 'all_charts', // returns all charts of form's fields
    fields: 'fields_charts', // if an array of fields set for chart type, this returns only the charts of specific fields
  },
}

export const MENU_BLOCK_ITEM_TYPES = {
  group: 'group', // this item contains sub-items. (sub-items are just like other menu items, except they can't be group themselves). If you use this type, you should include the sub_items in your object.
  link: 'link', // this item is supposed to open a link. If you use this, you should include a valid link in your object.
  block: 'block', // this item is supposed to open a block. If you use this, you should include a valid block's slug in your object.
}
