import RadioGroupInput from 'stories/FormInputs/RadioGroup/RadioGroup'
import { typeUtils } from 'types'
import { getFormItemErrors, getFormItemLabel } from './_propGetters'
import { getRequiredRule } from './_validationProps'

const generateOptions = (start, end) => {
  return Array.from({ length: end - start + 1 }, (_, i) => ({
    value: i + 1,
    label: i + 1,
  }))
}

const formControlLabelProps = {
  labelPlacement: 'top',
}

const getDefaultValue = fieldProps => fieldProps.raw_value

const Component = RadioGroupInput

const getProps = fieldProps => ({
  ...fieldProps,
  name: fieldProps.slug,
  label: getFormItemLabel(fieldProps),
  // input props
  separateLabel: true,
  options: generateOptions(
    fieldProps.range_start || 1,
    fieldProps.range_end || 5,
  ),
  formControlLabelProps,
})

const getControlledProps = (fieldProps, controllerProps, defaultValue) => ({
  ...controllerProps.field,
  type: typeUtils.getMostSpecificType(fieldProps),
  label: getFormItemLabel(fieldProps),
  // input props
  separateLabel: true,
  errors: getFormItemErrors(controllerProps),
  options: generateOptions(
    fieldProps.range_start || 1,
    fieldProps.range_end || 5,
  ),
  onChange: value => controllerProps.field.onChange(Number(value)),
  formControlLabelProps,
})

const getValidationRules = (fieldProps, options) => ({
  ...getRequiredRule(fieldProps, options),
})

export const starInput = {
  Component,
  getProps,
  getControlledProps,
  getValidationRules,
  getDefaultValue,
}
