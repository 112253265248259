import { FormattedMessage, useIntl } from 'react-intl'
import { useTheme } from '@mui/material/styles'
import InputAdornment from '@mui/material/InputAdornment'
import { useState } from 'react'
import Box from '@mui/material/Box'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import Collapse from '@mui/material/Collapse'
import { useBoard } from 'context/board'
import urlSettings from 'app-settings/url.settings'
import Modal from 'stories/modal/Modal'
import { useForm, Controller, useWatch } from 'react-hook-form'
import Toggle from '../../../stories/fields/board-ui/Toggle'
import TextField from '../../../stories/fields/board-ui/TextField'
import ExternalLink from '../../../stories/icons/ExternalLink'
import SettingsIcon from '../../../stories/icons/Settings'
import Button from '../../../stories/button/Button'
import { useBoardFn } from 'hooks/board'
import { setErrors } from 'utils/error'
import { omit } from 'utils/formatter/data-structure'
import { notify } from 'utils/notification'

export default function AppSettings({}) {
  const [isModalOpen, setModal] = useState(false)

  const theme = useTheme()
  const { boardInfo, setBoardData } = useBoard()
  const { updateBoard, isLoading: isSaving } = useBoardFn()

  const { formatMessage } = useIntl()

  const {
    handleSubmit,
    control,
    register,
    setError,
    reset,
    formState: { errors, isValid, isDirty },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      is_public: boardInfo?.is_public,
      share_address: boardInfo?.share_address,
      config: boardInfo?.config || { users_settings_enabled: false },
      custom_domain: boardInfo?.custom_domain || '',
      // address_is_universal: true,
      is_template: boardInfo?.is_template || false,
      template_type: boardInfo?.template_type || 'board',
    },
  })

  const isAppPublic = useWatch({
    control,
    defaultValue: boardInfo?.is_public,
    name: 'is_public',
  })

  const publicAddress = useWatch({
    control,
    defaultValue: boardInfo?.share_address,
    name: 'share_address',
  })

  const onSubmit = async data => {
    if (!data.is_public) {
      delete data.share_address
      delete data.custom_domain
      // delete data.address_is_universal
    }
    updateBoard(data, { alwaysSend: ['custom_domain'] })
      .then(board => {
        notify(formatMessage({ id: 'app.settings.saved.success' }))
        setBoardData(omit(['blocks'])(board))
        reset({
          share_address: board.share_address,
          is_public: board.is_public,
          config: board.config,
          custom_domain: board.custom_domain,
          is_template: board.is_template,
          // address_is_universal: board.address_is_universal,
        })
      })
      .catch(err => setErrors(err, setError))
  }

  return (
    <>
      <Box
        onClick={() => {
          setModal(true)
        }}
        sx={{
          display: 'flex',
          alignItems: 'center',
          height: 40,
          px: 1,
          py: 1.5,
          minWidth: 'auto',
          borderRadius: 2,
          ml: 'auto',
          color: 'text.primary',
          fontWeight: 600,
          textTransform: 'capitalize',
          cursor: 'pointer',
          '& > *:first-of-type': { mr: 1 },
          backgroundColor: isModalOpen ? 'primary.active' : 'inherit',
        }}
      >
        <SettingsIcon
          color={theme.palette.primary.dark}
          style={{ width: 20, height: 20 }}
        />
        <FormattedMessage
          id="app.settings"
          description="Button text"
          defaultMessage="Edit"
        />
      </Box>

      <Modal open={isModalOpen} onClose={() => setModal(false)}>
        <Typography component="p" variant="h5" sx={{ mb: 3 }}>
          <FormattedMessage id="app.settings" />
        </Typography>
        <Box component="form" onSubmit={handleSubmit(onSubmit)} width="100%">
          <Controller
            name="is_public"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <Toggle
                {...field}
                sx={{ mb: 1 }}
                errors={errors}
                label={formatMessage({ id: 'app.settings.isPublic' })}
              />
            )}
          />

          <Collapse in={isAppPublic}>
            <Box width="100%">
              <TextField
                inputProps={{
                  ...register('share_address', {
                    pattern: {
                      value: /^[a-zA-Z0-9_]+$/,
                      message: formatMessage({ id: 'validation.slug' }),
                    },
                    required: {
                      value: isAppPublic,
                      message: formatMessage({
                        id: 'field.validation.required',
                      }),
                    },
                  }),
                  placeholder: formatMessage({
                    id: 'app.settings.publicAddress',
                  }),
                  disabled: !isAppPublic,
                  startAdornment: (
                    <InputAdornment sx={{ mr: 0.5 }} position="start">
                      <Typography component="span" variant="body2">
                        {`${urlSettings.baseUrl}/`}
                      </Typography>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      {isAppPublic && (
                        <Link
                          href={`${urlSettings.baseUrl}/${publicAddress}`}
                          target="_blank"
                          underline="none"
                        >
                          <ExternalLink
                            width="20px"
                            height="20px"
                            color="#8F6EFD"
                          />
                        </Link>
                      )}
                    </InputAdornment>
                  ),
                }}
                sx={{ '& .MuiInputBase-input': { width: 'auto', flexGrow: 1 } }}
                errors={errors}
              />

              <TextField
                inputProps={{
                  ...register('custom_domain', {
                    pattern: {
                      value: /^[a-zA-Z0-9-.-_-]+$/,
                      message: formatMessage({ id: 'validation.domain' }),
                    },
                  }),
                  placeholder: formatMessage({ id: 'app.settings.cname' }),
                }}
                errors={errors}
              />
              {/* 
              <Controller
                name="address_is_universal"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <Toggle
                    {...field}
                    sx={{ mb: 1 }}
                    errors={errors}
                    label={formatMessage({
                      id: 'app.settings.isUniversal',
                    })}
                  />
                )}
              /> */}
            </Box>
          </Collapse>
          <Controller
            name="config[users_settings_enabled]"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <Toggle
                {...field}
                sx={{ mb: 1 }}
                errors={errors}
                label={formatMessage(
                  {
                    id: 'app.settings.users_settings_enabled',
                  },
                  {
                    badge: (
                      <Typography
                        color="secondary"
                        variant="subtitle2"
                        component="span"
                      >
                        (<FormattedMessage id="beta" />)
                      </Typography>
                    ),
                  },
                )}
              />
            )}
          />

          <Controller
            name="is_template"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <Toggle
                {...field}
                sx={{ mb: 1 }}
                errors={errors}
                label={formatMessage({
                  id: 'app.settings.isTemplate',
                })}
              />
            )}
          />
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
            <Button
              type="submit"
              variant="contained"
              isLoading={isSaving}
              disabled={isSaving || !isValid || !isDirty}
            >
              <FormattedMessage id="save" />
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  )
}
