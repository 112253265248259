import { auth } from 'api/auth'
import { isInFakeEmbed } from 'utils/common'
import { notify } from 'utils/notification'

const fakeStorage = {
  logout: () => {
    sessionStorage.removeItem('token')
    localStorage.removeItem('session')
    localStorage.removeItem('active-workspace')
  },
  setUserToken: tokenObj => {
    tokenObj && sessionStorage.setItem('token', JSON.stringify(tokenObj))
  },
  getAccessToken: () => JSON.parse(sessionStorage.getItem('token') ?? null),
  isAuthenticated: () => !!localStorage.getItem('session'),
}

async function getOrRefreshToken() {
  const {
    data: { errors, token },
  } = await auth().getToken()

  if (errors) {
    notify(errors.general_errors, 'error')
    fakeStorage.logout()
    return Promise.reject(errors)
  }

  if (token) {
    fakeStorage.setUserToken(token)
    return Promise.resolve(token)
  }
}

export const FakeEmbedAuthStorage = () => {
  return Object.freeze({
    isAuthenticated: () => fakeStorage.isAuthenticated(),
    accessToken: {
      get: () => fakeStorage.getAccessToken(),
    },
    session: {
      get: () => JSON.parse(localStorage.getItem('session') ?? null),
    },
    getOrRefreshToken,
  })
}

export const setupFakeEmbed = () => {
  if (isInFakeEmbed()) {
    console.log('setting up fake embed...')
    getOrRefreshToken()
  }
}
