import { config, getCurrentWorkspace } from './utils'
import { httpClient, HTTP } from './utils/http'
import API from 'app-settings/api.settings'

// #region FORM ROWS
const workspace = getCurrentWorkspace()

export const responses = () => ({
  // TODO: replace with the new create endpoint after api update
  create: (formData, formSlug) =>
    httpClient.post(
      `${API.baseUrl}/v4/form-displays/slug/${formSlug}/submit/`,
      formData,
      config({
        contentType: HTTP.ContentTypes.JSON,
        workspace,
      }),
    ),
  get: submitSlug =>
    httpClient.get(
      `${API.baseUrl}/v4/rows/${submitSlug}/`,
      config({ workspace }),
    ),

  getAll: (formSlug, params = {}) =>
    httpClient.get(
      `${API.baseUrl}/v4/forms/${formSlug}/rows/`,
      config({ params, workspace }),
    ),

  update: (submitSlug, formData) =>
    httpClient.patch(
      `${API.baseUrl}/v4/rows/${submitSlug}/`,
      formData,
      config({
        contentType: HTTP.ContentTypes.JSON,
        workspace,
      }),
    ),
  updateDraft: (submitSlug, formData) =>
    httpClient.patch(
      `${API.baseUrl}/v4/draft-rows/${submitSlug}/`,
      formData,
      config({
        contentType: HTTP.ContentTypes.JSON,
        workspace,
      }),
    ),
  delete: submitSlug =>
    httpClient.delete(
      `${API.baseUrl}/v4/forms/row/${submitSlug}/`,
      config({ workspace }),
    ),

  deleteMultiple: (formSlug, rowSlugs) =>
    httpClient.post(
      `${API.baseUrl}/v4/forms/${formSlug}/rows/bulk-delete/`,
      { slugs_list: rowSlugs },
      config({
        contentType: HTTP.ContentTypes.JSON,
        workspace,
      }),
    ),
})
// #endregion FORM ROWS
