import { notify } from './notification'

export const flattenFormErrors = (fe = {}) =>
  Object.keys(fe)
    .map(fieldName => ({
      [fieldName]: Array.isArray(fe[fieldName])
        ? fe[fieldName].join(' ')
        : fe[fieldName],
    }))
    .reduce((prev, curr) => ({ ...prev, ...curr }), {})

export const flattenErrors = ({
  general_errors: ge = [],
  form_errors: fe = {},
}) => {
  const formErrors = flattenFormErrors(fe)
  return [
    ...ge,
    ...Object.keys(formErrors).map(
      fieldName => `${fieldName}: ${formErrors[fieldName]}`,
    ),
  ]
}

export const setErrors = (err, formSetError) => {
  // server errors = 500
  if (err.response && err.response.status >= 500) {
    notify('Something went wrong...', 'error')
    return
  }

  if (!formSetError) {
    flattenErrors(err).map(err => notify(err, 'error'))
    return
  }

  if (err.general_errors) {
    flattenErrors({ general_errors: err.general_errors })
  }

  if (err.form_errors) {
    const fieldsHasError = Object.keys(err.form_errors)
    fieldsHasError.forEach(field => {
      let fieldErrors = ''
      err.form_errors[field].map(err => {
        fieldErrors = `${fieldErrors} - ${err} `
      })
      formSetError(field, { message: fieldErrors })
    })
  }
}
