import React from 'react'
import PropTypes from 'prop-types'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { FormattedNumber } from 'react-intl'
import Link from '@mui/material/Link'

const MatrixTableLayout = React.forwardRef(
  (
    { questions, options, value: controlledValue, onChange, size, label },
    ref,
  ) => {
    return (
      <TableContainer>
        <Table size={size} aria-label={label}>
          <TableHead>
            <TableRow>
              <TableCell />
              {options.map(opt => (
                <TableCell key={opt.slug} align="center">
                  <>
                    {opt.image && (
                      <Link
                        sx={{
                          display: 'inline-block',
                        }}
                        href={opt.image}
                        target="_blank"
                        rel="noopener noreferrer nofollow"
                      >
                        <img
                          style={{
                            width: 64,
                            height: 64,
                            objectFit: 'contain',
                            objectPosition: 'center',
                          }}
                          src={opt.image}
                          alt={opt.title}
                        />
                      </Link>
                    )}
                    {opt.title && <span>{opt.title}</span>}
                  </>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {questions.map((q, index) => (
              <TableRow key={q.slug}>
                <TableCell component="th" scope="row">
                  <FormattedNumber value={index + 1} />. {q.title}
                </TableCell>
                {options.map(({ render: OptionInputComponent, ...opt }) => (
                  <TableCell key={`${q.slug}:${opt.slug}`} align="center">
                    <OptionInputComponent
                      {...{
                        question: q,
                        option: opt,
                        value: controlledValue ? controlledValue[q.slug] : '',
                        onChange,
                        inputRef: ref,
                      }}
                    />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    )
  },
)

MatrixTableLayout.defaultProps = {
  size: 'small',
  label: 'table',
}

MatrixTableLayout.propTypes = {
  questions: PropTypes.array.isRequired,
  options: PropTypes.array.isRequired,
  size: PropTypes.oneOf(['small', 'medium']),
  label: PropTypes.string,
  /**
   *
   * ```
   *  onChange(questionName, value)
   * ```
   */
  onChange: PropTypes.func.isRequired,
}

export default MatrixTableLayout
