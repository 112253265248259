export default {
  GREETING: 'Hello {name}',
  edit: 'edit',
  submit: 'submit',
  yes: 'Yes',
  no: 'No',
  questions: 'Questions',
  delete: 'delete',
  view: 'View',
  close: 'Close',
  tags: 'Tags',
  saveAll: 'Save',
  uncategorized: 'uncategorized',
  untitled: 'Untitled',
  readOnly: 'Read-only',
  editable: 'Editable',
  done: 'Done',
  trackingCode: 'Tracking Code',
  beta: 'Beta',
  'view.public': 'Public view',
  open: 'Open',
  saveDraft: 'Update Draft',
  draft: 'draft',
  login: 'Login',
  logout: 'Logout',
  signup: 'Sign Up',
  account: 'My Account',
  email: 'Email',
  madeWith: 'Made with',
  copied: 'Copied',
  'app.temp.copy': 'Copy Template',
  'app.edit': 'Edit app',
  'app.settings': 'App settings',
  'app.settings.isTemplate': 'Convert app to template',
  'app.settings.isPublic': 'Enable public view',
  'app.settings.cname': 'App custom domain',
  'app.settings.isUniversal':
    'Use the same address everywhere (Formaloo, Custom domain)',
  'app.settings.publicAddress': 'Public Address',
  'app.settings.saved.success': 'Settings updated successfully.',
  'app.settings.users_settings_enabled':
    'Enable Portal Mode (Login/Signup feature) {badge}',
  'app.user.profile': 'Profile',
  'app.users.button': 'Users',
  'app.users.title': 'Users',
  'app.users.settings.signup.warning':
    'Sign up process is not completed. Please select or create your user table.',
  'app.users.settings.signup': 'Enable Portal Mode',
  'app.users.settings.signup.desc':
    'By enabling this, your app will turn into a membership-only portal and your audience can sign up and login inside your application.',
  'app.users.settings.signup.modal.title': 'Users Table',
  'app.users.settings.signup.modal.desc':
    'create a new users table or choose one of their existing users tables',
  'app.users.settings.signup.table.create': 'New Table',
  'app.users.settings.signup.table.exiting': 'Connect to your Tables',
  'app.users.settings.login.label': 'Allow users to login ',
  'app.users.settings.signup.label': 'Allow new users to sign up',
  'app.users.settings.paidMembership.label':
    'Enable a paywall for sign ups (coming soon)',
  'app.users.settings.captchaEnabled.label': 'Enable captcha',
  'app.users.settings.otp.label':
    'Allow users to login via OTP (verifying a code sent to their emails)',
  'app.users.settings.save.success': 'Changes saved successfully',
  'app.users.table.edit': 'Edit Form',
  'app.users.table.change': 'Change Users Table',

  'block.group.item.create.title': 'Create menu item',
  'block.group.subGroup.create.title': '+ Add page',
  'block.group.item.create.button': '+ Add page',

  'field.validation.required': 'This field is required',
  'field.validation.url': 'Enter valid url',
  'field.placeholder.link': 'Link url',
  'field.placeholder.title': 'Title',
  'field.placeholder.fields': 'Select fields:',
  'field.placeholder.xField': 'Select x-axis field:',
  'field.placeholder.yFields': 'Select y-axis fields:',
  'block.group.item.view.label': 'Select the data view:',
  'block.group.item.form.label': 'Choose the Table:',
  'block.group.item.type.label':
    'What type of data you want to display on the item?',
  'block.type.fields.all': 'All fields of form',
  'block.type.fields.specific': 'Specific fields',
  'block.type.group': 'Sub Menu',
  'block.type.link': 'Custom Link',
  'block.type.viewData': 'Block',
  'block.type.label': 'Select the item type',
  'block.menu.create': '+ Add menu',
  'block.menu.create.title': 'New menu',
  'block.type.kanban.title': 'Board',
  'block.type.kanban.columnField': 'Columns group by',
  'block.type.kanban.featureImage': 'Image of card',
  'block.type.kanban.cardTitle': 'Title of card',
  'block.type.kanban.cardFields': 'Fields to display on card',
  'block.type.kanban.mode': 'Access type',
  'block.type.kanban.view': 'View',
  'block.access.label': 'Visibility',
  'block.access.public': 'public',
  'block.access.logoutOnly': 'Non Logged in users',
  'block.access.loginOnly': 'Logged in users',
  'block.access.roleBase': 'Specific user access levels',
  'block.access.team.all': 'All team members',
  'block.access.team.roleBase': 'Specific team access levels',
  'block.access.roleBase.label': 'Select users roles',
  'block.access.team.label': 'Select team access level',
  'block.access.team.role.admin': 'Admin',
  'block.access.team.role.owner': 'Owner',
  'block.access.team.role.member': 'Team member',
  'block.access.team.role.guest': 'Guest',
  'block.edit.base': 'Edit base',
  'block.settings': 'Settings',
  'block.save': 'save',
  'report.chart.type.label': 'Select the chart type:',
  'report.revert': 'Reverted chart',
  'chart.type.bar': 'Bar chart',
  'chart.type.pie': 'Pie chart',
  'chart.type.line': 'Line chart',
  'block.type.data.advanceChart': 'Advanced chart {description}',
  'block.type.data.chart': 'Chart',
  'block.type.data.table': 'Table',
  'kanban.view.default': 'Kanban',
  'kanban.view.grid': 'Grid {description}',
  'kanban.search.placeholder': 'search the cards...',
  'field.result.likeDislike.title': '(Like/Dislike)',
  'field.result.likeDislike.option.like': 'Like',
  'field.result.likeDislike.option.dislike': 'Dislike',
  'field.result.nps.title': '(Out of 10)',
  'field.result.score.title': '(Out of 100)',
  'field.result.star.title': '(Out of 5)',
  'field.result.matrix.button': 'Show Answer',
  'field.file.sizeLimit': 'Max. file size: {size}KB',
  'field.creatable.create': 'Create "{option}"',

  'table.recordsCount': '{count} records',
  'general.genericDateTitle': 'Date',
  'table.labelDisplayedRows': '{from}-{to} of {count}',
  'pagination.rows per page': 'rows per page',
  'general.genericReferredAddress': 'Referred Address',
  'table.empty': 'Oops! No Data Available...',
  'table.moreThan': 'more than {to}',
  'table.actions': 'actions',
  'table.bulkActions.delete.success': '{count} items deleted successfully.',
  'table.bulkActions.delete.error': 'Oops! Something went wrong...',
  'table.row.add': 'Add Row',
  'filters.submit.status.all': 'all',
  'filters.submit.status.complete': 'complete',
  'filters.submit.status.inComplete': 'partial (auto-save)',
  'filters.submit.status': 'Rows status',
  'filters.condition.label': 'Condition',
  'filters.operator.label': 'Operator',
  'filters.operand.label': 'Value',
  'filters.chip.label': '{field} {condition}: {value}',
  'sortBy.label': 'Sort By',
  'sortBy.asc': 'Asc',
  'sortBy.desc': 'Desc',
  'sortBy.chip.label': 'Sort By: {field} - {direction}',
  'filters.conditions.exact': 'is',
  'filters.conditions.iexact': 'is (case insensitive)',
  'filters.conditions.contains': 'contains',
  'filters.conditions.icontains': 'contains (case insensitive)',
  'filters.conditions.lt': 'less than',
  'filters.conditions.lte': 'less than or equal to',
  'filters.conditions.gt': 'greater than',
  'filters.conditions.gte': 'greater than or equal to',
  'filters.time.gte': 'From date',
  'filters.time.lte': 'To date',
  'filters.search': 'search',
  'filters.field.hidden': '{field}',
  'filters.field.hidden.title': 'Hide columns',
  'filters.date.title': 'Date Filter',
  'filters.tags': 'Tag is',
  filter: 'Filter',
  'pagination.first page': 'first page',
  'pagination.last page': 'last page',
  'pagination.previous page': 'previous page',
  'pagination.next page': 'next page',
  'date.from': 'From',
  'date.to': 'To',
  'date.clear': 'Clear',
  save: 'Save changes',
  saveAs: 'Save as new table',
  'filter.time.title': 'Date range',
  'invitation.massage.error': 'Something went wrong...',
  'form.location.viewOnGoogleMaps': 'View On the Map',
  'form.validation.regex': 'The value does not match the pattern',
  'form.validation.required': 'This field is required',
  'form.validation.required.matrix':
    'Answering to all the questions of this field is required ',
  'form.validation.email': 'Enter valid email',
  'form.validation.maxLength':
    'the answer should be less than {maxLength} characters',
  'form.validation.minValue': 'the answer should be more than {minValue}',
  'form.validation.maxValue': 'the answer should be less than {maxValue}',
  'form.validation.url':
    'The URL should contain http or https, please enter the valid url',
  'form.validation.number': 'Enter only number for this field',
  'form.validation.between': 'The time should be between {min} and {max}',
  'form.validation.to': 'The time should be less than {max}',
  'form.validation.from': 'The time should be more than {min}',
  'form.validation.date.fromTo': '{desc}(Date between {min} and {max})',
  'form.validation.date.from': '{desc}(Date from {min})',
  'form.validation.date.to': '{desc}(Date to {max})',
  'form.validation.phone.mobile': 'Enter valid mobile number',
  'form.validation.phone.landline': 'Enter valid phone number',
  'form.validation.product.minValue':
    'the quantity should be more than {minValue}',
  'form.validation.product.maxValue':
    'the quantity should be less than {maxValue}',
  'form.validation.maxDigit':
    'the answer should be less than {maxLength} digits',
  'form.validation.invalidData': 'Invalid Data',
  'form.signature.notSigned': 'Not Signed!',
  'a11y.ariaLabel.button': '{label} Button',
  'form.response.tags.empty': 'No tags found, type to create a new one',
  'form.response.tags.title': 'Tags',
  'form.response.tags.create.success': 'Tag created successfully!',
  'form.response.tags.delete.success': 'Tag deleted successfully!',
  'form.response.tags.update.success': 'Tags updated successfully!',
  'form.response.tags.update.error': 'Could not update the row tags!',
  'form.fields.choices.typeToSearch': 'Type to Search Options...',
  'form.fields.choices.loading': 'Searching Options...',
  'form.fields.choices.noOptionsAvailable': 'No Options Found',
  'form.fields.dropDown.placeholder': 'Click to select',
  'form.submitResults.createRow.successMessage': 'New row created successfully',
  'form.field.product.quantity': 'quantity',
  'form.options': 'form options',
  'kanban.filter.title': 'Filters',
  'kanban.card.new': 'Add new card',
  'kanban.settings.customizing': 'Customize cards',
  'kanban.settings.groupBy': 'Group by',
  'kanban.column.card.count': 'Number of cards',
  'table.actions.row.create': 'New Row',

  'response.copyUrl': 'Copy share URL',
  'response.edit.successMessage': 'Changes saved successfully',
  'general.genericErrorMessage': 'Something went wrong...',
  'general.error.fixFormErrors': 'Fix the form errors, please!',
  'general.reset': 'reset all changes',
  'general.downloadFile': 'Download File',
  'general.unsavedChanges': 'Unsaved Changes',

  'charts.avgRate': 'Avg Rate: {avg}',
  'charts.totalAnswer': 'total responses: {total}',
  'charts.npsScore': 'NPS Score: {score}',

  'grid.column.number': 'Number',
  'grid.column.columns': 'Columns',

  'summary.edit': 'edit form',
  'summary.view': 'View form',
  'summary.share': 'share',
  'summary.chart': 'chart',
  'summary.response': 'responses',
  'summary.integration': 'Integration & Webhooks',
  'summary.presentation': 'Presentation',

  'validation.slug': 'Enter valid address',
  'validation.domain':
    'Domain only contains numbers, letters, underscore, dot and underline with no space',
}
