import Box from '@mui/material/Box'
import LinearProgress from '@mui/material/LinearProgress'

const BarSpinner = ({ style = {} }) => {
  return (
    <Box
      sx={{
        padding: 'calc(50vh - 1px) calc(50% - 65px)',
        width: '100%',
        position: 'fixed',
        zIndex: 9999,
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        ...style,
      }}
    >
      <LinearProgress sx={{ height: 3, borderRadius: 3 }} color="primary" />
    </Box>
  )
}
export default BarSpinner
