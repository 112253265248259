import FieldContainer from './container'
import InputBase from '@mui/material/InputBase'

const TextField = ({ errors, inputProps, sx = {} }) => {
  return (
    <FieldContainer errors={errors} name={inputProps.name}>
      <InputBase
        {...inputProps}
        sx={{ width: '100%', '&.Mui-error': { color: 'error.main' }, ...sx }}
        error={Boolean(errors[inputProps.name])}
      />
    </FieldContainer>
  )
}

export default TextField
