import React from 'react'
import PropTypes from 'prop-types'
import MuiInputLabel from '@mui/material/InputLabel'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import FormHelperText from '@mui/material/FormHelperText'
import { InputErrors } from '../_InputErrors'
import Radio from './Radio'
import Box from '@mui/material/Box'

const RadioButtonsGroup = React.forwardRef(
  (
    {
      name,
      label,
      description,
      readOnly,
      errors,
      showLabel,
      separateLabel,
      inputProps: muiInputProps,
      type,
      options,
      defaultValue,
      value: controlledValue,
      onChange: handleChange,
      row,
      size,
      fullWidth,
      formControlLabelProps,
      ...props
    },
    ref,
  ) => {
    const hasError = errors?.length > 0

    const showDefaultMuiLabel = showLabel && !separateLabel
    const showSeparateLabel = showLabel && separateLabel

    const isControlledInput = React.useMemo(
      () => typeof handleChange === 'function',
      [handleChange],
    )

    const [value, setValue] = React.useState(defaultValue)

    const currentValue = isControlledInput ? controlledValue : value

    const _onChange = event => {
      const newValue = event.target.value

      if (isControlledInput) {
        handleChange(newValue)
      } else {
        setValue(newValue)
      }
    }

    return (
      <Box
        sx={{
          display: 'grid',
          gridGap: 0.5,
          alignItems: 'start',
          justifyItems: 'start',
          alignContent: 'stretch',
          justifyContent: 'normal',
          width: showSeparateLabel && fullWidth ? '100%' : 'auto',
        }}
      >
        {showSeparateLabel && (
          <MuiInputLabel
            sx={{
              fontSize: '1.2rem',
              color: 'primary.lighten',
              mb: 1.5,
            }}
            htmlFor={name}
            error={hasError}
          >
            {label}
          </MuiInputLabel>
        )}
        <FormControl
          component="fieldset"
          error={hasError}
          disabled={props.disabled}
        >
          {showDefaultMuiLabel && (
            <FormLabel component="legend">{label}</FormLabel>
          )}
          <RadioGroup
            ref={ref}
            row={row}
            name={name}
            aria-label={label}
            value={currentValue}
            onChange={_onChange}
          >
            {options.map(({ value: optionValue, label: optionLabel }) => (
              <FormControlLabel
                sx={{ ml: -1.5, mr: 2 }}
                key={optionValue}
                value={optionValue}
                control={<Radio size={size} />}
                label={optionLabel}
                {...formControlLabelProps}
              />
            ))}
          </RadioGroup>
          {description && <FormHelperText>{description}</FormHelperText>}
        </FormControl>
        {hasError && <InputErrors errors={errors} />}
      </Box>
    )
  },
)

RadioButtonsGroup.displayName = 'FormalooRadioButtonsGroup'

RadioButtonsGroup.defaultProps = {
  showLabel: true,
  separateLabel: false,
  fullWidth: true,
  row: true,
  defaultValue: null,
  value: null,
  formControlLabelProps: {},
  name: '',
  label: '',
  options: [],
}

RadioButtonsGroup.propTypes = {
  name: PropTypes.string.isRequired,
  // label: PropTypes.string.isRequired,
  separateLabel: PropTypes.bool,
  showLabel: PropTypes.bool,
  description: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    PropTypes.elementType,
  ]),
  value: PropTypes.any,
  defaultValue: PropTypes.any,
  onChange: PropTypes.func,
  fullWidth: PropTypes.bool,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  errors: PropTypes.arrayOf(PropTypes.string),
  row: PropTypes.bool,
  /**
   * { label, value }
   */
  options: PropTypes.array.isRequired,
  formControlLabelProps: PropTypes.any,
}

export default RadioButtonsGroup
