import { useIntl } from 'react-intl'
import { setErrors } from 'utils/error'
import { useBoard } from 'context/board'
import { useBlockFn } from 'hooks/block'
import Button from 'stories/button/Button'
import { LOCATIONS } from 'utils/board/locations'
import { BLOCK_TYPES } from 'utils/board/block-types'

export default function AddMenuButton({ addToList, disabled }) {
  const { formatMessage } = useIntl()
  const { boardInfo } = useBoard()
  const { createBlock, isLoading: isCreating } = useBlockFn()

  if (disabled) return null

  const addBlock = () => {
    createBlock(boardInfo.slug, BLOCK_TYPES.menu, {
      title: formatMessage({ id: 'block.menu.create.title' }),
      location: LOCATIONS.left,
    })
      .then(addToList)
      .catch(error => setErrors(error))
  }

  return (
    <Button
      onClick={addBlock}
      variant="text"
      color="primary"
      disabled={isCreating}
      sx={{
        width: '100%',
        fontSize: '0.8rem',
        height: 40,
        mb: -3,
        '&:hover': { backgroundColor: 'inherit' },
      }}
    >
      {formatMessage({ id: 'block.menu.create' })}
    </Button>
  )
}
