import MuiPhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

import PropTypes from 'prop-types'
import React from 'react'

import FormHelperText from '@mui/material/FormHelperText'
import MuiInputLabel from '@mui/material/InputLabel'
// import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'

import { InputErrors } from '../_InputErrors'

// const Input = styled(MuiPhoneInput, {
//   shouldForwardProp: prop => ['fullWidth', 'hasError'].includes(prop),
// })(({ fullWidth, hasError, theme }) => ({
//   '.containerClass': {
//     flip: false,
//     direction: 'ltr',
//     fontFamily: 'inherit',
//     fontSize: 'inherit',
//     display: 'flex',
//     alignItems: 'center',
//   },
//   '.inputClass': {
//     '&.form-control': {
//       width: '100%',
//       height: theme.spacing(6),
//       borderColor: hasError
//         ? `${theme.palette.error.main}!important`
//         : 'initial',
//     },
//   },
//   '.buttonClass': {
//     borderColor: hasError ? `${theme.palette.error.main}!important` : 'initial',
//   },
//   dropdownClass: {},
//   '.searchClass': {
//     width: '100%',
//     '&.search': {
//       padding: `${theme.spacing(1)}px!important`,
//     },
//     '& > .search-box': {
//       margin: `${theme.spacing(0.5, 0)}!important`,
//       padding: `${theme.spacing(0.5)}px!important`,
//       width: 'inherit',
//     },
//   },
// }))

const PhoneInput = React.forwardRef(
  (
    {
      name,
      label,
      description,
      autoFill,
      readOnly,
      errors,
      showLabel,
      separateLabel,
      inputProps: muiInputProps,
      type,
      defaultValue,
      value: controlledValue,
      onChange: handleChange,
      fullWidth,
      ...props
    },
    forwardedRef,
  ) => {
    const showDefaultMuiLabel = showLabel && !separateLabel
    const showSeparateLabel = showLabel && separateLabel
    const autoComplete = React.useMemo(
      () => (Boolean(autoFill) ? 'on' : 'off'),
      [autoFill],
    )

    const isControlledInput = React.useMemo(
      () => typeof handleChange === 'function',
      [handleChange],
    )

    const customStyleClassNames = React.useMemo(
      () => ({
        containerClass: 'containerClass',
        inputClass: 'inputClass',
        buttonClass: 'buttonClass',
        dropdownClass: 'dropdownClass',
        searchClass: 'searchClass',
      }),
      [],
    )

    const [value, setValue] = React.useState(defaultValue)

    const inputProps = {
      id: name,
      name,
      readOnly,
      'aria-label': label,
      autoComplete,
      // ref: (e) => {
      //   if (e && forwardedRef.current) {
      //     forwardedRef.current.numberInputRef = e
      //     forwardedRef(e)
      //   }
      // },
      ...muiInputProps,
    }

    /**
     *
     *
     * @param {string} value
     * @param {{ name, dialCode, countryCode (iso2) }} country
     * @param {object} event
     * @param {string} formattedValue
     */
    const _onChange = (value, country, event, formattedValue) => {
      const newValue = value ? `+${value}` : ''

      if (isControlledInput) {
        handleChange(newValue)
      } else {
        setValue(newValue)
      }
    }

    const currentValue = isControlledInput ? controlledValue : value

    const hasError = errors?.length > 0

    return (
      <Box
        sx={{
          display: 'grid',
          gridGap: 0.5,
          alignItems: 'start',
          justifyItems: 'start',
          alignContent: 'stretch',
          justifyContent: 'normal',
          width: fullWidth || showSeparateLabel ? '100%' : 'auto',
          '& .react-tel-input .form-control': {
            minHeight: 50,
            borderRadius: 2,
          },
        }}
      >
        {showSeparateLabel && (
          <MuiInputLabel
            htmlFor={name}
            error={hasError}
            sx={{
              fontSize: '1.2rem',
              color: 'primary.lighten',
              mb: 1.5,
            }}
          >
            {label}
          </MuiInputLabel>
        )}

        <MuiPhoneInput
          country={props.defaultCountry}
          value={currentValue}
          onChange={_onChange}
          inputProps={inputProps}
          specialLabel={showDefaultMuiLabel ? label : undefined}
          enableSearch
          disableSearchIcon
          enableTerritories
          autocompleteSearch={autoFill}
          {...customStyleClassNames}
          {...props}
        />

        {description && <FormHelperText>{description}</FormHelperText>}

        {hasError && <InputErrors errors={errors} />}
      </Box>
    )
  },
)

PhoneInput.displayName = 'FormalooPhoneInput'

PhoneInput.defaultProps = {
  showLabel: true,
  autoFill: true,
  size: 'small',
  separateLabel: true,
  fullWidth: true,
  defaultCountry: 'us',
}

PhoneInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  separateLabel: PropTypes.bool,
  showLabel: PropTypes.bool,
  description: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    PropTypes.elementType,
  ]),
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func,
  fullWidth: PropTypes.bool,
  autoFill: PropTypes.bool,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  size: PropTypes.oneOf(['small', 'normal']),
  errors: PropTypes.arrayOf(PropTypes.string),
  defaultCountry: PropTypes.string,
}

export default PhoneInput
