import Box from '@mui/material/Box'
import BarSpinner from 'stories/loading/spinner/BarSpinner'
import Typography from '@mui/material/Typography'
import { useBoard } from 'context/board'
import { useState, useEffect } from 'react'
import { useInterval } from '../../../hooks/utility/useInterval'
import { useBoardFn } from 'hooks/board'
import { setErrors } from 'utils/error'
import { formatNumber } from 'utils/formatter/digit'
import equals from 'ramda/src/equals'

const INTERVAL_TIME = 5000 // 5 seconds
const STATUS = {
  IN_PROGRESS: 'in_progress',
  COMPLETE: 'completed',
}

const ProgressLabel = ({ progress = 100, sx = {} }) => {
  const [progressLabel, setProgress] = useState(0)

  const updateLabel = () => {
    if (progress > progressLabel) {
      setProgress(prevProgress =>
        prevProgress >= progress ? progress : prevProgress + 1,
      )
    }
  }

  useInterval(() => {
    updateLabel()
  }, 130)

  return <Typography sx={sx}>{`${formatNumber(progressLabel)}%`}</Typography>
}

export default function InitializingProgress() {
  const { boardInfo, setBoardData } = useBoard()
  const { fetchBoard } = useBoardFn()

  const [initializingStatus, setStatus] = useState(boardInfo?.copy_info || {})

  useEffect(() => {
    if (!equals(boardInfo?.copy_info, initializingStatus)) {
      setStatus(boardInfo?.copy_info)
    }
  }, [boardInfo?.copy_info])

  const checkProgress = () => {
    if (initializingStatus?.status === STATUS.IN_PROGRESS) {
      fetchBoard()
        .then(board => {
          setStatus(board?.copy_info)
          board?.copy_info?.status === STATUS.COMPLETE && setBoardData(board)
        })
        .catch(err => setErrors(err))
      return
    }
  }

  useInterval(() => {
    checkProgress()
  }, INTERVAL_TIME)

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        minHeight: '90vh',
        flexDirection: 'column',
      }}
    >
      {initializingStatus?.progress <= 100 && (
        <ProgressLabel sx={{ mb: 2 }} progress={initializingStatus?.progress} />
      )}

      <BarSpinner
        style={{
          padding: '0',
          position: 'static',
          width: '150px',
        }}
      />
    </Box>
  )
}
