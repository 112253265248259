import SignatureInput from 'stories/FormInputs/Signature/Signature'

import { typeUtils } from 'types'
import {
  getFormItemErrors,
  getFormItemLabel,
  getGenericFormItemDefaultValue as getDefaultValue,
} from './_propGetters'

const Component = SignatureInput

const getProps = fieldProps => ({
  ...fieldProps,
  name: fieldProps.slug,
  label: getFormItemLabel(fieldProps),
  // input props
  separateLabel: true,
})

const getControlledProps = (fieldProps, controllerProps, defaultValue) => ({
  ...controllerProps.field,
  type: typeUtils.getMostSpecificType(fieldProps),
  label: getFormItemLabel(fieldProps),
  // input props
  separateLabel: true,
  errors: getFormItemErrors(controllerProps),
  // TODO: add onChange to handle upload file to storage service
  //  and set received slug as filed value
  // refer to upload file docs
  onChange: files => {
    controllerProps.field.onChange(files?.length > 0 ? files[0] : files)
  },
})

const getValidationRules = (fieldProps, options) => ({
  // getValidationRules = (fieldProps, options)
})

export const signatureInput = {
  Component,
  getProps,
  getControlledProps,
  getValidationRules,
  getDefaultValue,
}
