import { config } from './utils'
import { HTTP, httpClient } from './utils/http'
import OAuthServer from 'app-settings/oauth.settings'

export const auth = () => ({
  getToken: () =>
    httpClient.post(
      OAuthServer.icasAuthorizationUrl,
      null,
      config({
        accessToken: JSON.parse(localStorage.getItem('session') ?? null),
        apiKey: OAuthServer.apiKey,
        contentType: HTTP.ContentTypes.FormUrlEncoded,
      }),
    ),
})
