import { createContext, useContext, useState } from 'react'
import { useBoard } from './board'
import { useBlockFn } from 'hooks/block'
import { setErrors } from 'utils/error'

const BoardViewContext = createContext()

const BoardViewProvider = ({ children }) => {
  const [currentBlock, setCurrentBlock] = useState(null)
  const { boardInfo } = useBoard()
  const { getBlock } = useBlockFn()

  const setBlockToViewOnBoard = (blockSlug, parent = {}) => {
    /* 
      "parent" input is a temporary solution to save parent slug or append function in current block.
      It should be replaced by better solution like global state managements (e.g redux) 
     */
    if (!boardInfo) return null
    if (!blockSlug) {
      setCurrentBlock(null)
      return
    }
    // reset block view & activate the loading by undefined
    setCurrentBlock(undefined)
    // set new block to be displayed
    getBlock(boardInfo.slug, blockSlug, boardInfo.share_address)
      .then(block => setCurrentBlock({ ...block, parent }))
      .catch(error => setErrors(error))
  }

  const setStaticPageToViewOnBoard = thePage => {
    setCurrentBlock(thePage)
  }

  return (
    <BoardViewContext.Provider
      value={{
        currentBlock,
        setCurrentBlock,
        setBlockToViewOnBoard,
        setStaticPageToViewOnBoard,
      }}
    >
      {children}
    </BoardViewContext.Provider>
  )
}

function useBoardView() {
  const context = useContext(BoardViewContext)
  if (context === undefined) {
    throw new Error(`useBoardView must be used within a BoardViewProvider`)
  }
  return context
}

export { BoardViewProvider, useBoardView }
