import React from 'react'
import PropTypes from 'prop-types'
import MatrixTable from './_MatrixTable'
import MuiInputLabel from '@mui/material/InputLabel'
import FormHelperText from '@mui/material/FormHelperText'
import { InputErrors } from '../_InputErrors'
import DefaultOptionInput from './_DefaultOptionInput'

const MatrixInput = React.forwardRef(
  (
    {
      name,
      label,
      description,
      value: controlledValue,
      defaultValue,
      onChange: handleChange,
      questions,
      options,
      size,
      showLabel,
      errors,
      isAdvanced,
      ...props
    },
    ref,
  ) => {
    const hasError = errors?.length > 0

    const isControlledInput = React.useMemo(
      () => typeof handleChange === 'function',
      [handleChange],
    )

    const _options = React.useMemo(
      () =>
        options.map(({ render: customRender, ...opt }) => ({
          ...opt,
          render:
            typeof customRender === 'function'
              ? customRender
              : DefaultOptionInput,
        })),
      [options],
    )

    const _defaultValue = React.useMemo(
      () => ({
        ...questions.map(q => ({
          [q.slug]: isAdvanced
            ? {
                ..._options
                  .map(opt => ({
                    [opt.slug]:
                      defaultValue && defaultValue[q.slug]
                        ? defaultValue[q.slug][opt.slug]
                        : '',
                  }))
                  .reduce((prev, curr) => ({ ...prev, ...curr })),
              }
            : '',
        })),
      }),
      [defaultValue, questions, isAdvanced, _options],
    )

    const [value, setValue] = React.useState(_defaultValue)

    const currentValue = isControlledInput ? controlledValue : value

    const _onChange = (questionName, value) => {
      const newValue = currentValue
        ? { ...currentValue, [questionName]: value }
        : { [questionName]: value }

      if (isControlledInput) {
        handleChange(newValue)
      } else {
        setValue(newValue)
      }
    }

    return (
      <div
        sx={{
          display: 'grid',
          gridGap: 0.5,
          alignItems: 'start',
          justifyItems: 'start',
          alignContent: 'stretch',
          justifyContent: 'normal',
          width: props.fullWidth ? '100%' : 'auto',
        }}
      >
        {showLabel && (
          <MuiInputLabel
            htmlFor={name}
            error={hasError}
            sx={{
              fontSize: '1.2rem',
              color: 'primary.lighten',
              mb: 1.5,
            }}
          >
            {label}
          </MuiInputLabel>
        )}

        {description && <FormHelperText>{description}</FormHelperText>}

        <MatrixTable
          ref={ref}
          name={name}
          questions={questions}
          options={_options}
          onChange={_onChange}
          size={size}
          label={label}
          value={currentValue}
          {...props}
        />

        {hasError && <InputErrors errors={errors} />}
      </div>
    )
  },
)

MatrixInput.defaultProps = {
  showLabel: true,
  size: 'small',
  fullWidth: true,
  isAdvanced: false,
}

MatrixInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  questions: PropTypes.array.isRequired,
  options: PropTypes.array.isRequired,
  showLabel: PropTypes.bool,
  description: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    PropTypes.elementType,
  ]),
  value: PropTypes.any,
  defaultValue: PropTypes.any,
  onChange: PropTypes.func,
  fullWidth: PropTypes.bool,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'normal']),
  errors: PropTypes.arrayOf(PropTypes.string),
  isAdvanced: PropTypes.bool,
}

export default MatrixInput
