import React from 'react'

import rtlPlugin from 'stylis-plugin-rtl'
import { CacheProvider } from '@emotion/react'
import createCache from '@emotion/cache'

import MuiThemeProvider from '@mui/material/styles/ThemeProvider'
import ScopedCssBaseline from '@mui/material/ScopedCssBaseline'
import GlobalStyles from '@mui/material/GlobalStyles'

import { ThemeProvider } from '@emotion/react'

import { Toaster } from 'react-hot-toast'

import theme from 'style/theme'
import { defaultLocale } from 'app-settings'
import { toasterConfig } from 'utils/toaster.config'
import { themeLocale } from '../style/fonts'

// TODO: read from intl context
const defaultThemeDirection = ['fa', 'ar'].includes(defaultLocale)
  ? 'rtl'
  : 'ltr'

const ThemeContext = React.createContext()

// Create rtl cache
const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [rtlPlugin],
})

function RTL(props) {
  return <CacheProvider value={cacheRtl}>{props.children}</CacheProvider>
}

class ThemeProviderClass extends React.Component {
  constructor(...args) {
    super(...args)

    this.switchThemeDirection = themeDirection => {
      this.setState({ themeDirection }, () =>
        document.body.setAttribute('dir', themeDirection),
      )
    }

    document.body.setAttribute('dir', defaultThemeDirection)
    // pass everything in state to avoid creating object inside render method (like explained in the documentation)
    this.state = {
      themeDirection: defaultThemeDirection,
      switchThemeDirection: this.switchThemeDirection,
    }
  }

  render() {
    const { children } = this.props
    const { themeDirection } = this.state
    return (
      <ThemeContext.Provider value={this.state}>
        <MuiThemeProvider theme={theme(themeDirection, defaultLocale)}>
          <ThemeProvider theme={theme(themeDirection, defaultLocale)}>
            {/* applying the app's style and theme only to child components */}
            <ScopedCssBaseline sx={themeLocale[defaultLocale].font}>
              {/* ScopedCssBaseline doesn't add reset styles like box-sizing to elements */}
              <GlobalStyles
                styles={{
                  '& *': { boxSizing: 'border-box !important' },
                  body: {
                    overflowX: 'clip',
                    maxWidth: '100%',
                    margin: '0',
                  },
                }}
              />

              {themeDirection === 'rtl' ? <RTL>{children}</RTL> : children}
              <Toaster {...toasterConfig} />
            </ScopedCssBaseline>
          </ThemeProvider>
        </MuiThemeProvider>
      </ThemeContext.Provider>
    )
  }
}

export { ThemeProviderClass as ThemeProvider, ThemeContext }
