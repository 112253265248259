export default function CopyIcon({ color = '#28293D', ...props }) {
  return (
    <svg
      width="15"
      height="18"
      viewBox="0 0 15 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.16667 17.3332C1.70833 17.3332 1.31583 17.1701 0.989167 16.844C0.663055 16.5173 0.5 16.1248 0.5 15.6665H2.16667V17.3332ZM0.5 14.4165V12.7498H2.16667V14.4165H0.5ZM0.5 11.4998V9.83317H2.16667V11.4998H0.5ZM0.5 8.58317V6.9165H2.16667V8.58317H0.5ZM0.5 5.6665C0.5 5.20817 0.663055 4.81595 0.989167 4.48984C1.31583 4.16317 1.70833 3.99984 2.16667 3.99984V5.6665H0.5ZM3.41667 17.3332V15.6665H5.08333V17.3332H3.41667ZM5.5 13.9998C5.04167 13.9998 4.64944 13.8368 4.32333 13.5107C3.99667 13.184 3.83333 12.7915 3.83333 12.3332V2.33317C3.83333 1.87484 3.99667 1.48234 4.32333 1.15567C4.64944 0.829559 5.04167 0.666504 5.5 0.666504H13C13.4583 0.666504 13.8508 0.829559 14.1775 1.15567C14.5036 1.48234 14.6667 1.87484 14.6667 2.33317V12.3332C14.6667 12.7915 14.5036 13.184 14.1775 13.5107C13.8508 13.8368 13.4583 13.9998 13 13.9998H5.5ZM5.5 12.3332H13V2.33317H5.5V12.3332ZM6.33333 17.3332V15.6665H8V17.3332H6.33333ZM9.25 17.3332V15.6665H10.9167C10.9167 16.1248 10.7536 16.5173 10.4275 16.844C10.1008 17.1701 9.70833 17.3332 9.25 17.3332Z"
        fill={color}
      />
    </svg>
  )
}
