import { createTheme } from '@mui/material/styles'
import { themeLocale } from './fonts'

const colors = {
  dark0: '#1C1C28',
  dark1: '#28293D',
  dark2: '#555770',
  dark3: '#8F90A6',
  dark4: '#C7C9D9',

  light0: '#E4E4EB',
  light1: '#EBEBF0',
  light2: '#F2F2F5',
  light3: '#FAFAFC',
  light4: '#FFFFFF',

  tertiary: {
    main: '#73DFE7',
    dark: '#00CFDE',
    light: '#A9EFF2',
  },
  gradient:
    'linear-gradient(270deg, rgba(75,73,255,1) 19%, rgba(153,82,162,1) 55%, rgba(249,92,48,1) 100%)',
}

const fontFamilyGenerator = locale => {
  if (locale === 'en')
    return [
      '-apple-system',
      'BlinkMacSystemFont',
      themeLocale[locale].name,
    ].join(',')
  return themeLocale[locale].name
}
// A custom theme for this app
const theme = (direction, locale) =>
  createTheme(
    {
      direction,
      palette: {
        primary: {
          main: '#8F6EFD',
          light: '#B9A4FF',
          dark: '#774FFF',
        },
        secondary: {
          main: '#F95C30',
          dark: '#FF4815',
          light: '#FF815D',
        },
        error: {
          main: '#FF1E1E',
        },
        background: {
          default: '#E5E5E5',
        },
        common: colors,
      },
      typography: {
        fontFamily: fontFamilyGenerator(locale),
        fontSize: 14,
        // Tell Material-UI what's the font-size on the html element is.
        // htmlFontSize: 16,
      },
      components: {
        MuiCssBaseline: {
          styleOverrides: themeLocale[locale].font,
        },
        MuiScopedCssBaseline: {
          styleOverrides: {
            root: {
              boxSizing: 'border-box',
              backgroundColor: 'white',
            },
          },
        },
        MuiInputBase: {
          styleOverrides: {
            input: {
              minHeight: 'auto',
              boxSizing: 'content-box !important',
            },
          },
        },
        MuiOutlinedInput: {
          root: {
            borderRadius: 8,
          },
        },
        MuiTypography: {
          styleOverrides: {
            root: {
              '&:first-letter': {
                textTransform: 'capitalize',
              },
            },
          },
        },
        MuiButton: {
          disableElevation: true,
          disableFocusRipple: true,
          disableTouchRipple: true,
          styleOverrides: {
            root: {
              textTransform: 'capitalize',
            },
          },
        },
        MuiIconButton: {
          styleOverrides: {
            root: {
              '&:hover': {
                backgroundColor: 'none',
              },
            },
          },
        },
      },
    },
    themeLocale[locale],
  )

export default theme
