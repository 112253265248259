import { board } from 'api/board'
import { getBoardMetaData } from 'utils/board/meta-data'
import { useState } from 'react'

export const useBoardFn = () => {
  const { boardPublicSlug, isPrivate, formSlug, boardSlug } = getBoardMetaData()
  const [isLoading, setLoading] = useState(false)

  const fetchBoard = async () => {
    const getBoard = () =>
      isPrivate
        ? board().getOne(formSlug, boardSlug)
        : board().getShared(boardPublicSlug)

    try {
      const {
        data: { data, errors },
      } = await getBoard()
      if (data && data.board) return Promise.resolve(data.board)
      return Promise.reject(errors)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const updateBoard = async (formData, dataOptions = {}) => {
    setLoading(true)
    try {
      const {
        data: { data, errors },
      } = await board().updateOne(formSlug, boardSlug, formData, dataOptions)
      setLoading(false)
      if (data && data.board) return Promise.resolve(data.board)
      return Promise.reject(errors)
    } catch (error) {
      setLoading(false)
      return Promise.reject(error)
    }
  }

  return { fetchBoard, updateBoard, isLoading, isPrivate }
}
