import isEmpty from 'lodash/isEmpty'

export const omit = blacklisted => obj =>
  Object.entries(obj)
    .filter(([key]) => !blacklisted.includes(key))
    .reduce((obj, [key, val]) => Object.assign(obj, { [key]: val }), {})

export const filterObj = whiteList => theObj =>
  Object.keys(theObj)
    .filter(key => whiteList.includes(key))
    .reduce((obj, key) => {
      obj[key] = theObj[key]
      return obj
    }, {})

export const removeBlankProperty = (obj = {}, whiteList = []) => {
  for (var propName in obj) {
    if (
      (isEmpty(obj[propName]) || !obj[propName]) &&
      !whiteList.includes(propName)
    ) {
      delete obj[propName]
    }
  }
  return obj
}
