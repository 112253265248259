import LeftSidebar from './left-sidebar'
import BoardView from './view'
import Box from '@mui/material/Box'
import { useBoard } from 'context/board'
import { ResponsiveViewProvider } from 'context/responsive-view'
import Fade from '@mui/material/Fade'
import { APP_CLASS_PREFIX } from 'utils/fix-classnames'
import AuthBox from './view/AuthBox'
import InitializingProgress from './view/InitializingProgress'

const PrimaryBoard = () => {
  const { boardInfo } = useBoard()

  if (!boardInfo || boardInfo.copy_info?.status === 'in_progress')
    return <InitializingProgress />

  return (
    <Fade in={true}>
      <Box display="flex" height="100vh" className={`${APP_CLASS_PREFIX}_root`}>
        <ResponsiveViewProvider>
          <LeftSidebar />
          <BoardView />
          <AuthBox />
        </ResponsiveViewProvider>
      </Box>
    </Fade>
  )
}

export default PrimaryBoard
