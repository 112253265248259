import { useMemo } from 'react'

import urlSettings from 'app-settings/url.settings'

const { formViewDomain, formViewMainDomain } = urlSettings

export const useFormUrl = ({
  owner_custom_domain: ownerCustomDomain,
  custom_domain: customDomain,
  subdomain: subDomain,
  address,
  full_form_address: absoluteUrl,
} = {}) => {
  const url = useMemo(() => {
    if (absoluteUrl) return absoluteUrl

    if (customDomain) return customDomain

    if (ownerCustomDomain) return `https://${ownerCustomDomain}/${address}`

    if (subDomain)
      return `https://${subDomain}.${formViewMainDomain.replace(
        'https://',
        '',
      )}/${address}`

    return `${formViewDomain}/${address}`
  }, [ownerCustomDomain, customDomain, subDomain, address, absoluteUrl])

  return url
}
