import * as React from 'react'
import Avatar from '@mui/material/Avatar'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Divider from '@mui/material/Divider'
import Button from '@mui/material/Button'
import SettingsIcon from 'stories/icons/Settings'
import LogoutIcon from 'stories/icons/Logout'
import CaretDownIcon from 'stories/icons/CaretDown'
import { useAuthContext } from 'context/auth'
import { FormattedMessage } from 'react-intl'
import Typography from '@mui/material/Typography'
import { useBoardView } from 'context/view'
import { UserProfile } from '../page/types'

export default function AccountMenu() {
  const { user, logout } = useAuthContext()
  const { setStaticPageToViewOnBoard } = useBoardView()

  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  return (
    <React.Fragment>
      <Button
        id="demo-customized-button"
        aria-controls={open ? 'account-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        disableElevation
        onClick={handleClick}
        startIcon={<Avatar style={{ width: 24, height: 24 }} />}
        endIcon={<CaretDownIcon style={{ width: 24, height: 24 }} />}
        variant="text"
        color="primary"
        disableTouchRipple
      >
        <Typography
          component="span"
          sx={{ color: 'primary.dark', fontWeight: '500' }}
          variant="subtitle2"
        >
          {user.user?.first_name}
        </Typography>
      </Button>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            backgroundColor: 'background.default',
            // filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            border: '1px solid #D8E0ED',
            /* M3/Elevation Light/1 */

            boxShadow:
              '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
            borderRadius: 2,
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem
          onClick={() => {
            setStaticPageToViewOnBoard(UserProfile)
          }}
        >
          <SettingsIcon />
          <Typography sx={{ ml: 1 }} variant="caption">
            <FormattedMessage id="account" />
          </Typography>
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => {
            logout()
          }}
        >
          <LogoutIcon width="20px" height="20px" />
          <Typography sx={{ ml: 1 }} variant="caption">
            <FormattedMessage id="logout" />
          </Typography>
        </MenuItem>
      </Menu>
    </React.Fragment>
  )
}
