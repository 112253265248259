import React from 'react'
import PropTypes from 'prop-types'

import MuiInputLabel from '@mui/material/InputLabel'
import MuiTextField from '@mui/material/TextField'

import { InputErrors } from '../_InputErrors'
import Box from '@mui/material/Box'

const TextInput = React.forwardRef(
  (
    {
      name,
      label,
      description,
      autoFill,
      readOnly,
      errors,
      showLabel,
      separateLabel,
      inputProps: muiInputProps,
      type,
      sx = {},
      ...props
    },
    ref,
  ) => {
    const showDefaultMuiLabel = showLabel && !separateLabel
    const showSeparateLabel = showLabel && separateLabel
    const autoComplete = React.useMemo(
      () => (Boolean(autoFill) ? 'on' : 'off'),
      [autoFill],
    )

    const inputProps = {
      readOnly,
      'aria-label': label,
      ...muiInputProps,
    }
    const hasError = errors?.length > 0

    return (
      <Box
        sx={{
          display: 'grid',
          gridGap: 0.5,
          alignItems: 'start',
          justifyItems: 'start',
          alignContent: 'stretch',
          justifyContent: 'normal',
          width: props.fullWidth && showSeparateLabel ? '100%' : 'auto',
        }}
      >
        {showSeparateLabel && (
          <MuiInputLabel
            sx={{
              fontSize: '1.2rem',
              color: 'primary.lighten',
              mb: 1.5,
            }}
            htmlFor={name}
            error={hasError}
          >
            {label}
          </MuiInputLabel>
        )}
        <MuiTextField
          inputRef={ref}
          // provide a unique id to make it accessible
          id={name}
          variant="outlined"
          hiddenLabel
          name={name}
          label={showDefaultMuiLabel ? label : undefined}
          error={hasError}
          autoComplete={autoComplete}
          inputProps={inputProps}
          helperText={description}
          sx={{
            '.MuiInputBase-root': { minHeight: 50, borderRadius: 2 },
            ...sx,
          }}
          {...props}
        />
        {hasError && <InputErrors errors={errors} />}
      </Box>
    )
  },
)

TextInput.displayName = 'FormalooTextInput'

TextInput.defaultProps = {
  showLabel: true,
  autoFill: true,
  size: 'small',
  separateLabel: false,
  minRows: 3,
  maxRows: 5,
  fullWidth: true,
}

TextInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  separateLabel: PropTypes.bool,
  showLabel: PropTypes.bool,
  description: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    PropTypes.elementType,
  ]),
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func,
  fullWidth: PropTypes.bool,
  autoFill: PropTypes.bool,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  multiline: PropTypes.bool,
  minRows: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  maxRows: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  placeholder: PropTypes.string,
  size: PropTypes.oneOf(['small', 'normal']),
  errors: PropTypes.arrayOf(PropTypes.string),
}

export default TextInput
