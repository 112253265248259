export default {
  GREETING: 'سلام {name}',
  edit: 'ویرایش',
  done: 'ذخیره ',
  submit: 'ارسال',
  yes: 'بله',
  no: 'خیر',
  delete: 'حذف',
  view: 'نمایش',
  close: 'بستن',
  tags: 'تگ‌ها',
  saveAll: 'ذخیره',
  uncategorized: 'بدون دسته‌بندی',
  untitled: 'بدون عنوان',
  readOnly: 'بدون قابلیت ویرایش',
  editable: 'قابل ویرایش',
  trackingCode: 'کد رهگیری',
  beta: 'آزمایشی',
  open: 'باز کردن',
  saveDraft: 'ذخیره پیش‌نویس',
  draft: 'پیش‌نویس',
  login: 'ورود',
  logout: 'خروج',
  signup: 'ثبت‌نام',
  email: 'ایمیل',
  account: 'حساب‌کاربری',
  madeWith: 'ساخته شده با',
  copied: 'کپی شد',
  'app.temp.copy': 'کپی قالب',
  'app.edit': 'ویرایش اپ',
  'app.settings': 'تنظیمات اپ',
  'app.settings.isTemplate': 'تبدیل اپ به قالب',
  'app.settings.cname': 'دامنه اختصاصی اپ',
  'app.settings.isUniversal': 'استفاده از آدرس یکسان در داخل و خارج فرمالو',
  'app.settings.isPublic': 'فعال‌سازی نمایش عمومی',
  'app.settings.publicAddress': 'آدرس نمایش عمومی',
  'app.settings.saved.success': 'تغییرات با موفقیت ذخیره شد',
  'app.settings.users_settings_enabled': 'فعال‌سازی تنظیمات کاربران {badge}',
  'app.user.profile': 'پروفایل',
  'app.users.button': 'کاربران',
  'app.users.title': 'کاربران',
  'app.users.settings.signup.warning':
    'فرآیند ثبت نام کامل نشده است لطفا جدول کاربری خود را انتخاب یا ایجاد کنید.',
  'app.users.settings.signup': 'ورود و ثبت‌نام',
  'app.users.settings.signup.desc':
    'دسترسی به کاربران برای ورود و ثبت نام برنامه شما',
  'app.users.settings.signup.modal.title': 'جدول کاربران',
  'app.users.settings.signup.modal.desc':
    'یک جدول جدید ایجاد کنید یا یکی از جداول کاربران موجود خود را انتخاب کنید.',
  'app.users.settings.signup.table.create': 'جدول جدید',
  'app.users.settings.signup.table.exiting': 'اتصال به دیگر جداول شما',
  'app.users.settings.login.label': 'ورود',
  'app.users.settings.signup.label': 'ثبت‌نام کاربران جدید',
  'app.users.settings.paidMembership.label': 'ثبت‌نام غیر رایگان',
  'app.users.settings.captchaEnabled.label': 'فعال‌سازی captcha',
  'app.users.settings.otp.label': 'دریافت رمز یکبار مصرف',
  'app.users.settings.save.success': 'تغییرات با موفقیت ذخیره شد',
  'app.users.table.edit': 'ویرایش فرم',
  'app.users.table.change': 'تغییر جدول',

  'form.options': 'موارد فرم',
  'view.public': 'نمایش عمومی',
  'block.group.item.create.title': 'ساخت آیتم جدید',
  'block.group.subGroup.create.title': '+ زیر گروه جدید',
  'block.group.item.create.button': '+ آیتم جدید',
  'block.group.item.view.label': 'نوع نمایش را انتخاب کنید:',
  'block.group.item.type.label': 'چه دیتایی در این آیتم نمایش داده شود؟',
  'block.group.item.form.label': 'فرم مورد نظر را انتخاب کنید',

  'block.type.data': 'نمایش پاسخ‌ها و چارت‌ها',
  'block.type.form': 'نمایش فرم',
  'block.type.data.chart': 'چارت',
  'block.type.data.advanceChart': '{description}  چارت پیشرفته',
  'block.type.data.table': 'جدول',
  'block.type.fields.all': 'همه فیلدهای فرم',
  'block.type.fields.specific': 'فیلدهای انتخاب شده',
  'block.type.group': 'گروه',
  'block.type.link': 'لینک',
  'block.type.viewData': 'داده‌ها',
  'block.type.label': 'نوع آیتم را انتخاب کنید:',
  'block.access.label': 'سطح دسترسی کاربر',
  'block.access.public': 'همه',
  'block.access.logoutOnly': 'کاربرانی که وارد نشده اند',
  'block.access.loginOnly': 'کاربرانی که وارد شده‌اند',
  'block.access.roleBase': 'کابران بر اساس سطح دسترسی',
  'block.access.team.all': 'اعضای تیم',
  'block.access.team.roleBase': 'اعضای تیم براساس سطح دسترسی',
  'block.access.roleBase.label': 'انتخاب سطح کاربر',
  'block.access.team.label': 'انتخاب سطح تیم',
  'block.access.team.role.admin': 'ادمین',
  'block.access.team.role.owner': 'صاحب اپ',
  'block.access.team.role.member': 'عضو تیم',
  'block.access.team.role.guest': 'مهمان',
  'block.menu.create': '+ منو جدید',
  'block.menu.create.title': 'منو جدید',
  'block.type.kanban.title': 'برد',
  'block.type.kanban.columnField': 'دسته‌بندی ستون‌ها براساس',
  'block.type.kanban.featureImage': 'عکس کارت',
  'block.type.kanban.cardTitle': 'عنوان کارت',
  'block.type.kanban.cardFields': 'فیلدهای کارت',
  'block.type.kanban.mode': 'نوع دسترسی',
  'block.type.kanban.view': 'نوع نمایش',
  'block.edit.base': 'ویرایش فرم پایه',
  'block.settings': 'تنظیمات',
  'block.save': 'ذخیره',

  'kanban.view.default': 'کنبن',
  'kanban.view.grid': 'گرید {description}',
  'kanban.search.placeholder': 'جستجو کارت‌ها...',
  'report.chart.type.label': 'نوع چارت را انتخاب کنید:',
  'chart.type.bar': 'پشته‌ای/ستونی',
  'chart.type.pie': 'دایره‌ای',
  'chart.type.line': 'میله‌ای',
  'report.revert': 'چارت چرخیده',

  'field.validation.required': 'این فیلد الزامی است',
  'field.validation.url': 'آدرس معتبر وارد نمایید',
  'field.placeholder.link': 'آدرس لینک',
  'field.placeholder.title': 'عنوان',
  'field.placeholder.fields': 'فیلدها را انتخاب کنید: ',
  'field.placeholder.xField': 'فیلد محور افقی را انتخاب کنید:',
  'field.placeholder.yFields': 'فیلدهای محور عمودی را انتخاب کنید:',
  'field.result.likeDislike.title': '(موافق/مخالف)',
  'field.result.likeDislike.option.like': 'موافق',
  'field.result.likeDislike.option.dislike': 'مخالف',
  'field.result.nps.title': '(از ده)',
  'field.result.score.title': '(از صد)',
  'field.result.star.title': '(از پنج)',
  'field.result.matrix.button': 'نمایش پاسخ',
  'field.file.sizeLimit': 'حداکثر حجم فایل: {size}KB',
  'field.creatable.create': 'ایجاد "{option}"',

  'table.recordsCount': '{count} مورد',
  'general.genericDateTitle': 'تاریخ',
  'table.labelDisplayedRows': '{from}-{to} از {count}',
  'pagination.rows per page': 'تعداد سطرهای هر صفحه',
  'general.genericReferredAddress': 'آدرس مبدا',
  'table.empty': 'متاسفانه هنوز اطلاعاتی برای نمایش وجود ندارد...',
  'table.moreThan': 'بیش از {to}',
  'table.actions': 'عملیات',
  'table.bulkActions.delete.success': '{count} مورد با موفقیت حذف شد.',
  'table.bulkActions.delete.error': 'اشکالی رخ داد...',
  'table.row.add': 'سطر جدید',
  'filters.submit.status.complete': 'تکمیل',
  'filters.submit.status.inComplete': 'در حال تکمیل',
  'filters.submit.status.all': 'همه',
  'filters.submit.status': 'وضعیت پاسخ',
  'filters.condition.label': 'شرط',
  'filters.operator.label': 'عملگر',
  'filters.operand.label': 'مقدار',
  'filters.chip.label': '{field} {condition}: {value}',
  'sortBy.label': 'مرتب‌سازی بر اساس',
  'sortBy.asc': 'صعودی',
  'sortBy.desc': 'نزولی',
  'sortBy.chip.label': 'مرتب‌سازی بر اساس: {field} - {direction}',
  'filters.conditions.exact': 'برابر است با',
  'filters.conditions.iexact': '(غیرحساس به حروف کوچک و بزرگ) برابر است با',
  'filters.conditions.contains': 'شامل',
  'filters.conditions.icontains': '(غیرحساس به حروف کوچک و بزرگ) شامل',
  'filters.conditions.lt': 'کمتر از',
  'filters.conditions.lte': 'کمتر از یا برابر با',
  'filters.conditions.gt': 'بیشتر از',
  'filters.conditions.gte': 'بیشتر از یا برابر با',
  'filters.time.gte': 'از تاریخ',
  'filters.time.lte': 'تا تاریخ',
  'filters.tags': 'تگ',
  'filters.search': 'جستجو',
  'filters.field.hidden': '{field}',
  'filters.field.hidden.title': 'ستون‌های پنهان',
  'filters.date.title': 'فیلتر تاریخ',
  'filter.time.title': 'تاریخ',
  filter: 'فیلتر',
  'pagination.first page': 'صفحه اول',
  'pagination.last page': 'صفحه آخر',
  'pagination.previous page': 'صفحه قبل',
  'pagination.next page': 'صفحه بعد',

  questions: 'پرسش‌ها',
  'date.from': 'از تاریخ',
  'date.to': 'تا تاریخ',
  'date.clear': 'پاک کردن',
  save: 'ذخیره',
  saveAs: 'ذخیره به عنوان جدول جدید',
  'invitation.massage.error': 'خطایی رخ داده است',

  'form.location.viewOnGoogleMaps': 'مشاهده روی نقشه',
  'form.validation.regex': 'مقدار وارد شده با الگوی مورد نظر همخوانی ندارد',
  'form.validation.required': 'این فیلد الزامی است',
  'form.validation.required.matrix':
    'پاسخ به تمامی پرسش‌های این فیلد الزامی است',
  'form.validation.email': 'ایمیل معتبر وارد نمایید',
  'form.validation.minLength': 'تعداد حروف باید بیشتر از {minLength} باشد',
  'form.validation.maxLength': 'تعداد حروف باید کمتر از {maxLength} باشد',
  'form.validation.minValue': 'پاسخ باید بیشتر از {minValue} باشد',
  'form.validation.maxValue': 'پاسخ باید کمتر از {maxValue} باشد',
  'form.validation.url':
    'آدرس باید با http و یا https شروع شود لطفا آدرس معتبر وارد نمایید.',
  'form.validation.number': 'پاسخ باید تنها شامل اعداد باشد',
  'form.validation.time.range':
    'زمان وارد شده باید بین بازه {min} و {max} باشد ',
  'form.validation.time.from': 'زمان وارد شده باید از {min} بزرگتر باشد ',
  'form.validation.time.to': 'زمان وارد شده باید از {max} کوچکتر باشد ',
  'form.validation.date.fromTo': '{desc}(تاریخی بین {min} و {max})',
  'form.validation.date.from': '{desc}(تاریخی بعد از {min})',
  'form.validation.date.to': '{desc}(تاریخی قبل از {max})',
  'form.validation.phone.mobile': 'شماره موبایل معتبر وارد نمایید.',
  'form.validation.phone.landline': 'شماره ثابت معتبر وارد نمایید',
  'form.validation.product.minValue': 'تعداد باید بیشتر از {minValue} باشد',
  'form.validation.product.maxValue': 'تعداد باید کمتر از {maxValue} باشد',
  'form.validation.maxDigit': 'تعداد ارقام نباید بیشتر از {maxLength} باشد',
  'form.validation.invalidData': 'اطلاعات نادرست',
  'form.signature.notSigned': 'امضا نشده!',
  'a11y.ariaLabel.button': 'دکمه {label}',
  'form.response.tags.title': 'تگ‌ها',
  'form.response.tags.empty': 'تگی یافت نشد. جهت ساخت تگ جدید تایپ کنید.',
  'form.response.tags.create.success': 'برچسب سطر جدید با موفقیت ایجاد شد',
  'form.response.tags.delete.success': 'برچسب سطر جدید با موفقیت حذف شد',
  'form.response.tags.update.success':
    'به‌روزرسانی برچسب‌های سطر با موفقیت انجام شد',
  'form.response.tags.update.error': 'خطا در به‌روزرسانی برچسب‌های سطر',
  'form.fields.choices.loading': 'در حال بارگذاری گزینه‌ها...',
  'form.fields.choices.typeToSearch': 'برای جستجو بین گزینه‌ها تایپ کنید',
  'form.fields.choices.noOptionsAvailable': 'موردی یافت نشد',
  'form.fields.dropDown.placeholder': 'برای انتخاب کلیک کنید',
  'form.field.product.quantity': 'تعداد',

  'kanban.filter.title': 'فیلتر',
  'kanban.card.new': 'کارت جدید',
  'kanban.settings.customizing': 'تنظیمات کارت',
  'kanban.settings.groupBy': 'دسته‌بندی براساس',
  'kanban.column.card.count': 'تعداد کارت‌ها',

  'response.copyUrl': 'آدرس اشتراک‌گذاری را کپی کنید',
  'response.edit.successMessage': 'تغییرات با موفقیت ذخیره شد',
  'table.actions.row.create': 'سطر جدید',

  'form.submitResults.createRow.successMessage': 'سطر با موفقیت ایجاد شد',

  'general.genericErrorMessage': 'اشکالی رخ داد...',
  'general.error.fixFormErrors': 'لطفا اشکالات فرم را برطرف نمایید',
  'general.reset': 'بازنشادن',
  'general.unsavedChanges': 'تغییرات ذخیره‌نشده',
  'general.downloadFile': 'دانلود فایل',

  'charts.avgRate': 'نرخ میانگین: {avg}',
  'charts.totalAnswer': ' کل پاسخ‌ها: {total}',
  'charts.npsScore': 'نمره NPS: {score}',

  'grid.column.number': 'تعداد',
  'grid.column.columns': 'ستونها',

  'summary.edit': 'ویرایش فرم',
  'summary.view': 'نمایش فرم',
  'summary.share': 'ارسال و انتشار',
  'summary.chart': 'نمودارها',
  'summary.response': 'پاسخ‌ها',
  'summary.integration': 'اتوماسیون و وب‌هوک',
  'summary.presentation': 'حالت ارائه',
  'validation.slug': 'آدرس معتبر وارد نمایید',
  'validation.domain':
    'آدرس تنها شامل اعداد، حروف، -، نقطه و ـ بدون فاصله می‌باشد.',
}
