import { typeUtils } from 'types'
import {
  getFormItemErrors,
  getFormItemLabel,
  getGenericFormItemDefaultRawValue as getDefaultValue,
} from './_propGetters'

import { getRequiredRule } from './_validationProps'
import { defaultLocale } from 'app-settings'
import LazyLoad from 'utils/lazy-load'

const DatePickerInput = LazyLoad({
  loader: () => import('stories/FormInputs/DatePicker/DatePicker'),
})

const Component = DatePickerInput

const getValidDateRange = fieldProps => ({
  minDate: fieldProps.from_date ?? undefined,
  maxDate: fieldProps.to_date ?? undefined,
})

const getProps = fieldProps => ({
  ...fieldProps,
  name: fieldProps.slug,
  label: getFormItemLabel(fieldProps),
  // input props
  separateLabel: true,
  autoFill: false,
  locale: defaultLocale,
  format: fieldProps.date_format,
  ...getValidDateRange(fieldProps),
})

const getControlledProps = (fieldProps, controllerProps, defaultValue) => ({
  ...controllerProps.field,
  type: typeUtils.getMostSpecificType(fieldProps),
  label: getFormItemLabel(fieldProps),
  // input props
  separateLabel: true,
  autoFill: false,
  errors: getFormItemErrors(controllerProps),
  locale: defaultLocale,
  displayFormat: fieldProps.date_format?.toUpperCase() ?? 'DD/MM/YYYY',
  outputFormat: 'YYYY-MM-DD',
  ...getValidDateRange(fieldProps),
  clearable: !fieldProps.required,
  showTodayButton: true,
})

const getValidationRules = (fieldProps, options) => ({
  ...getRequiredRule(fieldProps, options),
})

export const dateInput = {
  Component,
  getProps,
  getControlledProps,
  getValidationRules,
  getDefaultValue,
}
