import { defaultLocale, locales } from 'app-settings'

export const toPersianDigit = a => {
  if (a !== null && a !== undefined) {
    if (typeof a === 'number') {
      a = a.toString()
    }

    return a.replace(/\d+/g, function (digit) {
      const enDigitArr = []
      const peDigitArr = []

      for (let i = 0; i < digit.length; i++) {
        enDigitArr.push(digit.charCodeAt(i))
      }

      for (let j = 0; j < enDigitArr.length; j++) {
        peDigitArr.push(
          String.fromCharCode(
            enDigitArr[j] + (!!a && a === true ? 1584 : 1728),
          ),
        )
      }

      return peDigitArr.join('')
    })
  }
  return ''
}

export const formatNumber = digit => {
  return defaultLocale === locales.fa ? toPersianDigit(digit) : digit
}

export function thousandsSeparator(rowNum) {
  return rowNum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}
