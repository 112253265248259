import { AuthStorage } from 'app-settings/storage.settings'
import API from 'app-settings/api.settings'
import Storage from 'utils/storage-management'
import { HTTP } from './http'
import assetSettings from 'app-settings/assets.settings'
import { removeBlankProperty } from 'utils/formatter/data-structure'
import { shouldSendCredentials } from 'utils/common'

const headers = ({
  apiKey = API.apiKey,
  contentType = HTTP.ContentTypes.JSON,
  workspace,
}) => {
  return removeBlankProperty({
    'Content-Type': contentType,
    'x-api-key': apiKey,
    accept: HTTP.ContentTypes.JSON,
    'x-workspace': workspace,
  })
}

const baseHttpConfig = {
  validateStatus: status => status < 400,
  withCredentials: shouldSendCredentials(),
  headers: headers({}),
}

const generateAuthHeaders = (accessToken, settings = {}) => ({
  ...headers(settings),
  Authorization: `JWT ${accessToken}`,
})

export const getAccessToken = () => {
  return AuthStorage()?.accessToken?.get()
}

// ______________________________________________________
export const config = (additionalSettings = {}) => {
  const token = additionalSettings.accessToken ?? getAccessToken()

  if (!token) {
    const config = { ...baseHttpConfig, ...additionalSettings }
    return config
  }

  const headerSettings = {
    headers: generateAuthHeaders(token, additionalSettings),
  }
  const config = {
    ...baseHttpConfig,
    ...headerSettings,
    ...additionalSettings,
  }
  return config
}

// ______________________________________________________
export const unAuthorizedConfig = additionalSettings => {
  const settings = {
    headers: headers(additionalSettings),
    data: additionalSettings.data || null,
    validateStatus: status => status < 400,
  }

  return { ...settings, ...additionalSettings }
}

export const getCurrentWorkspace = () =>
  Storage.get(assetSettings.ACTIVE_WORKSPACE_STORAGE_KEY) &&
  JSON.parse(Storage.get(assetSettings.ACTIVE_WORKSPACE_STORAGE_KEY))
