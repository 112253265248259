import { block } from 'api/block'
import { useState, useEffect } from 'react'
import { useBoardMode } from 'context/mode'
import { getBoardMetaData } from 'utils/board/meta-data'

export const useBlockFn = () => {
  const { setSwitchMode } = useBoardMode()
  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    setSwitchMode(!isLoading)
  }, [isLoading])

  const updateTheBlock = async (
    boardSlug,
    blockSlug,
    formValues,
    options = {},
  ) => {
    setLoading(true)
    try {
      const {
        data: { data, errors },
      } = await block().update(boardSlug, blockSlug, formValues, options)
      setLoading(false)
      if (data && data.block) return Promise.resolve(data.block)
      return Promise.reject(errors)
    } catch (error) {
      setLoading(false)
      return Promise.reject(error)
    }
  }

  const createBlock = async (boardSlug, blockType, formValues) => {
    setLoading(true)
    try {
      const {
        data: { data, errors },
      } = await block().create(boardSlug, blockType, formValues)
      setLoading(false)
      if (data && data.block) return Promise.resolve(data.block)
      return Promise.reject(errors)
    } catch (error) {
      setLoading(false)
      return Promise.reject(error)
    }
  }

  const deleteBlock = async (boardSlug, blockSlug) => {
    setLoading(true)
    try {
      await block().delete(boardSlug, blockSlug)
      setLoading(false)
      return Promise.resolve()
    } catch (error) {
      setLoading(false)
      return Promise.reject(error)
    }
  }

  const getBlock = async (boardSlug, blockSlug, boardPublicSlug) => {
    const { isPrivate } = getBoardMetaData()

    const getBlockByAccess = () =>
      isPrivate
        ? block().getOne(boardSlug, blockSlug)
        : block().getOneSharedBlock(boardPublicSlug, blockSlug)

    setLoading(true)
    try {
      const {
        data: { data },
      } = await getBlockByAccess()
      setLoading(false)
      if (data) return Promise.resolve(data.block)
    } catch (error) {
      setLoading(false)
      return Promise.reject(error)
    }
  }

  const duplicateBlock = async (
    boardSlug,
    blockType,
    blockSlug,
    formValues = {},
  ) => {
    setLoading(true)
    try {
      const {
        data: { data, errors },
      } = await block().duplicate(boardSlug, blockType, blockSlug, formValues)
      setLoading(false)
      if (data && data.block) return Promise.resolve(data.block)
      return Promise.reject(errors)
    } catch (error) {
      setLoading(false)
      return Promise.reject(error)
    }
  }

  const createTableReport = async formValues => {
    setLoading(true)
    try {
      const {
        data: { data, errors },
      } = await block().createTableReport(formValues)
      setLoading(false)
      if (data && data.table_chart) return Promise.resolve(data.table_chart)
      return Promise.reject(errors)
    } catch (error) {
      setLoading(false)
      return Promise.reject(error)
    }
  }

  const updateReport = async (reportSlug, formValues) => {
    setLoading(true)
    try {
      const {
        data: { data, errors },
      } = await block().updateReport(reportSlug, formValues)
      setLoading(false)
      if (data && data.report) return Promise.resolve(data.report)
      return Promise.reject(errors)
    } catch (error) {
      setLoading(false)
      return Promise.reject(error)
    }
  }

  return {
    isLoading,
    duplicateBlock,
    updateTheBlock,
    createBlock,
    deleteBlock,
    getBlock,
    createTableReport,
    updateReport,
  }
}
