import React from 'react'
import PropTypes from 'prop-types'

import MuiInputLabel from '@mui/material/InputLabel'
import ReactSelect from 'react-select'

import { InputErrors } from '../_InputErrors'

import useFormatMessage from 'hooks/utility/useFormatMessage'
import { FormHelperText } from '@mui/material'
import Box from '@mui/material/Box'
import { useTheme } from '@mui/material/styles'

const SelectInput = React.forwardRef(
  (
    {
      name,
      label,
      description,
      autoFill,
      readOnly,
      errors,
      showLabel,
      separateLabel,
      inputProps: muiInputProps,
      type,
      options,
      isLoading,
      renderOption,
      getOptionLabel,
      getOptionValue,
      defaultValue,
      value: controlledValue,
      onChange: handleChange,
      loadingMessage,
      noOptionsMessage,
      disabled: isDisabled,
      isMulti,
      ...props
    },
    ref,
  ) => {
    const muiTheme = useTheme()
    const formatMessage = useFormatMessage()
    const showDefaultMuiLabel = showLabel && !separateLabel
    const showSeparateLabel = showLabel && separateLabel

    const autoComplete = React.useMemo(
      () => (Boolean(autoFill) ? 'on' : `off-${name}`),
      [autoFill, name],
    )

    const customizedTexts = React.useMemo(
      () => ({
        placeholder: formatMessage({ id: 'form.fields.dropDown.placeholder' }),
        loadingMessage: () =>
          formatMessage({ id: 'form.fields.choices.loading' }),
        noOptionsMessage: () =>
          formatMessage({
            id: 'form.fields.choices.noOptionsAvailable',
          }),
      }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [loadingMessage, noOptionsMessage],
    )

    const isControlledInput = React.useMemo(
      () => typeof handleChange === 'function',
      [handleChange],
    )

    const [valueState, setValueState] = React.useState(defaultValue)

    const _getCurrentValue = ({
      isControlledInput,
      controlledValue,
      value,
      isMulti,
      options,
    }) => {
      const v = isControlledInput ? controlledValue : value

      const newValue = isMulti
        ? options.filter(option => v?.includes(getOptionValue(option)))
        : options.find(option => getOptionValue(option) === v)

      return newValue ?? null
    }

    const currentValue = React.useMemo(
      () =>
        _getCurrentValue({
          isControlledInput,
          controlledValue,
          value: valueState,
          isMulti,
          options,
        }),

      // eslint-disable-next-line react-hooks/exhaustive-deps
      [isControlledInput, controlledValue, valueState, isMulti, options],
    )

    const _onChange = (eventValue, actionMeta) => {
      const newValue = isMulti
        ? eventValue.map(getOptionValue)
        : getOptionValue(eventValue)

      if (isControlledInput) {
        handleChange(newValue)
      } else {
        setValueState(newValue)
      }
    }

    const hasError = errors?.length > 0

    return (
      <Box
        sx={{
          display: 'grid',
          gridGap: 0.5,
          alignItems: 'start',
          justifyItems: 'start',
          alignContent: 'stretch',
          justifyContent: 'normal',
          width: '100%',
          '& .formaloo-select__menu': {},
          '& .formaloo-select__control': {
            minHeight: 50,
            borderRadius: 2,
          },
          '& .formaloo-select__multi-value': {
            backgroundColor: 'background.paper',
            borderRadius: 2,
            px: 1,
            py: 0.4,
          },
          '& .formaloo-select__indicator-separator': {
            display: 'none',
          },
        }}
      >
        {showSeparateLabel && (
          <MuiInputLabel
            id={`${name}-label-id`}
            htmlFor={`${name}-id`}
            error={hasError}
            sx={{
              fontSize: '1.2rem',
              color: 'primary.lighten',
              mb: 1.5,
            }}
          >
            {label}
          </MuiInputLabel>
        )}
        <ReactSelect
          ref={ref}
          // provide a unique id to make it accessible
          aria-label={label}
          id={`${name}-select`}
          inputId={`${name}-id`}
          aria-labelledby={`${name}-label-id`}
          name={name}
          options={options}
          value={currentValue}
          onChange={_onChange}
          getOptionLabel={getOptionLabel}
          getOptionValue={getOptionValue}
          isDisabled={isDisabled || readOnly}
          isMulti={isMulti}
          classNamePrefix="formaloo-select"
          style={{ width: props.fullWidth ? '100%' : 'auto' }}
          styles={{
            option: (styles, { isDisabled, isFocused, isSelected }) => {
              return {
                ...styles,
                color: muiTheme.palette.primary.dark,
                backgroundColor: isDisabled
                  ? undefined
                  : isSelected
                  ? muiTheme.palette.background.paper
                  : isFocused
                  ? muiTheme.palette.background.paper
                  : undefined,
                ':active': {
                  ...styles[':active'],
                  backgroundColor: !isDisabled
                    ? isSelected
                      ? muiTheme.palette.primary.main
                      : undefined
                    : undefined,
                },
              }
            },
          }}
          theme={theme => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary: muiTheme.palette.primary.main,
            },
          })}
          {...customizedTexts}
          {...props}
        />
        {description && <FormHelperText>{description}</FormHelperText>}
        {hasError && <InputErrors errors={errors} />}
      </Box>
    )
  },
)

SelectInput.displayName = 'FormalooSelectInput'

SelectInput.defaultProps = {
  showLabel: true,
  autoFill: false,
  size: 'small',
  separateLabel: true,
  fullWidth: true,
  isLoading: false,
  getOptionValue: option => (option ? option.slug : null),
  getOptionLabel: option => option?.title,
  isOptionSelected: (option, selectValue) =>
    Array.isArray(selectValue)
      ? selectValue.includes(option.slug)
      : option.slug === selectValue,
}

SelectInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  separateLabel: PropTypes.bool,
  showLabel: PropTypes.bool,
  isClearable: PropTypes.bool,
  description: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    PropTypes.elementType,
  ]),
  value: PropTypes.any,
  defaultValue: PropTypes.any,
  onChange: PropTypes.func,
  fullWidth: PropTypes.bool,
  autoFill: PropTypes.bool,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  size: PropTypes.oneOf(['small', 'normal']),
  errors: PropTypes.arrayOf(PropTypes.string),
  isMulti: PropTypes.bool,
  options: PropTypes.array.isRequired,
  getOptionLabel: PropTypes.func.isRequired,
  getOptionValue: PropTypes.func.isRequired,
  /**
   * Render the option, use getOptionLabel by default.
   *
   * ```
   * Signature:
   *  function(option: T, state: object) => ReactNode
   *  option: The option to render.
   *  state: The state of the component.
   * ```
   */
  renderOption: PropTypes.func,
  /**
   * Render the selected value.
   *
   * ```
   *  Signature:
   *  function(value: T[], getTagProps: function) => ReactNode
   *  value: The value provided to the component.
   *  getTagProps: A tag props getter.
   * ```
   */
  renderTags: PropTypes.func,
  /**
   * If true, the Autocomplete is free solo, meaning that
   * the user input is not bound to provided options.
   */
  freeSolo: PropTypes.bool,
  isLoading: PropTypes.bool,
  /**
   * Used to determine if an option is selected,
   * considering the current value. Uses strict equality by default.
   *
   * ```
   * Signature:
   *  function(option: T, value: T) => boolean
   *  option: The option to test.
   *  value: The value to test against.
   * ```
   */
  isOptionSelected: PropTypes.func,
  isOptionDisabled: PropTypes.func,
  onInputChange: PropTypes.func,
  loadingText: PropTypes.string,
  noOptionsText: PropTypes.string,
  isRtl: PropTypes.bool,
}

export default SelectInput
