/**
 * @typedef {string} FormType
 **/

/**
 * Enum for form type values.
 * @readonly
 * @enum {FormType}
 */
export const FORM_TYPE = {
  SINGLE_PAGE: 'simple',
  MULTI_PAGE: 'multi_step',
}
