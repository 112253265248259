import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { useForm, Controller } from 'react-hook-form'
import FormItem from 'stories/FormInputs/FormItem/FormItem'
import {
  getControlledFormItemProps,
  getFormItemValidationRules,
} from 'stories/FormInputs/FormItem'

import { FIELD_TYPE, typeUtils } from 'types'
import { setErrors } from 'utils/error'
import { notify } from 'utils/notification'
import { useEffect, useCallback } from 'react'
import AsyncCreatableSelect from 'stories/FormInputs/AsyncCreatableSelect'
import { fetchResponseTagOptions } from 'stories/Form/FormResponseTags/_utils'
import { getFormItemErrors } from 'stories/FormInputs/FormItem/props/_propGetters'
import { getRandomTagColor } from 'stories/Form/FormResponseTags/_colors'
import Button from 'stories/button/Button'
import { tags as tagApi } from 'api/response-tags'
import isEmpty from 'lodash/isEmpty'
import { removeBlankProperty } from '../../../utils/formatter/data-structure'

const getCreatableFields = (fields = []) =>
  fields.filter(
    field =>
      field.type &&
      !typeUtils.isNonRenderable(field) &&
      !typeUtils.isResponseVerificationState(field) &&
      !typeUtils.isVerificationField(field) &&
      typeUtils.getMostSpecificType(field) !== FIELD_TYPE.SIGNATURE,
  )

const getDefaultValues = (fields, defaults = {}) => {
  const serverDefaults = fields.reduce(
    (prev, curr) => ({
      ...prev,
      [curr.slug]: curr.default,
    }),
    {},
  )
  return {
    ...serverDefaults,
    ...removeBlankProperty(defaults),
  }
}

const FormResponseTags = ({ formSlug, control, ...props }) => {
  return (
    <Controller
      name="row_tags"
      control={control}
      render={({ field: fieldProps, ...controllerProps }) => (
        <AsyncCreatableSelect
          label={<FormattedMessage id="form.response.tags.title" />}
          {...fieldProps}
          {...controllerProps}
          fetchOptions={fetchResponseTagOptions(formSlug)}
          onCreateOption={async (title, { selectMethods }) => {
            selectMethods.setIsLoading(true)

            try {
              const {
                data: { data },
              } = await tagApi().create(formSlug, {
                title,
                color: getRandomTagColor().textColor,
              })
              notify(
                <FormattedMessage id="form.response.tags.create.success" />,
                'success',
              )
              selectMethods.addToOptions(data.row_tag)
              selectMethods.setIsLoading(false)
              return Promise.resolve(data.row_tag)
            } catch (error) {
              notify(
                <FormattedMessage id="invitation.massage.error" />,
                'error',
              )
              selectMethods.setIsLoading(false)
              return Promise.reject(error)
            }
          }}
          errors={getFormItemErrors(controllerProps)}
          isMulti
          formatOptionLabel={(option, context) => {
            return (
              <div style={{ display: 'flex', gridGap: '8px' }}>
                {option.color ? (
                  <span
                    style={{
                      borderRadius: '50%',
                      width: '16px',
                      height: '16px',
                      backgroundColor: option.color,
                    }}
                  />
                ) : null}
                {option.title}
              </div>
            )
          }}
          noOptionsMessage={<FormattedMessage id="form.response.tags.empty" />}
          defaultOptions
        />
      )}
    />
  )
}

const FormResponseCreateForm = ({
  form,
  onSuccessfulSubmit,
  onFailedSubmit,
  onDirtyStateChange,
  disableFields = [],
  onSubmit: { createResponse, isSubmitting },
  disableTags,
  ...props
}) => {
  const ignoreRequired = React.useMemo(
    () => form.logic?.length > 0,
    [form.logic],
  )

  const creatableFields = getCreatableFields(form.fields_list)
  const defaultValues = getDefaultValues(creatableFields, props.defaultValues)

  const formDataProps = {
    currency: form.currency,
  }

  const {
    handleSubmit,
    reset,
    formState: { isValid, isValidating, isDirty },
    control,
    setError,
  } = useForm({
    mode: 'onChange',
    criteriaMode: 'all',
    defaultValues,
  })

  const setFieldError = useCallback(
    fieldSlug => errors => {
      setError(fieldSlug, errors)
    },
    [],
  )

  useEffect(() => {
    if (!isEmpty(defaultValues)) {
      reset(defaultValues)
    }
  }, [])

  useEffect(() => {
    if (!isSubmitting && !isValidating) {
      onDirtyStateChange(isDirty)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty, isSubmitting, isValidating])

  const onSubmit = async data => {
    // const formValues = getDirtyFormFieldValues(dirtyFields, data)

    if (data && Object.keys(data).length > 0) {
      createResponse(data, form.slug)
        .then(response => {
          notify(
            <FormattedMessage id="form.submitResults.createRow.successMessage" />,
            'success',
          )
          typeof onSuccessfulSubmit === 'function' &&
            onSuccessfulSubmit(response)
          reset(defaultValues)
        })
        .catch(error => {
          setErrors(error, setError)

          notify(<FormattedMessage id="general.error.fixFormErrors" />, 'error')

          typeof onFailedSubmit === 'function' && onFailedSubmit(error)
        })
    }
  }

  return (
    <>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          mb: 6,
          display: 'flex',
          minHeight: '100%',
          flexDirection: 'column',
        }}
      >
        {!disableTags && (
          <FormResponseTags control={control} formSlug={form.slug} />
        )}
        <hr />

        {creatableFields.map(fieldProps => {
          return (
            <Box
              key={fieldProps.slug}
              sx={{
                width: '100%',
                margin: '8px 0px',
                py: 1,
              }}
            >
              <Controller
                name={fieldProps.slug}
                control={control}
                rules={getFormItemValidationRules(fieldProps, {
                  ignoreRequired,
                })}
                render={controllerProps => (
                  <FormItem
                    {...getControlledFormItemProps(
                      fieldProps,
                      controllerProps,
                      {
                        formDataProps,
                        setFieldErrors: setFieldError(fieldProps.slug),
                      },
                    )}
                    disabled={disableFields.includes(fieldProps.slug)}
                  />
                )}
              />
            </Box>
          )
        })}

        <Box
          sx={{
            width: 'calc(100% + 48px)',
            bottom: '0',
            marginBottom: '-56px',
            display: 'flex',
            padding: '8px 24px',
            position: 'sticky',
            background: 'white',
            boxShadow: 'none',
            marginLeft: '-24px',
            marginTop: 'auto',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            variant="outlined"
            color="secondary"
            disabled={!isDirty || isSubmitting}
            type="button"
            onClick={() => reset()}
            sx={{
              minWidth: 150,
              color: '#794EF1',
              minHeight: 50,
              ml: 1,
              border: 'none',
              '&.Mui-disabled': {
                border: 'none',
              },
              '&:hover': {
                boxShadow: 'none',
                border: 'none',
                background: 'none',
              },
            }}
          >
            <FormattedMessage id="general.reset" />
          </Button>

          <div>
            {isDirty && !isValid && (
              <Typography variant="caption" component="span" color="error">
                <FormattedMessage id="form.validation.invalidData" />{' '}
              </Typography>
            )}
            <Button
              variant="contained"
              color="secondary"
              disabled={!isValid || isValidating || isSubmitting}
              isLoading={isSubmitting}
              type="submit"
              sx={{
                minWidth: 150,
                background: '#794EF1',
                minHeight: 50,
                ml: 1,
                '&:hover': { background: '#794EF1', boxShadow: 'none' },
              }}
            >
              <FormattedMessage id="submit" />
            </Button>
          </div>
        </Box>
      </Box>
    </>
  )
}

FormResponseCreateForm.defaultProps = {}

FormResponseCreateForm.propTypes = {
  form: PropTypes.object,
  onSuccessfulSubmit: PropTypes.func,
  onFailedSubmit: PropTypes.func,
  onDirtyStateChange: PropTypes.func,
}

export default FormResponseCreateForm
