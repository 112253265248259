import { Helmet } from 'react-helmet'

const HelmetHeader = ({ logo, title = process.env.REACT_APP_TITLE }) => {
  return (
    <Helmet>
      <link rel="icon" id="favicon" href={logo} />
      <title>{title}</title>
      <meta property="og:title" content={title} />
      <meta property="og:image" content={logo} />
      <meta property="twitter:image" content={logo} />
    </Helmet>
  )
}

export default HelmetHeader
