import PhoneInput from 'stories/FormInputs/Phone/Phone'

import { typeUtils } from 'types'
import {
  getFormItemErrors,
  getFormItemLabel,
  getGenericFormItemDefaultValue as getDefaultValue,
} from './_propGetters'
import { getRequiredRule } from './_validationProps'

const Component = PhoneInput

const getProps = fieldProps => ({
  ...fieldProps,
  name: fieldProps.slug,
  label: getFormItemLabel(fieldProps),
  // input props
  separateLabel: true,
  autoFill: false,
})

const getControlledProps = (fieldProps, controllerProps, defaultValue) => ({
  ...controllerProps.field,
  type: typeUtils.getMostSpecificType(fieldProps),
  label: getFormItemLabel(fieldProps),
  // input props
  separateLabel: true,
  autoFill: false,
  errors: getFormItemErrors(controllerProps),
})

const getValidationRules = (fieldProps, options) => ({
  ...getRequiredRule(fieldProps, options),
})

export const phoneInput = {
  Component,
  getProps,
  getControlledProps,
  getValidationRules,
  getDefaultValue,
}
