import getRawMessage from 'locales/localeDirectAccess'

export const getRequiredRule = (fieldProps, options = {}) =>
  !options.ignoreRequired &&
  fieldProps.required && {
    required: {
      value: fieldProps.required,
      message: getRawMessage('form.validation.required'),
    },
  }

export const getMinValueRule = (fieldProps, minValue) =>
  (minValue || !!fieldProps.min_value) && {
    min: {
      value: minValue ?? fieldProps.min_value,
      message: getRawMessage('form.validation.minValue', {
        minValue: minValue ?? fieldProps.min_value,
      }),
    },
  }

export const getMaxValueRule = (fieldProps, maxValue) =>
  (maxValue || !!fieldProps.max_value) && {
    max: {
      value: maxValue ?? fieldProps.max_value,
      message: getRawMessage('form.validation.maxValue', {
        maxValue: maxValue ?? fieldProps.max_value,
      }),
    },
  }

export const getMinLengthRule = (fieldProps, minLength) =>
  (minLength || !!fieldProps.min_length) && {
    minLength: {
      value: minLength ?? fieldProps.min_length,
      message: getRawMessage('form.validation.minLength', {
        minLength: minLength ?? fieldProps.min_length,
      }),
    },
  }

export const getMaxLengthRule = (fieldProps, maxLength) =>
  (maxLength ?? !!fieldProps.max_length) && {
    maxLength: {
      value: maxLength ?? fieldProps.max_length,
      message: getRawMessage('form.validation.maxLength', {
        maxLength: maxLength ?? fieldProps.max_length,
      }),
    },
  }
