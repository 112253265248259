import React from 'react'
import PropTypes from 'prop-types'
import { getFormItemComponent } from './props'
import { getType } from 'types/_utils'

const FormItem = React.forwardRef((props, forwardedRef) => {
  const FormItemComponent = getFormItemComponent(props)

  return FormItemComponent ? (
    <FormItemComponent {...props} ref={forwardedRef} />
  ) : (
    <>unsupported field: {getType(props)}</>
  )
})

FormItem.propTypes = {
  type: PropTypes.string.isRequired,
}

FormItem.displayName = 'FormalooFormItem'

export default FormItem
