export default function SettingsIcon({ color = '#28293D', ...props }) {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
      <path
        d="M10.432 7.56821C11.2228 8.35902 11.2228 9.64118 10.432 10.432C9.64118 11.2228 8.35902 11.2228 7.56821 10.432C6.77739 9.64118 6.77739 8.35902 7.56821 7.56821C8.35902 6.77739 9.64118 6.77739 10.432 7.56821"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.93752 9C3.93752 9.22275 3.95777 9.4455 3.98477 9.6615L2.79377 10.593C2.52977 10.8 2.45777 11.1697 2.62577 11.46L3.68477 13.2923C3.85202 13.5825 4.20752 13.7048 4.51877 13.5803L5.58527 13.152C5.79602 13.0673 6.03077 13.101 6.21977 13.2263C6.38477 13.3358 6.55577 13.4362 6.73277 13.5262C6.93527 13.629 7.08227 13.8127 7.11452 14.0378L7.27727 15.1725C7.32452 15.504 7.60877 15.75 7.94327 15.75H10.056C10.3905 15.75 10.6748 15.504 10.722 15.1725L10.8848 14.0385C10.917 13.8135 11.0655 13.6282 11.2688 13.5262C11.445 13.4377 11.6153 13.338 11.7795 13.2292C11.97 13.1032 12.2055 13.0672 12.417 13.1527L13.4813 13.5803C13.7918 13.7048 14.1473 13.5825 14.3153 13.2923L15.3743 11.46C15.5423 11.1697 15.4703 10.7992 15.2063 10.593L14.0153 9.6615C14.0423 9.4455 14.0625 9.22275 14.0625 9C14.0625 8.77725 14.0423 8.5545 14.0153 8.3385L15.2063 7.407C15.4703 7.2 15.5423 6.83025 15.3743 6.54L14.3153 4.70775C14.148 4.4175 13.7925 4.29525 13.4813 4.41975L12.417 4.84725C12.2055 4.932 11.97 4.89675 11.7795 4.77075C11.6153 4.662 11.445 4.56225 11.2688 4.47375C11.0655 4.37175 10.917 4.1865 10.8848 3.9615L10.7228 2.8275C10.6755 2.496 10.3913 2.25 10.0568 2.25H7.94402C7.60952 2.25 7.32527 2.496 7.27802 2.8275L7.11452 3.963C7.08227 4.18725 6.93452 4.37175 6.73277 4.4745C6.55577 4.5645 6.38477 4.66575 6.21977 4.7745C6.03002 4.899 5.79527 4.93275 5.58452 4.848L4.51877 4.41975C4.20752 4.29525 3.85202 4.4175 3.68477 4.70775L2.62577 6.54C2.45777 6.83025 2.52977 7.20075 2.79377 7.407L3.98477 8.3385C3.95777 8.5545 3.93752 8.77725 3.93752 9V9Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
