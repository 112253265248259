import FileInput from 'stories/FormInputs/File/File'

import { typeUtils } from 'types'
import {
  getFormItemErrors,
  getFormItemLabel,
  getGenericFormItemDefaultValue as getDefaultValue,
} from './_propGetters'
import { FormattedMessage } from 'react-intl'
import { getRequiredRule } from './_validationProps'
import getRawMessage from 'locales/localeDirectAccess'
import { formatNumber } from 'utils/formatter/digit'
import { flattenErrors } from 'utils/error'
import { storageService } from 'api/storage-service'

const accept = {
  document: '.txt,.pdf,.doc,.docx,xls,.xlsx,.ppt,.pptx',
  image: 'image/*',
  all: '*',
}

const generateDescription = (desc, maxSize) => {
  if (!maxSize) return desc

  return (
    <span>
      {desc || ''}
      <span className="form-field-limit">
        <FormattedMessage
          id="field.file.sizeLimit"
          values={{ size: formatNumber(maxSize) }}
        />
      </span>
    </span>
  )
}

const Component = FileInput

const getProps = fieldProps => ({
  ...fieldProps,
  name: fieldProps.slug,
  label: getFormItemLabel(fieldProps),
  // input props
  separateLabel: true,
  accept: accept[fieldProps.file_type],
})

const getControlledProps = (
  fieldProps,
  controllerProps,
  defaultValue,
  rowDataProps,
  formDataProps,
  setFieldErrors,
) => ({
  ...controllerProps.field,
  type: typeUtils.getMostSpecificType(fieldProps),
  label: getFormItemLabel(fieldProps),
  // input props
  separateLabel: true,
  errors: getFormItemErrors(controllerProps),
  accept: accept[fieldProps.file_type],
  // TODO: handle multiple file uploads
  onChange: async files => {
    const file = files?.length > 0 ? files[0] : null

    // clear file input
    if (file === null) {
      controllerProps.field.onChange(null)
    }

    if (!file) return

    const {
      data: { data },
    } = await storageService().upload(
      { file: file.data },
      {
        onUploadProgress: file.onUploadProgress,
      },
    )
    try {
      file.onUploadSuccess(data.file)
      controllerProps.field.onChange(data.file.slug)
    } catch (error) {
      setFieldErrors({
        type: 'upload-api',
        message: error
          ? flattenErrors(error).join(',')
          : getRawMessage('invitation.massage.error'),
      })
    }
  },
  description: generateDescription(fieldProps.description, fieldProps.max_size),
})

const getValidationRules = (fieldProps, options) => ({
  ...getRequiredRule(fieldProps, options),
})

export const fileInput = {
  Component,
  getProps,
  getControlledProps,
  getValidationRules,
  getDefaultValue,
}
