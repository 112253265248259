import React from 'react'
import PropTypes from 'prop-types'

import TextInput from '../Text/Text'

const EmailInput = React.forwardRef((props, forwardedRef) => (
  <TextInput ref={forwardedRef} dir="ltr" {...props} />
))

EmailInput.displayName = 'FormalooEmailInput'

EmailInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  separateLabel: PropTypes.bool,
  showLabel: PropTypes.bool,
  description: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    PropTypes.elementType,
  ]),
  value: PropTypes.string,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
  fullWidth: PropTypes.bool,
  autoFill: PropTypes.bool,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  multiline: PropTypes.bool,
  placeholder: PropTypes.string,
  size: PropTypes.oneOf(['small', 'normal']),
  errors: PropTypes.arrayOf(PropTypes.string),
}

export default EmailInput
