import Box from '@mui/material/Box'

export default function MobileClickAway({ onClick }) {
  return (
    <Box
      onClick={onClick}
      sx={{
        width: 'calc(100vw - 305px)',
        height: '100vh',
        position: 'fixed',
        top: 0,
        bottom: 0,
        background: 'transparent',
        zIndex: -1,
        right: 0,
      }}
    />
  )
}
