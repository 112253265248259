import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import assetSettings from 'app-settings/assets.settings'

const { sentryDSN, environment } = assetSettings.environment

// Ignore list based off: https://gist.github.com/1878283
const ravenOptions = {
  // white list formaloo and all of its subdomains
  whitelistUrls: [/https?:\/\/((.+)\.)?formaloo\.(com|net)/],
  ignoreErrors: [
    // Random plugins/extensions
    'top.GLOBALS',
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    'originalCreateNotification',
    'canvas.contentDocument',
    'MyApp_RemoveAllHighlights',
    'http://tt.epicplay.com',
    "Can't find variable: ZiteReader",
    'jigsaw is not defined',
    'ComboSearch is not defined',
    'http://loading.retry.widdit.com/',
    'atomicFindClose',
    // Facebook borked
    'fb_xd_fragment',
    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
    // reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268
    'bmi_SafeAddOnload',
    'EBCallBackMessageReceived',
    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    'conduitPage',
    // You can delete this if using raven.js > 1.0, which ignores these automatically.
    'Script error.',
    'ResizeObserver loop limit exceeded',
  ],
  ignoreUrls: [
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Woopra flakiness
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
  ],
}

export default function setupLogger() {
  if (sentryDSN) {
    Sentry.init({
      dsn: sentryDSN,
      integrations: [new Integrations.BrowserTracing()],
      environment,
      ...ravenOptions,
      // We recommend adjusting this value in production, or using tracesSampler
      // for finer control
      tracesSampleRate: 0.01,
      sendDefaultPii: true,
    })
  }
}
