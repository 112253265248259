import { getBoardMetaData } from 'utils/board/meta-data'
import { USER_ACCESS } from 'types/user-access'

export const useAccess = () => {
  const { userAccess } = getBoardMetaData()

  const isAllowedToEdit = userAccess !== USER_ACCESS.READ
  const isAdmin = !userAccess || userAccess === USER_ACCESS.OWNER

  return { isAllowedToEdit, isAdmin }
}
