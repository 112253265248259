import urlSettings from 'app-settings/url.settings'
import assetSettings from 'app-settings/assets.settings'
import { getBoardMetaData } from 'utils/board/meta-data'

export const isEmbeddedInTheDashboard = () =>
  window.location.origin === urlSettings.formBuilderDomain

export const isLoadedInFrame = () => {
  try {
    return window.self !== window.top
  } catch (e) {
    return true
  }
}

export const isLoadedViaScript = () => {
  const { boardSlug } = getBoardMetaData()

  return !!boardSlug
}

export const isInDevMode = () => assetSettings.environment.devMode

export const isInFakeEmbed = () =>
  process.env.REACT_APP_FAKE_EMBED?.toLowerCase() === 'true'

export const shouldSendCredentials = () =>
  !isInFakeEmbed() &&
  !isEmbeddedInTheDashboard() &&
  !isLoadedInFrame() &&
  !isLoadedViaScript()
