import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import FormHelperText from '@mui/material/FormHelperText'
import MuiInputLabel from '@mui/material/InputLabel'
import Button from 'stories/button/Button'
import { InputErrors } from '../_InputErrors'
import { isValidHTML } from 'utils/formatter/html'

const LocationPreview = ({ value, label }) => {
  if (!value)
    return (
      <Button variant="contained" disabled fullWidth>
        <FormattedMessage id="general.notAvailable" />
      </Button>
    )

  // handle default value (url)
  if (isValidHTML(value)) {
    const fileSrc =
      value.split('href="')[1] && value.split('href="')[1].split('"')[0]

    return (
      <Button
        target="_blank"
        color="secondary"
        href={fileSrc}
        rel="noreferrer noopener nofollow"
        variant="contained"
        fullWidth
      >
        <FormattedMessage id="form.location.viewOnGoogleMaps" />
      </Button>
    )
  }

  return (
    <div
      data-error="i don't know how to preview"
      data-value={JSON.stringify(value)}
    />
  )
}

const LocationInput = React.forwardRef(
  (
    {
      name,
      label,
      buttonText,
      separateLabel,
      showLabel,
      description,
      defaultValue,
      value: controlledValue,
      onChange: handleChange,
      fullWidth,
      readOnly,
      disabled,
      placeholder,
      size,
      errors,
      renderPreview,
      ...props
    },
    ref,
  ) => {
    const hasError = errors?.length > 0

    const showSeparateLabel = showLabel && separateLabel

    const isControlledInput = React.useMemo(
      () => typeof handleChange === 'function',
      [handleChange],
    )

    const [value, setValue] = React.useState(defaultValue)

    const currentValue = isControlledInput ? controlledValue : value

    const _onChange = event => {
      const newValue = event ? event.target?.value : null

      if (isControlledInput) {
        handleChange(newValue)
      } else {
        setValue(newValue)
      }
    }

    return (
      <div
        xs={{
          display: 'grid',
          gridGap: 0.5,
          alignItems: 'start',
          justifyItems: 'start',
          alignContent: 'stretch',
          justifyContent: 'normal',
          width: fullWidth ? '100%' : 'auto',
        }}
      >
        {showSeparateLabel && (
          <MuiInputLabel htmlFor={name} error={hasError}>
            {label}
          </MuiInputLabel>
        )}
        {/* TODO: add signature pad here */}
        {description && <FormHelperText>{description}</FormHelperText>}
        <LocationPreview
          value={currentValue}
          onRemove={() => _onChange(null)}
        />
        {hasError && <InputErrors errors={errors} />}
      </div>
    )
  },
)

LocationInput.displayName = 'FormalooSignatureInput'

LocationInput.defaultProps = {
  showLabel: true,
  size: 'small',
  separateLabel: false,
  fullWidth: true,
  // html file input props
}

LocationInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  separateLabel: PropTypes.bool,
  showLabel: PropTypes.bool,
  description: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    PropTypes.elementType,
  ]),
  value: PropTypes.any,
  defaultValue: PropTypes.any,
  onChange: PropTypes.func,
  fullWidth: PropTypes.bool,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  size: PropTypes.oneOf(['small', 'normal']),
  errors: PropTypes.arrayOf(PropTypes.string),
}

export default LocationInput
