import { createContext, useContext, useState } from 'react'

const BoardModeContext = createContext()

const BoardModeProvider = ({ children }) => {
  const [isEditMode, setEditMode] = useState(false)

  /* this flag is used for enabling/disabling the edit/save button 
     of board during updating the blocks or removing/creating one. */
  const [isSwitchModeEnable, setSwitchMode] = useState(true)

  return (
    <BoardModeContext.Provider
      value={{ isEditMode, setEditMode, isSwitchModeEnable, setSwitchMode }}
    >
      {children}
    </BoardModeContext.Provider>
  )
}

function useBoardMode() {
  const context = useContext(BoardModeContext)
  if (context === undefined) {
    throw new Error(`useBoardMode must be used within a BoardModeProvider`)
  }
  return context
}

export { BoardModeProvider, useBoardMode }
