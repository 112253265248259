import { config, getCurrentWorkspace } from './utils'
import { HTTP, httpClient } from './utils/http'
import API from 'app-settings/api.settings'
import { createJsonData } from 'utils/form/createData'

const workspace = getCurrentWorkspace()

export const form = () => ({
  getAll: params =>
    httpClient.get(`${API.baseUrl}/v4/forms/`, config({ params, workspace })),

  getOne: (formSlug, params) =>
    httpClient.get(
      `${API.baseUrl}/v4/forms/${formSlug}/`,
      config({ params, workspace }),
    ),

  getOneBySlugPublic: (formSlug, params) =>
    httpClient.get(
      `${API.baseUrl}/v3.1/form-displays/slug/${formSlug}/`,
      config({ params }),
    ),
  getRows: (formSlug, params) =>
    httpClient.get(
      `${API.baseUrl}/v4/forms/${formSlug}/rows/`,
      config({ params, workspace }),
    ),
  getOneRow: (rowSlug, params) =>
    httpClient.get(
      `${API.baseUrl}/v4/rows/${rowSlug}/`,
      config({ params, workspace }),
    ),

  getFieldChoices: (fieldSlug, params) =>
    httpClient.get(
      `${API.baseUrl}/v4/fields/${fieldSlug}/choices/`,
      config({ params, workspace }),
    ),
  updateField: (fieldSlug, formData) =>
    httpClient.patch(
      `${API.baseUrl}/v4/fields/${fieldSlug}/`,
      createJsonData(formData),
      config({ contentType: HTTP.ContentTypes.JSON, workspace }),
    ),
})
