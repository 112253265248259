import React from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import { FormattedMessage } from 'react-intl'
import Typography from '@mui/material/Typography'

import FormResponseEditForm from './Edit'
import FormResponseCreateForm from './Create'
import FormResponseTagsForm from '../FormResponseTags/InlineEdit'

import BarSpinner from 'stories/loading/spinner/BarSpinner'
import { useFormFn } from 'hooks/form'
import { setErrors } from 'utils/error'
import { useMountEffect } from 'hooks/react'
import { CopyButton } from 'stories/button/CopyButton'
import ShareIcon from 'stories/icons/Share'

export const EditResponseContainer = ({
  form,
  response,
  onDirtyStateChange,
  onSuccessfulSubmit,
  onClose,
  disableTags,
  shareUrl,
  ...props
}) => {
  const draftBadge = response.status === 'draft' && (
    <Box
      component="span"
      sx={{
        backgroundColor: '#FFF5ED',
        borderRadius: 2,
        px: 1,
        py: 0.5,
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#E56A00',
        fontSize: 14,
        ml: 1,
      }}
    >
      <FormattedMessage id="draft" />
    </Box>
  )

  const shareButton = shareUrl && (
    <CopyButton
      text={shareUrl}
      icon={<ShareIcon />}
      alt={<FormattedMessage id="response.copyUrl" />}
      wrapperStyle={{ mxWidth: 65, display: 'inline-block', ml: 1 }}
    />
  )

  return (
    <>
      <Typography
        component="h2"
        color="primary.lighten"
        sx={{ pb: 2, fontSize: '2rem' }}
      >
        {form.title}
        {draftBadge}
        {shareButton}
      </Typography>

      {!disableTags && (
        <FormResponseTagsForm
          tags={response.row_tags}
          responseSlug={response.slug}
          formSlug={form.slug}
          onSuccessfulSubmit={res => {
            typeof onSuccessfulSubmit === 'function' && onSuccessfulSubmit(res)
          }}
        />
      )}
      <FormResponseEditForm
        form={form}
        response={response}
        onDirtyStateChange={onDirtyStateChange}
        onSuccessfulSubmit={res => {
          typeof onSuccessfulSubmit === 'function' && onSuccessfulSubmit(res)
          onClose()
        }}
        {...props}
      />
    </>
  )
}

const ResponseFormContainer = ({
  mode,
  formSlug,
  onSuccessfulSubmit,
  ...props
}) => {
  const { getForm } = useFormFn()
  const [form, setForm] = React.useState(null)

  useMountEffect(() => {
    getForm(formSlug)
      .then(theForm => setForm(theForm))
      .catch(error => setErrors(error))
  })

  if (!form || (mode === 'edit' && !props.response))
    return (
      <BarSpinner
        style={{
          position: 'static',
          height: '100%',
          padding: 'calc(40% - 1px) calc(50% - 65px)',
        }}
      />
    )

  return mode === 'edit' ? (
    <EditResponseContainer
      {...props}
      onSuccessfulSubmit={onSuccessfulSubmit}
      form={form}
    />
  ) : (
    <FormResponseCreateForm
      form={form}
      onSuccessfulSubmit={res => {
        typeof onSuccessfulSubmit === 'function' && onSuccessfulSubmit(res)
        props.onClose()
      }}
      {...props}
    />
  )
}

ResponseFormContainer.propTypes = {
  mode: PropTypes.oneOf(['edit', 'create']),
}

export default ResponseFormContainer
