import { useBoard } from 'context/board'
import { useForm, Controller } from 'react-hook-form'
import { THEMES_MODE } from 'utils/board/theme-configs'
import ThemeSwitch from 'stories/fields/board-ui/ThemeSwitch'
import Box from '@mui/material/Box'
import Button from 'stories/button/Button'
import { FormattedMessage, useIntl } from 'react-intl'
import { useBoardFn } from 'hooks/board'
import { useBoardMode } from 'context/mode'
import { setErrors } from 'utils/error'
import BarSpinner from 'stories/loading/spinner/BarSpinner'
import Link from '@mui/material/Link'
import urlSettings from 'app-settings/url.settings'
import ViewIcon from 'stories/icons/View'
import { useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { ErrorMessage } from '@hookform/error-message'
import BoardTitle from '../left-sidebar/BoardTitle'
import PenIcon from 'stories/icons/Pen'
import InputBase from '@mui/material/InputBase'
import SummaryOptions from '../left-sidebar/SummaryOptions'
import { useAccess } from 'hooks/access-management/useAccess'
import AppSettings from '../left-sidebar/AppSettings'
import UsersPageButton from '../left-sidebar/UsersPageButton'

const { baseUrl } = urlSettings

export default function BoardThemeEditor() {
  const { setBoardTheme, setBoardData, boardInfo } = useBoard()
  const { updateBoard, isLoading: isSaving, isPrivate } = useBoardFn()
  const { isEditMode, setEditMode, isSwitchModeEnable } = useBoardMode()
  const { palette } = useTheme()
  const { formatMessage } = useIntl()
  const { isAllowedToEdit, isAdmin } = useAccess()

  const {
    control,
    handleSubmit,
    formState: { errors },
    register,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      isDarkMode: boardInfo?.theme_config?.mode === 'dark',
      title: boardInfo?.primary_form?.title || '',
    },
  })

  const onSubmit = ({ isDarkMode, ...data }) => {
    const boardData = {
      theme_config: { mode: isDarkMode ? 'dark' : 'light' },
      ...data,
    }
    //TODO add error handling
    updateBoard(boardData)
      .then(board => {
        setBoardData(board)
        setEditMode(false)
      })
      .catch(err => setErrors(err))
  }

  const setLocalTheme = isDark => {
    if (isDark) {
      setBoardTheme(THEMES_MODE.dark)
      return
    }
    setBoardTheme(THEMES_MODE.light)
  }

  const toggleEdit = () => {
    if (isEditMode) {
      handleSubmit(onSubmit)()
      return
    }
    setEditMode(true)
  }

  const EditView = (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box
        display="flex"
        alignItems="flex-start"
        justifyContent="space-between"
      >
        <Controller
          control={control}
          name="isDarkMode"
          render={({ field: { onChange, ref, ...field } }) => (
            <ThemeSwitch
              inputRef={ref}
              onChange={data => {
                onChange(data)
                setLocalTheme(data.target.checked)
              }}
              {...field}
            />
          )}
        />
        <Button
          type="submit"
          onClick={toggleEdit}
          variant="contained"
          color="primary"
          isLoading={isSaving}
          disabled={!isSwitchModeEnable}
          sx={{
            ml: 'auto',
            backgroundColor: 'primary.darken',
            height: 32,
          }}
        >
          <FormattedMessage
            id="done"
            description="Button text"
            defaultMessage="Done"
          />
        </Button>
      </Box>
      <Box display="flex" flexDirection="column" width="100%" sx={{ mt: 3 }}>
        <InputBase
          name="title"
          error={Boolean(errors && errors.title)}
          placeholder={formatMessage({ id: 'field.placeholder.title' })}
          {...register('title', {
            required: {
              value: true,
              message: formatMessage({ id: 'field.validation.required' }),
            },
          })}
          variant="standard"
          sx={{ pb: 1, width: '100%', fontWeight: 500, fontSize: '1.1rem' }}
        />
        <Typography sx={{ width: '100%' }} variant="caption" color="error">
          <ErrorMessage errors={errors} name="title" />
        </Typography>
      </Box>
    </form>
  )

  const PrivateInfo = (
    <Box
      width="100%"
      sx={{
        fontSize: 14,
        fontWeight: 600,
        '& > *': {},
      }}
    >
      {isAllowedToEdit && (
        <>
          <Button
            type="submit"
            onClick={toggleEdit}
            variant="text"
            isLoading={isSaving}
            disabled={!isSwitchModeEnable}
            disableRipple
            sx={{
              height: 30,
              px: 1,
              py: 0,
              mb: 1,
              minWidth: 'auto',
              ml: 'auto',
              '&:hover': { backgroundColor: 'inherit' },
              color: 'text.primary',
              fontWeight: 600,
              '& > *:first-of-type': { mr: 1 },
            }}
          >
            <PenIcon
              color={palette.primary.dark}
              style={{ width: 20, height: 20 }}
            />
            <FormattedMessage
              id="app.edit"
              description="Button text"
              defaultMessage="Edit"
            />
          </Button>
          {isAdmin && <AppSettings />}
          <SummaryOptions />
          {boardInfo?.config?.users_settings_enabled
            ? isAdmin && <UsersPageButton />
            : null}
        </>
      )}

      {boardInfo?.is_public && (
        <Link
          href={`${baseUrl}/${boardInfo?.share_address}`}
          underline="none"
          target="_blank"
          sx={{
            display: 'flex',
            alignItems: 'center',
            color: 'text.primary',
            mb: 2,
            mt: 1.5,
            px: 1,
            '& > *:first-of-type': { mr: 1 },
          }}
        >
          <ViewIcon
            color={palette.primary.dark}
            style={{ width: 20, height: 20 }}
          />
          <FormattedMessage id="view.public" />
        </Link>
      )}
    </Box>
  )
  const ReadView = (
    <>
      <BoardTitle />
      {isPrivate && PrivateInfo}
    </>
  )

  return (
    <>
      {isEditMode ? EditView : ReadView}
      {isSaving && (
        <BarSpinner style={{ backgroundColor: 'rgba(162, 162, 173, 0.3)' }} />
      )}
    </>
  )
}
