import MuiButton from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'

const Button = ({
  isLoading,
  text,
  children,
  variant = 'contained',
  disabled,
  sx = {},
  ...props
}) => {
  return (
    <MuiButton
      sx={{
        boxShadow: 'none',
        borderRadius: 2,
        borderColor: 'primary.light',
        ...sx,
      }}
      disabled={isLoading || disabled}
      variant={variant}
      disableRipple
      {...props}
    >
      {isLoading ? <CircularProgress size={17} /> : text || children}
    </MuiButton>
  )
}
export default Button
