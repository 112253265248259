import EmailInput from 'stories/FormInputs/Email/EmailInput'

import { typeUtils } from 'types'
import {
  getFormItemErrors,
  getFormItemLabel,
  getGenericFormItemDefaultValue,
} from './_propGetters'
import { getRequiredRule } from './_validationProps'
import { EMAIL_REGEX } from 'utils/regex'
import getRawMessage from 'locales/localeDirectAccess'

const Component = EmailInput

const getDefaultValue = fieldProps =>
  getGenericFormItemDefaultValue(fieldProps, '')

const getProps = fieldProps => ({
  ...fieldProps,
  name: fieldProps.slug,
  label: getFormItemLabel(fieldProps),
  // input props
  separateLabel: true,
  autoFill: false,
})

const getControlledProps = (fieldProps, controllerProps, defaultValue) => {
  return {
    ...controllerProps.field,
    type: typeUtils.getMostSpecificType(fieldProps),
    label: getFormItemLabel(fieldProps),
    // input props
    separateLabel: true,
    autoFill: false,
    errors: getFormItemErrors(controllerProps),
  }
}

const getValidationRules = (fieldProps, options) => ({
  ...getRequiredRule(fieldProps, options),
  pattern: {
    value: EMAIL_REGEX,
    message: getRawMessage('form.validation.email'),
  },
})

export const emailInput = {
  Component,
  getProps,
  getControlledProps,
  getValidationRules,
  getDefaultValue,
}
