import React from 'react'
import { useAuthContext } from 'context/auth'
import { useFormFn } from 'hooks/form'
import { setErrors } from 'utils/error'
import BarSpinner from 'stories/loading/spinner/BarSpinner'
import { EditResponseContainer } from 'stories/Form/FormResponse/Container'
import Box from '@mui/material/Box'
import { FormattedMessage } from 'react-intl'
import { useAuth } from 'hooks/useAuth'
import Typography from '@mui/material/Typography'
import MuiInputLabel from '@mui/material/InputLabel'
import MuiTextField from '@mui/material/TextField'
import { useBoard } from 'context/board'
import { omit } from 'utils/formatter/data-structure'

const ProfilePage = () => {
  const { user, userProfileForm, isLoading, setUser } = useAuthContext()
  const { getPublicForm } = useFormFn()
  const { boardInfo } = useBoard()
  const { updateUserProfile, isLoading: isSubmitting } = useAuth(
    boardInfo?.share_address,
  )

  const [form, setFrom] = React.useState(null)

  const profileFirstFillStatusField =
    boardInfo?.user_form?.form_fields?.edited_by_user

  const emailField = boardInfo?.user_form?.form_fields?.email

  React.useEffect(() => {
    if (user) {
      getPublicForm(user.form.slug)
        .then(theForm =>
          setFrom({
            ...theForm,
            fields_list: theForm.fields_list
              .filter(
                // email & edited_by_user are readOnly fields
                field =>
                  ![profileFirstFillStatusField, emailField].includes(
                    field.slug,
                  ),
              )
              .map(field => omit(['position'])(field)),
          }),
        )
        .catch(error => setErrors(error))
    }
  }, [])

  if (isLoading || !user?.form || !form)
    return (
      <Box
        width="100%"
        sx={{
          height: '80vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <BarSpinner
          style={{
            position: 'static',
            padding: '0 calc(50% - 65px)',
          }}
        />
      </Box>
    )

  const onSuccessfulSubmit = updatedUser => {
    setUser(updatedUser)
  }

  if (!userProfileForm) return null

  const userEmail = (
    <Box
      sx={{
        display: 'grid',
        gridGap: 0.5,
        alignItems: 'start',
        justifyItems: 'start',
        alignContent: 'stretch',
        justifyContent: 'normal',
        width: '100%',
        '& > *': {
          width: '100%',
        },
      }}
    >
      <MuiInputLabel
        sx={{
          fontSize: '1.2rem',
          color: 'primary.lighten',
          mb: 1.5,
        }}
        htmlFor="email"
      >
        <FormattedMessage id="email" />
      </MuiInputLabel>
      <MuiTextField
        id="email"
        variant="outlined"
        hiddenLabel
        name={name}
        disabled
        value={user?.rendered_data?.[emailField]?.value || ''}
        sx={{
          '.MuiInputBase-root': { minHeight: 50, borderRadius: 2 },
        }}
      />
    </Box>
  )
  return (
    <Box px={3} pt={3} sx={{ maxHeight: '100%', overflowY: 'auto' }}>
      <Typography component="h6" variant="h5" sx={{ mb: 4 }}>
        <FormattedMessage id="app.user.profile" />
      </Typography>
      {userEmail}
      <EditResponseContainer
        form={{ ...form, title: '' }}
        onClose={() => {}}
        responseSlug={user?.slug}
        formSlug={form.slug}
        response={user}
        onSubmit={{ updateResponse: updateUserProfile, isSubmitting }}
        onSuccessfulSubmit={onSuccessfulSubmit}
        onDirtyStateChange={() => {}}
        disableTags={true}
      />
    </Box>
  )
}

ProfilePage.propTypes = {}

export default ProfilePage
