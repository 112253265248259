import { FIELD_JSON_KEY, FIELD_SUB_TYPE, FIELD_TYPE, typeUtils } from 'types'
import { emailInput } from './email'
import { nationalNumber } from './nationalNumber'
import { numberInput } from './number'
import { textInput } from './text'
import { urlInput } from './url'
import { timeInput } from './time'
import { dateInput } from './date'
import { selectInput } from './select'
import { checkboxGroupInput } from './checkboxGroup'
import { radioGroupInput } from './radioGroup'
import { npsInput } from './nps'
import { starInput } from './star'
import { yesNoInput } from './yesNo'
import { likeDislikeInput } from './likeDislike'
import { fileInput } from './file'
import { cityInput } from './city'
import { matrixInput } from './matrix'
import { tableInput } from './table'
import { phoneInput } from './phone'
import { productInput } from './product'
import { regexInput } from './regex'
import { signatureInput } from './signature'
import { verificationInput } from './verification'
import { lookupInput } from './lookup'
import { barcodeInput } from './barcode'
import { locationInput } from './location'
import { variable } from './variable'
import { userInput } from './user'

const formItemTypes = {
  // TYPES
  [FIELD_TYPE.SHORT_TEXT]: textInput,
  [FIELD_TYPE.LONG_TEXT]: textInput,
  [FIELD_TYPE.NUMBER]: numberInput,
  [FIELD_TYPE.WEBSITE]: urlInput,
  [FIELD_TYPE.EMAIL]: emailInput,
  [FIELD_TYPE.PHONE]: phoneInput,
  [FIELD_TYPE.TIME]: timeInput,
  [FIELD_TYPE.DATE]: dateInput,
  [FIELD_TYPE.REGEX]: regexInput,

  [FIELD_TYPE.BARCODE]: barcodeInput,
  // ---
  [FIELD_TYPE.DROPDOWN]: selectInput,
  [FIELD_TYPE.CITY]: cityInput,
  [FIELD_TYPE.LOOKUP]: lookupInput,
  [FIELD_TYPE.USER]: userInput,
  // SUBTYPES
  [FIELD_TYPE.MULTIPLE_SELECT]: checkboxGroupInput,
  [FIELD_TYPE.SINGLE_SELECT]: radioGroupInput,
  [FIELD_TYPE.YES_NO]: yesNoInput,
  // rating sub-types
  [FIELD_SUB_TYPE.RATING.LIKE_DISLIKE]: likeDislikeInput,
  [FIELD_SUB_TYPE.RATING.NPS]: npsInput,
  [FIELD_SUB_TYPE.RATING.STAR]: starInput,
  [FIELD_SUB_TYPE.RATING.EMBEDDED]: starInput,
  // ---
  [FIELD_TYPE.FILE]: fileInput,

  [FIELD_TYPE.MATRIX]: matrixInput,

  [FIELD_TYPE.TABLE]: tableInput,

  [FIELD_TYPE.PRODUCT]: productInput,
  // JSON KEYS
  [FIELD_JSON_KEY.SHORT_TEXT.NATIONAL_NUMBER]: nationalNumber,
  // invisible on the user form
  [FIELD_TYPE.HIDDEN]: textInput,

  // read only for now
  [FIELD_TYPE.SIGNATURE]: signatureInput,
  [FIELD_TYPE.LOCATION]: locationInput,

  [FIELD_TYPE.PHONE_VERIFICATION]: verificationInput,
  [FIELD_TYPE.EMAIL_VERIFICATION]: verificationInput,
  [FIELD_TYPE.VARIABLE]: variable,
}

export const getFormItemComponent = fieldProps => {
  const itemType = typeUtils.getMostSpecificType(fieldProps)

  return formItemTypes[itemType] ? formItemTypes[itemType].Component : null
}

export const getFormItemProps = (fieldProps, controllerProps) => {
  const itemType = typeUtils.getMostSpecificType(fieldProps)

  return formItemTypes[itemType]
    ? formItemTypes[itemType].getMostSpecificType(fieldProps)
    : {}
}

export const getControlledFormItemProps = (
  fieldProps,
  controllerProps,
  { defaultValue, rowDataProps, formDataProps, setFieldErrors },
) => {
  const itemType = typeUtils.getMostSpecificType(fieldProps)

  return formItemTypes[itemType]
    ? formItemTypes[itemType].getControlledProps(
        fieldProps,
        controllerProps,
        defaultValue,
        rowDataProps,
        formDataProps,
        setFieldErrors,
      )
    : {}
}

export const getFormItemValidationRules = (fieldProps, options = {}) => {
  const itemType = typeUtils.getMostSpecificType(fieldProps)

  return formItemTypes[itemType]
    ? formItemTypes[itemType].getValidationRules(fieldProps, options)
    : {}
}

export const getFormItemDefaultValue = fieldProps => {
  const itemType = typeUtils.getMostSpecificType(fieldProps)

  if (typeof formItemTypes[itemType]?.getDefaultValue !== 'function')
    return undefined

  return formItemTypes[itemType]
    ? formItemTypes[itemType].getDefaultValue(fieldProps)
    : undefined
}
