import { useState, useMemo } from 'react'
import { useWindowLocation } from 'hooks/utility/useWindowLocation'
import { board } from 'api/board'
import { FormattedMessage } from 'react-intl'
import { notify } from 'utils/notification'

export function useAuth(boardSharedSlug) {
  const { pathname } = useWindowLocation()

  const [isLoading, setIsLoading] = useState(false)
  const isAuthCallbackRoute = useMemo(
    () => pathname.startsWith('/auth/success'),
    [pathname],
  )

  const getUserProfile = async () => {
    setIsLoading(true)

    try {
      const { data } = await board().getBoardUserProfile(boardSharedSlug)
      return Promise.resolve(data.data.row)
    } catch (e) {
      return Promise.reject(e)
    } finally {
      setIsLoading(false)
    }
  }

  const updateUserProfile = async (_, profileData) => {
    setIsLoading(true)
    try {
      const { data } = await board().updateBoardUserProfile(
        boardSharedSlug,
        profileData,
      )
      return Promise.resolve(data.data.row)
    } catch (e) {
      return Promise.reject(e)
    } finally {
      setIsLoading(false)
    }
  }

  const logout = async () => {
    try {
      setIsLoading(true)
      const _ = await board().logout(boardSharedSlug)
      window.location.reload()
    } catch (e) {
      const errors = flattenErrors(e)
      // handle 401/403?
      if (errors?.length > 0) {
        notify(errors.join('\n'), 'error')
      } else {
        notify(<FormattedMessage id="general.genericErrorMessage" />, 'error')
      }
    } finally {
      setIsLoading(false)
    }
  }

  return {
    isLoading,
    isAuthCallbackRoute,
    logout,
    getUserProfile,
    updateUserProfile,
  }
}
