import axios from 'axios'
import Storage from 'utils/storage-management'
import {
  AuthStorage,
  DashboardStorageSettings,
} from 'app-settings/storage.settings'
import OAuthServer from 'app-settings/oauth.settings'
import { isEmbeddedInTheDashboard, isInFakeEmbed } from 'utils/common'

// dashboard auth functionality, not app ui
const clearSession = () => {
  Storage.remove(DashboardStorageSettings.sessionKey)
  window.location.reload()
}

const onFulfilled = response => {
  // Return a successful response back to the calling service
  return response
}
const onRejected = error => {
  // Return any error which is not due to authentication back to the calling service
  if (error.response.status !== 401) {
    // error 400 - invalid_grant
    // Logout user if token refresh didn't work or user is disabled
    if (error.response.message === 'invalid_grant') {
      clearSession()
    }

    return new Promise((resolve, reject) => {
      reject(error.response.data.errors)
    })
  }

  // 👇 error 401

  // is not embedded in the dashboard
  // so it's not token based auth and token refresh does not work
  if (!isInFakeEmbed() && !isEmbeddedInTheDashboard()) {
    return new Promise((resolve, reject) => {
      reject(error.response.data.errors)
    })
  }

  // if 401 happened when refreshing the token,
  // the session has expired and should re-auth by going to icas
  if (error.config.url === OAuthServer.icasAuthorizationUrl) {
    clearSession()
  }

  // because of the interceptors getOrRefreshToken rejection will never get handled in it's own catch
  // Try request again with new token
  return AuthStorage()
    ?.getOrRefreshToken()
    .then(token => {
      // New request with new token
      const { config } = error
      config.headers.Authorization = `JWT ${token}`

      return new Promise((resolve, reject) => {
        axios
          .request(config)
          .then(response => {
            resolve(response)
          })
          .catch(errors => {
            reject(errors)
          })
      })
    })
    .catch(e => {
      return new Promise((resolve, reject) => {
        reject(e)
      })
    })
}

const axiosConfig = () => {
  // only repeat requests and refresh tokens in dev mode or embedded dashboard
  if (isInFakeEmbed() || isEmbeddedInTheDashboard()) {
    axios.interceptors.response.use(onFulfilled, onRejected)
  }
}

export default axiosConfig
