import RadioGroupInput from 'stories/FormInputs/RadioGroup/RadioGroup'
import { typeUtils } from 'types'
import {
  getFormItemErrors,
  getFormItemLabel,
  getGenericFormItemDefaultRawValue as getDefaultValue,
} from './_propGetters'
import { getRequiredRule } from './_validationProps'

const generateOptions = (start, end) => {
  return Array.from({ length: end - start + 1 }, (_, i) => ({
    value: i + start,
    label: i + start,
  }))
}

const formControlLabelProps = {
  labelPlacement: 'top',
}

const Component = RadioGroupInput

const getProps = fieldProps => ({
  ...fieldProps,
  name: fieldProps.slug,
  label: getFormItemLabel(fieldProps),
  // input props
  separateLabel: true,
  options: generateOptions(
    fieldProps.range_start || 0,
    fieldProps.range_end || 10,
  ),
  formControlLabelProps,
})

const getControlledProps = (fieldProps, controllerProps, defaultValue) => ({
  ...controllerProps.field,
  type: typeUtils.getMostSpecificType(fieldProps),
  label: getFormItemLabel(fieldProps),
  // input props
  separateLabel: true,
  errors: getFormItemErrors(controllerProps),
  options: generateOptions(
    fieldProps.range_start || 0,
    fieldProps.range_end || 10,
  ),
  onChange: value => controllerProps.field.onChange(Number(value)),
  formControlLabelProps,
})

const getValidationRules = (fieldProps, options) => ({
  ...getRequiredRule(fieldProps, options),
})

export const npsInput = {
  Component,
  getProps,
  getControlledProps,
  getValidationRules,
  getDefaultValue,
}
