import PropTypes from 'prop-types'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'

function InputErrors({ errors }) {
  if (errors.length === 0) return null

  return (
    <List
      sx={{
        margin: 0,
        pl: 2,
        color: 'error.main',
      }}
    >
      {errors.map(message => (
        <ListItem key={message}>{message}</ListItem>
      ))}
    </List>
  )
}

InputErrors.defaultProps = {
  errors: [],
}

InputErrors.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string),
}

export default InputErrors
